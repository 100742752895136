import React, { useEffect, useState, useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import userCaptures from '../../helpers/usersCaptures';
import { endpointContext } from '../../contexts/endpointContext';
import Skeleton from 'react-loading-skeleton';

/**
 * Provides a breakdown of all captures assigned to user in pipeline (QC & upload)
 * @param {*} user, qcType
 * @returns number of captures for user at each stage
 */

const CurrentlyQCingUser = ({ user, qcType }) => {
	const endpointContextQCingUser = useContext(endpointContext);
	const [capsQCUser, setCapsQCUser] = useState([]);
	const [capsUploadUser, setCapsUploadUser] = useState([]);

	useEffect(() => {
		if (user && endpointContextQCingUser.capsQC3QC4) {
			if (qcType === 'smelters') {
				userCaptures({
					user: user,
					allCaps: endpointContextQCingUser.capsQC3QC4.smelterCapsQC3,
					setState: setCapsQCUser
				});
				userCaptures({
					user: user,
					allCaps: endpointContextQCingUser.capsQC3QC4.smelterCapsQC4,
					setState: setCapsUploadUser
				});
			} else if (qcType === 'stockpiles') {
				userCaptures({
					user: user,
					allCaps: endpointContextQCingUser.capsQC3QC4.stockpilesCapsQC3,
					setState: setCapsQCUser
				});
				userCaptures({
					user: user,
					allCaps: endpointContextQCingUser.capsQC3QC4.stockpilesCapsQC4,
					setState: setCapsUploadUser
				});
			}
		}
	}, [endpointContextQCingUser, qcType]);

	const navigate = useNavigate();

	const navigateToUsersCaptures = () => {
		navigate('/users_captures');
	};

	if (endpointContextQCingUser.loading) {
		return (
			<>
				<div className="p-3">
					<h5>
						There are{' '}
						<strong>
							<span style={{ display: 'inline-block' }}>
								<Skeleton width={30} />
							</span>
						</strong>{' '}
						captures in your pipeline
					</h5>

					<Row>
						<Col>
							<h1 className="text-center">
								<Skeleton width={50} />
							</h1>
							<p className="text-center">to QC</p>
						</Col>
						<Col>
							<h1 className="text-center">
								<Skeleton width={50} />
							</h1>
							<p className="text-center">to upload</p>
						</Col>
					</Row>

					<Row>
						<div className="d-flex justify-content-center mt-4">
							<button
								data-tooltip-id="dashboard-remove-caps"
								className="btn interactButton mx-2"
								style={{ width: '180px' }}
								disabled
							>
								View your captures
							</button>
						</div>
					</Row>

					<Tooltip id="dashboard-remove-caps">
						View, remove, or continue to QC the captures assigned to you
					</Tooltip>

					<Tooltip id="dashboard-remove-caps-disabled">
						You have no captures to view
					</Tooltip>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="p-3">
				<h5>
					There are <strong>{capsQCUser.length}</strong> captures in your
					pipeline
				</h5>

				<Row>
					<Col>
						<h1 className="text-center">{capsQCUser.length}</h1>
						<p className="text-center">to QC</p>
					</Col>
					<Col>
						<h1 className="text-center">{capsUploadUser.length}</h1>
						<p className="text-center">to upload</p>
					</Col>
				</Row>

				<Row>
					<div className="d-flex justify-content-center mt-4">
						<button
							data-tooltip-id="dashboard-remove-caps"
							className="btn interactButton mx-2"
							style={{ width: '180px' }}
							onClick={(e) => navigateToUsersCaptures()}
						>
							View your captures
						</button>
					</div>
				</Row>

				<Tooltip id="dashboard-remove-caps">
					View, remove, or continue to QC the captures assigned to you
				</Tooltip>

				<Tooltip id="dashboard-remove-caps-disabled">
					You have no captures to view
				</Tooltip>
			</div>
		</>
	);
};

export default CurrentlyQCingUser;
