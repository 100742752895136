import React, { useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../../contexts/pipelineContext';

const QC_Progress_Bar = () => {
	const { qcInProgress, progressBar, comm, qcType, qcPerc, capsInPipeline } =
		useContext(pipelineContext);

	return (
		<div className="d-flex justify-content-evenly">
			<div
				style={{
					position: 'relative',
					width: '90%',
					marginTop: '12px',
					paddingTop: '5px'
				}}
			>
				<div
					style={{
						position: 'absolute',
						height: '30px',
						width: '100%'
					}}
				>
					<div
						className="progress"
						style={{ maxWidth: '100%', height: '28px' }}
					>
						<div
							id="QCprogressbar"
							className={`progress-bar ${
								qcInProgress ? 'progress-bar-striped progress-bar-animated' : ''
							}`}
							role="progressbar"
							style={{ width: `${progressBar.width}%` }}
							aria-valuenow="50"
							aria-valuemin="0"
							aria-valuemax="100"
						></div>
					</div>
				</div>

				<div
					style={{
						position: 'absolute',
						height: '30px',
						width: '100%'
					}}
				>
					<div className="row pt-1">
						{comm === undefined ? (
							<div className="col">
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									QC Category
								</p>
							</div>
						) : comm === 'smelters' ? (
							<div className="col">
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									{comm.charAt(0).toUpperCase() + comm.slice(1)}{' '}
									<i className="fa-solid fa-city ps-2"></i>
								</p>
							</div>
						) : (
							<div className="col">
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									{comm.charAt(0).toUpperCase() + comm.slice(1)}{' '}
									<i className="fa-solid fa-cubes-stacked ps-2"></i>
								</p>
							</div>
						)}

						{qcType === undefined ? (
							<div className="col">
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									QC Type
								</p>
							</div>
						) : qcType === 'Daily' ? (
							<div className="col">
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									Daily <i className="fa-solid fa-calendar-day ps-2"></i>
								</p>
							</div>
						) : qcType === 'Historic' ? (
							<div className="col">
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									Historic <i className="fa-solid fa-calendar-days ps-2"></i>
								</p>
							</div>
						) : (
							<div className="col">
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									Re-QC <i className="fa-solid fa-calendar-check ps-2"></i>
								</p>
							</div>
						)}

						{qcType === undefined ? (
							<div className="col">
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									QC Form
								</p>
							</div>
						) : qcType === 'Historic' ? (
							<div className="col">
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									Historic QC Form
								</p>
							</div>
						) : qcType === 'Daily' ? (
							<div className="col">
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									Daily QC Form
								</p>
							</div>
						) : (
							<></>
						)}

						<div className="col">
							{capsInPipeline.length > 0 ? (
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									View captures ({capsInPipeline.length})
								</p>
							) : (
								<p
									className="text-center text-white"
									style={{ fontSize: '12px' }}
								>
									View captures
								</p>
							)}
						</div>
						<div className="col">
							<p
								className="text-center text-white"
								style={{ fontSize: '12px' }}
							>
								Perform QC ({qcPerc}%)
							</p>
						</div>
						<div className="col">
							<p
								className="text-center text-white"
								style={{ fontSize: '12px' }}
							>
								Upload captures
							</p>
						</div>
						<div className="col">
							<p
								className="text-center text-white"
								style={{ fontSize: '12px' }}
							>
								QC report
							</p>
						</div>
					</div>
				</div>
			</div>

			<div
				className="d-flex justify-content-center"
				style={{
					width: '10%',
					marginTop: '12px'
				}}
			>
				{qcInProgress && progressBar.title !== 'Upload Captures' ? (
					<>
						<button
							style={{ width: '100px' }}
							data-bs-toggle="modal"
							data-bs-target="#exitQCModal"
							className="btn btn-danger"
							data-tooltip-id="exitQCButton"
						>
							Exit QC
						</button>
						<Tooltip id="exitQCButton" place="bottom" effect="solid">
							You will review your current captures
						</Tooltip>
					</>
				) : (
					<button
						disabled
						style={{ width: '100px' }}
						className="btn btn-danger"
					>
						Exit QC
					</button>
				)}
			</div>
		</div>
	);
};

export default QC_Progress_Bar;
