import { Tooltip } from 'react-tooltip';
import config from '../../config';
import { useContext } from 'react';
import StockpilesTableBasic from '../StockpilesTableBasic';
import { pipelineContext } from '../../contexts/pipelineContext';

const QC_Progress_Modal = ({props}) => {
	const {
		comm,
		capsInPipeline,
		qcPerc,
		setProgressBar,
		currentCaptureIndex,
		setCurrentCaptureIndex
	} = useContext(pipelineContext);

	const objectsWithField = capsInPipeline.filter((obj) =>
		obj.hasOwnProperty('newQC')
	);

	const moveToUpload = () => {
		setProgressBar({
			width: 6 * (100 / 7),
			title: 'Upload Captures'
		});
	};

	if (comm === 'stockpiles') {
		return (
			<div
				class="modal modal-xl fade"
				id="exampleModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								QC Progress
							</h5>

							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div
							class="modal-body"
							style={{ maxHeight: '600px', overflowY: 'auto' }}
						>
							<div className="progress mb-4 mt-3" style={{ width: '100%' }}>
								<div
									className="progress-bar progress-bar-striped progress-bar-animated"
									role="progressbar"
									style={{ width: `${qcPerc}%` }}
									aria-valuenow={qcPerc}
									aria-valuemin="0"
									aria-valuemax="100"
								>
									{qcPerc}%
								</div>
							</div>

							<StockpilesTableBasic props={props} allowJumpCapture={true}/>
						</div>
						<div class="modal-footer">
							{objectsWithField.length > 0 ? (
								<>
									<button
										type="button"
										class="btn interactButtonSecondary"
										data-tooltip-id="qcProgressModalUpload"
										data-bs-dismiss="modal"
										onClick={(e) => moveToUpload()}
									>
										Finish QC
									</button>
									<Tooltip
										id="qcProgressModalUpload"
										place="right"
										effect="solid"
									>
										Finish QCing and upload {objectsWithField.length} captures
									</Tooltip>
								</>
							) : (
								<>
									<button
										type="button"
										class="btn interactButtonSecondary"
										data-tooltip-id="qcProgressModalUpload"
										//onClick={(e) => moveToUpload()}
									>
										Finish QC
									</button>
									<Tooltip
										id="qcProgressModalUpload"
										place="right"
										effect="solid"
									>
										You have not QC'd any captures yet
									</Tooltip>
								</>
							)}

							<button
								type="button"
								class="btn interactButton"
								data-bs-dismiss="modal"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	} else if (comm === 'smelters') {
		return (
			<div
				class="modal modal-xl fade"
				id="exampleModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								QC Progress
							</h5>

							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div
							class="modal-body"
							style={{ maxHeight: '600px', overflowY: 'auto' }}
						>
							<div className="progress mb-4 mt-3" style={{ width: '100%' }}>
								<div
									className="progress-bar progress-bar-striped progress-bar-animated"
									role="progressbar"
									style={{ width: `${qcPerc}%` }}
									aria-valuenow={qcPerc}
									aria-valuemin="0"
									aria-valuemax="100"
								>
									{qcPerc}%
								</div>
							</div>

							<table class="table">
								<thead>
									<tr>
										<th scope="col">#</th>
										<th scope="col">Capture ID</th>
										<th scope="col">Site</th>
										<th scope="col">Sensor</th>
										<th scope="col">Capture Date</th>
										<th scope="col">Pre QC State</th>
										<th scope="col">Set State</th>
										<th scope="col">QC'd</th>
									</tr>
								</thead>
								<tbody>
									{capsInPipeline.map((capture, idx) => (
										<tr>
											{currentCaptureIndex === idx ? (
												<>
													<th scope="row" className="table-secondary">
														{idx + 1}
													</th>
													<td
														className="table-secondary"
														data-bs-dismiss="modal"
														onClick={(e) => setCurrentCaptureIndex(idx)}
													>
														<a href="#">{capture.id}</a>
													</td>
													<td className="table-secondary">
														{capture.site_name}
													</td>
													<td className="table-secondary">{capture.sensor}</td>
													<td className="table-secondary">
														{capture.captured}
													</td>

													{capture.initial_state === 0 ? (
														<td className="table-secondary inactiveCap">
															{
																config.qcStates.filter(
																	(qstate) =>
																		qstate.stateInt === capture.initial_state
																)[0].stateString
															}
														</td>
													) : capture.initial_state === -1 ? (
														<td className="table-secondary invalidCap">
															{
																config.qcStates.filter(
																	(qstate) =>
																		qstate.stateInt === capture.initial_state
																)[0].stateString
															}
														</td>
													) : (
														<td className="table-secondary activeCap">
															{
																config.qcStates.filter(
																	(qstate) =>
																		qstate.stateInt === capture.initial_state
																)[0].stateString
															}
														</td>
													)}

													{config.qcStates
														.map((obj) => obj.stateInt)
														.includes(capture.newQC) ? (
														<>
															{capture.newQC === 0 ? (
																<td className="table-secondary inactiveCap">
																	{
																		config.qcStates.filter(
																			(qstate) =>
																				qstate.stateInt === capture.newQC
																		)[0].stateString
																	}
																</td>
															) : capture.newQC === -1 ? (
																<td className="table-secondary invalidCap">
																	{
																		config.qcStates.filter(
																			(qstate) =>
																				qstate.stateInt === capture.newQC
																		)[0].stateString
																	}
																</td>
															) : (
																<td className="table-secondary activeCap">
																	{
																		config.qcStates.filter(
																			(qstate) =>
																				qstate.stateInt === capture.newQC
																		)[0].stateString
																	}
																</td>
															)}
														</>
													) : (
														<td className="table-secondary"></td>
													)}
													{config.qcStates
														.map((obj) => obj.stateInt)
														.includes(capture.newQC) ? (
														<td className="table-secondary">
															<i class="fa-solid fa-circle-check"></i>
														</td>
													) : capture.newQC === 'failed' ? (
														<>
															<td
																className="table-secondary"
																data-tooltip-id="progress-modal-failed-qc"
															>
																<i className="fa-solid fa-circle-exclamation text-warning"></i>
															</td>
															<Tooltip
																id="progress-modal-failed-qc"
																place="right"
																effect="solid"
															></Tooltip>
														</>
													) : (
														<td className="table-secondary"></td>
													)}
												</>
											) : (
												<>
													<th scope="row">{idx + 1}</th>
													<td
														data-bs-dismiss="modal"
														// onClick={(e) => openThisCapture(e, idx)}
														//onClick={(e) => sentDataToParent(e, idx)}
														onClick={(e) => setCurrentCaptureIndex(idx)}
													>
														<a href="#">{capture.id}</a>
													</td>
													<td>{capture.site_name}</td>
													<td>{capture.sensor}</td>
													<td>{capture.captured}</td>
													{capture.initial_state === 0 ? (
														<td className="inactiveCap">
															{
																config.qcStates.filter(
																	(qstate) =>
																		qstate.stateInt === capture.initial_state
																)[0].stateString
															}
														</td>
													) : capture.initial_state === -1 ? (
														<td className="invalidCap">
															{
																config.qcStates.filter(
																	(qstate) =>
																		qstate.stateInt === capture.initial_state
																)[0].stateString
															}
														</td>
													) : (
														<td className="activeCap">
															{
																config.qcStates.filter(
																	(qstate) =>
																		qstate.stateInt === capture.initial_state
																)[0].stateString
															}
														</td>
													)}
													{config.qcStates
														.map((obj) => obj.stateInt)
														.includes(capture.newQC) ? (
														<>
															{capture.newQC === 0 ? (
																<td className="inactiveCap">
																	{
																		config.qcStates.filter(
																			(qstate) =>
																				qstate.stateInt === capture.newQC
																		)[0].stateString
																	}
																</td>
															) : capture.newQC === -1 ? (
																<td className="invalidCap">
																	{
																		config.qcStates.filter(
																			(qstate) =>
																				qstate.stateInt === capture.newQC
																		)[0].stateString
																	}
																</td>
															) : (
																<td className="activeCap">
																	{
																		config.qcStates.filter(
																			(qstate) =>
																				qstate.stateInt === capture.newQC
																		)[0].stateString
																	}
																</td>
															)}
														</>
													) : (
														<td></td>
													)}
													{config.qcStates
														.map((obj) => obj.stateInt)
														.includes(capture.newQC) ? (
														<td>
															<i class="fa-solid fa-circle-check"></i>
														</td>
													) : capture.newQC === 'failed' ? (
														<>
															<td data-tooltip-id="progress-modal-failed-qc">
																<i className="fa-solid fa-circle-exclamation text-warning"></i>
															</td>
															<Tooltip
																id="progress-modal-failed-qc"
																place="right"
																effect="solid"
															></Tooltip>
														</>
													) : (
														<td></td>
													)}
												</>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<div class="modal-footer">
							{objectsWithField.length > 0 ? (
								<>
									<button
										type="button"
										class="btn interactButtonSecondary"
										data-tooltip-id="qcProgressModalUpload"
										data-bs-dismiss="modal"
										onClick={(e) => moveToUpload()}
									>
										Finish QC
									</button>
									<Tooltip
										id="qcProgressModalUpload"
										place="right"
										effect="solid"
									>
										Finish QCing and upload {objectsWithField.length} captures
									</Tooltip>
								</>
							) : (
								<>
									<button
										type="button"
										className="btn interactButtonDisabled"
										data-tooltip-id="qcProgressModalUpload"
										//onClick={(e) => moveToUpload()}
									>
										Finish QC
									</button>
									<Tooltip
										id="qcProgressModalUpload"
										place="right"
										effect="solid"
									>
										You have not QC'd any captures yet
									</Tooltip>
								</>
							)}

							<button
								type="button"
								class="btn interactButton"
								data-bs-dismiss="modal"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default QC_Progress_Modal;
