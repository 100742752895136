import React, { useState } from 'react';
import Sidenav from './components/Sidenav';
import { Row, Col } from 'reactstrap';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';

export const ProtectedRoute = ({
	component: C,
	user,
	children,
	qcProps,
	authProps,
	endpointProps
}) => {
	let location = useLocation();
	let navigate = useNavigate();

	// if NOT logged in
	if (!authProps.isAuthenticated) {
		return <Navigate to="/login" replace />;

		// if logged in
	} else {
		return (
			<>
				<Row className="mx-0">
					<Sidenav
						// {...user}
						authProps={authProps}
						endpointProps={endpointProps}
						location={location}
						navigate={navigate}
						qcProps={qcProps}
					/>
					<C
						authProps={authProps}
						endpointProps={endpointProps}
						location={location}
						navigate={navigate}
						qcProps={qcProps}
					/>
				</Row>
			</>
		);
	}
};

export default ProtectedRoute;
