import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import formatDate from '../../helpers/formatDate';
import config from '../../config';
import useGainsQCApi from '../../services/useGainsQCApi';
import { Toaster } from 'react-hot-toast';
import { pipelineContext } from '../../contexts/pipelineContext';

const QC_Form_Historic = ({
	fetchCapturesObj,
	handleSubmit,
	formValue,
	setFormValue,
	formValid,
	setFormValid,
	loading,
	setCapturesUrl,
	props
}) => {
	const [siteOptions, setSiteOptions] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);

	const pipelineContextQCFormHist = useContext(pipelineContext);

	const {
		data: sites,
		loading: loadingSites,
		error: errorSites,
		makeRequest: makeRequestSites
	} = useGainsQCApi();

	useEffect(() => {
		makeRequestSites(
			`${config.baseUrl}/${pipelineContextQCFormHist.comm}/sites?hasCaptures=true`,
			'GET',
			null,
			'',
			1000
		);

		let today = new Date();
		setFormValue({
			...formValue,
			formEndDateNow: today.toLocaleTimeString(),
			formAutoQC: false
		});
	}, []);

	useEffect(() => {
		if (sites?.data) {
			var options;
			options = sites.data.map(({ id, name }) => ({
				value: id,
				label: `${id} - ${name}`
			}));

			setSiteOptions(options);
		}
	}, [sites]);

	useEffect(() => {
		let firstName = props.authProps.userAttributes.givenName.toLowerCase();
		let lastName = props.authProps.userAttributes.familyName.toLowerCase();

		validateForm();

		if (formValue.fullHistory) {
			setCapturesUrl(
				`captures?startDate=2016-01-01&endDate=${formValue.formEndDate}&siteIds=${formValue.formSiteId}&qc=0&qcUser=${firstName}.${lastName}`
			);
		} else {
			setCapturesUrl(
				`captures?startDate=${formValue.formStartDate}&endDate=${formValue.formEndDate}&siteIds=${formValue.formSiteId}&qc=0&qcUser=${firstName}.${lastName}`
			);
		}
	}, [formValue]);

	const handleChange = (selectedValues) => {
		setSelectedOptions(selectedValues);
		setFormValue({ ...formValue, formSiteId: selectedValues.value });
	};

	const validateForm = () => {
		if (
			formValue.fullHistory &&
			formValue.formEndDate !== '' &&
			typeof formValue.formSiteId === 'number' &&
			formValue.formEndDate > formValue.formStartDate
		) {
			setFormValid(true);
		} else if (
			formValue.formEndDate !== '' &&
			formValue.formStartDate !== '' &&
			typeof formValue.formSiteId === 'number' &&
			formValue.formEndDate > formValue.formStartDate
		) {
			setFormValid(true);
		} else {
			setFormValid(false);
		}
	};

	const handleBackButton = (e) => {
		e.preventDefault();
		setFormValue({
			formSiteId: '',
			formStartDate: '',
			formEndDate: new Date().toISOString().substring(0, 10),
			formAutoQC: true,
			formEndDateNow: '',
			fullHistory: true,
			formSensors: ['sentinel-2-l1c', 'landsat-8-l1tp'],
			formCommodities: [3],
			formProdStates: [0, 1]
		});

		pipelineContextQCFormHist.setQCInProgress(false);
		pipelineContextQCFormHist.setProgressBar({
			width: 2 * (100 / 7),
			title: 'QC Type'
		});
		pipelineContextQCFormHist.setQCType(undefined);
	};

	return (
		<>
			<Toaster />
			<hr></hr>
			<form onSubmit={handleSubmit} className="px-3 mx-3">
				<Row>
					<Col className="d-flex justify-content-start">
						<button
							className="btn interactButtonSecondary"
							style={{ maxHeight: '40px', width: '200px' }}
							onClick={handleBackButton}
						>
							<i class="fa-solid fa-left-long pe-3"></i>
							Back
						</button>
					</Col>
					<Col></Col>
					<Col className="d-flex justify-content-end">
						{formValid && !fetchCapturesObj.loading ? (
							<button
								type="submit"
								className="btn interactButton mt-2 mx-0"
								style={{ maxHeight: '40px', width: '200px' }}
							>
								View captures <i class="fa-solid fa-right-long ps-2"></i>
							</button>
						) : (
							<button
								disabled
								className="btn btn-secondary mt-2 mx-0"
								style={{ maxHeight: '40px', width: '200px' }}
							>
								View captures <i class="fa-solid fa-right-long ps-2"></i>
							</button>
						)}
					</Col>
				</Row>

				<Row>
					<h4 className="py-5">
						Fill in the captures criteria for historic QC
					</h4>

					<Col className="col-lg-4 col-6">
						<div className="form-group">
							<h5 className="card-title">Site</h5> <br></br>
							{loadingSites ? (
								<Select
									options={siteOptions}
									value={
										siteOptions.filter(
											(option) => option.value === formValue.formSiteId
										)[0]
									}
									onChange={handleChange}
									isSearchable
									placeholder="Search by site name or id"
									isDisabled={true}
								/>
							) : (
								<>
									{formValue.formSiteId ? (
										<Select
											options={siteOptions}
											value={
												siteOptions.filter(
													(option) => option.value === formValue.formSiteId
												)[0]
											}
											onChange={handleChange}
											isSearchable
											placeholder="Search by site name or id"
										/>
									) : (
										<Select
											options={siteOptions}
											value={selectedOptions}
											onChange={handleChange}
											isSearchable
											placeholder="Search by site name or id"
										/>
									)}
								</>
							)}
							{errorSites && (
								<h6 className="text-warning mt-2">
									<i className="fa-solid fa-triangle-exclamation"></i> Could not
									fetch sites at this time
								</h6>
							)}
							{loadingSites && <h6 className="mt-2">Loading sites ..</h6>}
						</div>
					</Col>

					<Col className="col-lg-1"></Col>

					<Col className="col-lg-4 col-12">
						<div className="form-group">
							<h5 className="card-title">Interval</h5> <br></br>
							<label for="full-history-true" className="form-label me-2">
								Full capture history
							</label>
							<input
								id="full-history-true"
								type="radio"
								name="QCFormHistoricFullHistory"
								value={true}
								className="form-check-input"
								defaultChecked={formValue.fullHistory === true}
								onClick={(e) =>
									setFormValue({
										...formValue,
										fullHistory: true,
										formStartDate: ''
									})
								}
							></input>
							<label for="full-history-false" className="form-label ms-4 me-2">
								Choose specific dates
							</label>
							<input
								id="full-history-false"
								type="radio"
								name="QCFormHistoricFullHistory"
								value={false}
								defaultChecked={formValue.fullHistory === false}
								className="form-check-input"
								onClick={(e) =>
									setFormValue({
										...formValue,
										fullHistory: false
									})
								}
							></input>
							<Row className="mt-3">
								<p>Viewing captures from:</p>
								<Col>
									<p>
										{formValue.formStartDate ? (
											<span className="fw-light">
												{formatDate(formValue.formStartDate)}
											</span>
										) : (
											<span className="fw-light">earliest capture date</span>
										)}{' '}
										at <span className="fw-light">00:00:00</span>
									</p>
								</Col>
								<Col>
									<p>
										<span className="fw-light">
											{formatDate(formValue.formEndDate)}
										</span>{' '}
										at{' '}
										<span className="fw-light">{formValue.formEndDateNow}</span>
									</p>
								</Col>
							</Row>
							{formValue.fullHistory === false && (
								<Row className="py-2">
									<Col className="col-6 pe-1">
										<label for="start_date">Start date</label> <br></br>
										{formValue.fullHistory ? (
											<input
												className="form-control text-sm"
												id="start_date"
												name="formStartDate"
												title="start date of interval"
												max={new Date().toISOString().split('T')[0]}
												type="date"
												value=""
												disabled
											></input>
										) : (
											<input
												className="form-control text-sm"
												id="start_date"
												name="formStartDate"
												title="start date of interval"
												type="date"
												max={new Date().toISOString().split('T')[0]}
												value={formValue.formStartDate}
												onChange={(e) =>
													setFormValue({
														...formValue,
														formStartDate: e.target.value
													})
												}
											></input>
										)}
										<br></br>
									</Col>
									<Col className="col-6 ps-1">
										<label for="end_date">End date</label> <br></br>
										<input
											className="form-control text-sm"
											id="end_date"
											name="formEndDate"
											title="End date of interval"
											type="date"
											max={new Date().toISOString().split('T')[0]}
											value={formValue.formEndDate}
											onChange={(e) =>
												setFormValue({
													...formValue,
													formEndDate: e.target.value
												})
											}
										></input>
										<br></br>
									</Col>

									{formValue.formStartDate > formValue.formEndDate && (
										<p id="sensor-err" className="text-warning pt-3">
											<i class="fa-solid fa-triangle-exclamation"></i> The start
											date is after the end date
										</p>
									)}
								</Row>
							)}
						</div>

						{/* <div className="form-group mt-5">
							<Row>
								<Col className="d-flex justify-content-end">
									<label
										data-tooltip-id="disabledAutoQC"
										className="px-2 text-decoration-line-through"
										for="set_auto_qc"
									>
										set parameters for Auto QC
									</label>
									<input
										className="form-check-input"
										type="checkbox"
										id="set_auto_qc"
										name="set_auto_qc"
										disabled
										value={formValue.formAutoQC}
										defaultChecked={formValue.formAutoQC}
										onClick={() =>
											setFormValue({
												...formValue,
												formAutoQC: !formValue.formAutoQC
											})
										}
									></input>
									<Tooltip
										id="disabledAutoQC"
										place="bottom"
										effect="solid"
										style={{ backgroundColor: 'grey' }}
									>
										Disabled for now to focus on fully working manual QC
										pipeline
									</Tooltip>
								</Col>
							</Row>
						</div> */}
					</Col>
				</Row>
			</form>
		</>
	);
};

export default QC_Form_Historic;
