import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../../contexts/pipelineContext';

const QC_Type = ({ loading }) => {
	const pipelineContextQCType = useContext(pipelineContext);

	const handleSetQCType = (qc_type) => {
		pipelineContextQCType.setQCType(qc_type);
		pipelineContextQCType.setProgressBar({
			width: 3 * (100 / 7),
			title: qc_type
		});
	};

	const handleBackToQCCat = () => {
		pipelineContextQCType.setComm(undefined);
		pipelineContextQCType.setProgressBar({
			width: 1 * (100 / 7),
			title: ''
		});
	};

	return (
		<>
			<hr></hr>
			<div className="px-3 mx-3">
				<Row>
					<Col className="d-flex justify-content-start">
						<button
							className="btn interactButtonSecondary"
							style={{ maxHeight: '40px', width: '200px' }}
							onClick={handleBackToQCCat}
						>
							<i class="fa-solid fa-left-long pe-3"></i>
							Back
						</button>
					</Col>
					<Col></Col>
					<Col className="d-flex justify-content-end"></Col>
				</Row>

				<Row>
					<h4 className="py-5">
						Select the type of QC to perform for{' '}
						<strong>{pipelineContextQCType.comm}</strong>
					</h4>

					{loading ? (
						<>
							<Col className="col-3">
								<button style={{ border: 'none', backgroundColor: '#f8f9fa' }}>
									<div
										className="card QCTypeCard"
										style={{
											width: '18rem',
											height: '120px',
											backgroundColor: '#f8f9fa',
											color: 'grey'
										}}
									>
										<div className="card-body">
											<h5 className="card-title">
												Daily QC{' '}
												<i className="fa fa-solid fa-calendar-day ps-3"></i>
											</h5>
											<p className="card-text">
												Perform a routine QC on images captured in the last day
											</p>
										</div>
									</div>
								</button>
							</Col>
							<Col className="col-3">
								<button style={{ border: 'none', backgroundColor: '#f8f9fa' }}>
									<div
										className="card QCTypeCard"
										style={{
											width: '18rem',
											height: '120px',
											backgroundColor: '#f8f9fa',
											color: 'grey'
										}}
									>
										<div className="card-body">
											<h5 className="card-title">
												Historic QC{' '}
												<i className="fa fa-solid fa-calendar-days ps-3"></i>
											</h5>
											<p className="card-text">
												Perform a historic QC only on captures from a particular
												site
											</p>
										</div>
									</div>
								</button>
							</Col>
							<Col className="col-3">
								<button
									style={{
										border: 'none',
										backgroundColor: '#f8f9fa',
										backgroundColor: '#f8f9fa',
										color: 'grey'
									}}
								>
									<div
										className="card QCTypeCard"
										style={{ width: '18rem', height: '120px' }}
									>
										<div className="card-body">
											<h5 className="card-title">
												Re-QC{' '}
												<i className="fa fa-solid fa-calendar-check ps-3"></i>
											</h5>
											<p className="card-text">
												Re-QC previously QC'd captures
											</p>
										</div>
									</div>
								</button>
							</Col>
						</>
					) : (
						<>
							<Col className="col-3">
								<button
									onClick={(e) => handleSetQCType('Daily')}
									className="QCTypeButtons"
								>
									<div
										className="card QCTypeButtonsBtn"
										style={{ width: '18rem', height: '120px' }}
									>
										<div className="card-body">
											<h5 className="card-title">
												Daily QC{' '}
												<i className="fa fa-solid fa-calendar-day ps-3"></i>
											</h5>
											<p className="card-text">
												Perform a routine QC on images captured in the last day
											</p>
										</div>
									</div>
								</button>
							</Col>
							<Col className="col-3">
								<button
									onClick={(e) => handleSetQCType('Historic')}
									className="QCTypeButtons"
								>
									<div
										className="card QCTypeButtonsBtn"
										style={{ width: '18rem', height: '120px' }}
									>
										<div className="card-body ">
											<h5 className="card-title">
												Historic QC{' '}
												<i className="fa fa-solid fa-calendar-days ps-3"></i>
											</h5>
											<p className="card-text">
												Perform a historic QC only on captures from a particular
												site
											</p>
										</div>
									</div>
								</button>
							</Col>
							<Col className="col-3">
								<button
									//onClick={(e) => handleSetQCType('Re QC Form')}
									data-tooltip-id="reQCType"
									className="QCTypeButtons"
								>
									<div
										className="card QCTypeButtonsBtn"
										style={{ width: '18rem', height: '120px' }}
									>
										<div className="card-body ">
											<h5 className="card-title">
												Re-QC{' '}
												<i className="fa fa-solid fa-calendar-check ps-3"></i>
											</h5>
											<p className="card-text">
												Re-QC previously QC'd captures
											</p>
										</div>
									</div>
								</button>

								<Tooltip
									id="reQCType"
									place="bottom"
									effect="solid"
									style={{ backgroundColor: 'grey' }}
								>
									The option to Re-QC is not currently available
								</Tooltip>
							</Col>
						</>
					)}
				</Row>
			</div>
		</>
	);
};

export default QC_Type;
