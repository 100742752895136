import React, { useState, useEffect, useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import { Row, Col } from 'reactstrap';
import ReactECharts from 'echarts-for-react';
import { endpointContext } from '../../contexts/endpointContext';
import Skeleton from 'react-loading-skeleton';
import handleUndefined from '../../helpers/handleUndefined'

/**
 * Provides a piechart segmenting who has caps in the pipeline and how many
 *
 * @param {*} qcCat ie smelters/stockpiles
 * @returns pie chart of all captures in QC
 */
const CurrentlyQCing = ({ qcCat }) => {
	const [usersQCingObj, setUsersQCingObj] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [numCaps, setNumCaps] = useState();
	const endpointContextQCing = useContext(endpointContext);

	handleUndefined(qcCat, 'qcCat is undefined')

	useEffect(() => {
		var newSet;
		if (qcCat === 'smelters') {
			newSet = [
				...new Set(
					endpointContextQCing.capsQC3QC4.smelterCapsQC3.map(
						(obj) => obj.qc_user
					)
				)
			];
		} else {
			newSet = [
				...new Set(
					endpointContextQCing.capsQC3QC4.stockpilesCapsQC3.map(
						(obj) => obj.qc_user
					)
				)
			];
		}


		let listObj = [];

		if (newSet.length > 0) {
			for (let i in newSet) {
				let qcAlias = newSet[i];
				let initialsList = newSet[i].split('.');
				let initials = '';
				if (initialsList.length > 0) {
					for (let j in initialsList) {
						initials = initials + initialsList[j][0].toUpperCase();
					}
				} else {
					initials = qcAlias[0].toUpperCase();
				}
				let user = { qcAlias: qcAlias, initials: initials };
				listObj.push(user);
			}
		}
		setUsersQCingObj(listObj);
	}, [qcCat, endpointContextQCing]);

	useEffect(() => {
		var data2;
		if (qcCat === 'smelters') {
			data2 = usersQCingObj.map((obj) => ({
				value: endpointContextQCing.capsQC3QC4.smelterCapsQC3.filter(
					(cap) => cap.qc_user === obj.qcAlias
				).length,
				name: obj.qcAlias
			}));
		} else {
			data2 = usersQCingObj.map((obj) => ({
				value: endpointContextQCing.capsQC3QC4.stockpilesCapsQC3.filter(
					(cap) => cap.qc_user === obj.qcAlias
				).length,
				name: obj.qcAlias
			}));
		}

		const num2 = data2.map((obj) => obj.value);
		const num3 = num2.reduce((sum, value) => sum + value, 0);

		setChartData(data2);
		setNumCaps(num3);
	}, [usersQCingObj]);

	var opt2 = {
		title: {
			text: '0 captures',
			left: '50%',
			top: '50%',
			textAlign: 'center',
			textVerticalAlign: 'middle',

			textStyle: {
				color: '#000',
				fontSize: 20,
				fontWeight: 'bold'
			}
		},
		series: [
			{
				type: 'pie',
				radius: '70%',
				data: chartData,
				emphasis: {
					itemStyle: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				}
			}
		]
	};

	var opt = {
		tooltip: {
			trigger: 'item'
		},

		series: [
			{
				type: 'pie',
				radius: '70%',
				data: chartData,
				emphasis: {
					itemStyle: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				}
				// label: {
				// 	show: true,
				// 	position: 'inside',
				// 	formatter: '{c}',
				// 	fontSize: 14,
				// 	color: '#fff'
				// }
			}
		]
	};

	if (endpointContextQCing.loading) {
		return (
			<>
				<div className="p-3">
					<h5>
						There are{' '}
						<strong>
							<span style={{ display: 'inline-block' }}>
								<Skeleton width={30} />
							</span>
						</strong>{' '}
						captures in <strong>QC</strong>
					</h5>

					<Tooltip id="captures-in-qc">Loading ..</Tooltip>

					<Row>
						<Col
							style={{ height: '300px' }}
							className="d-flex justify-content-center"
						>
							<Skeleton
								circle={true}
								width={'200px'}
								height={'200px'}
								style={{ marginTop: '50px' }}
							/>
						</Col>
					</Row>
				</div>
			</>
		);
	}

	return (
		<>
			<div className="p-3">
				<h5>
					There are <strong>{numCaps}</strong> captures in{' '}
					<strong>{qcCat} QC</strong>
					<span data-tooltip-id="captures-in-qc">
						<strong>
							<sup>i</sup>
						</strong>
					</span>{' '}
				</h5>

				<Tooltip id="captures-in-qc">
					The total number of captures which are currently in the pipeline for{' '}
					{qcCat} QC
				</Tooltip>

				<Row>
					<Col style={{ height: '300px' }}>
						{numCaps != 0 ? (
							<ReactECharts
								option={opt}
								notMerge={true}
								lazyUpdate={true}
								theme={'theme_name'}
							/>
						) : (
							<ReactECharts
								option={opt2}
								notMerge={true}
								lazyUpdate={true}
								theme={'theme_name'}
							/>
						)}
					</Col>
				</Row>
			</div>
		</>
	);
};

export default CurrentlyQCing;
