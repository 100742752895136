import React, { useContext, useEffect, useState } from 'react';
import config from '../../../config';
import { Tooltip } from 'react-tooltip';
import { putInfo } from '../../../actions/getActions';
import divideReturnQCList from '../../../helpers/divideReturnQCList';
import StockpilesTableBasic from '../../StockpilesTableBasic';
import SmeltersTableBasic from '../../SmeltersTableBasic';
import { pipelineContext } from '../../../contexts/pipelineContext';
import useGainsQCApi from '../../../services/useGainsQCApi';

const ExitQCModal = ({ props }) => {
	const {
		setQCInProgress,
		capsInPipeline,
		comm,
		setCapsInPipeline,
		resetState
	} = useContext(pipelineContext);

	const { error: errorSendingCapsBack, makeRequest: sendCapsBack } =
		useGainsQCApi();


	let capturesToReset;
	let capturesQCd;
	if (comm === 'smelters') {
		capturesToReset = capsInPipeline.filter(
			(cap) =>
				(cap.state === null && !cap.hasOwnProperty('newQC')) ||
				(cap.state === null &&
					cap.hasOwnProperty('newQC') &&
					cap.newQC === 'failed')
		);
		capturesQCd = capsInPipeline.filter(
			(cap) => cap.state !== null && cap.hasOwnProperty('newQC')
		);
	} else {
		capturesToReset = capsInPipeline.filter(
			(cap) =>
				!cap.hasOwnProperty('newQC') ||
				(cap.hasOwnProperty('newQC') && cap.newQC === 'failed')
		);
		capturesQCd = capsInPipeline.filter((cap) => cap.hasOwnProperty('newQC'));
	}

	const exitQCWithCaps = () => {
		// reset all QC captures
		const urls = divideReturnQCList(capturesToReset);

		for (let i in urls) {
			sendCapsBack(
				`${config.baseUrl}/${comm}/reset_captures?table=${config.tableNames[comm]}&captureIds=${urls[i]}`,
				'POST',
				null,
				'sending caps back'
			);
		}

		// set global captures
		resetState();
		setTimeout(props.navigate('/'), 2000);
	};

	const exitQCNoCaps = () => {
		resetState();
		setTimeout(props.navigate('/'), 1000);
	};


	return (
		<div
			className="modal modal-xl fade"
			id="exitQCModal"
			tabindex="-1"
			aria-labelledby="exitQCModalLabel"
			aria-hidden="true"
			style={{ marginTop: '80px' }}
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div
						className="modal-header"
						//style={{ backgroundColor: 'rgb(48, 48, 48)' }}
					>
						<h5 className="modal-title" id="exitQCModalLabel">
							QC In Progress - are you sure you want to exit?
						</h5>
					</div>

					<div
						class="modal-body"
						style={{ maxHeight: '600px', overflowY: 'auto' }}
					>
						{capsInPipeline.length > 0 && (
							<>
								<>
									<p>
										<i className="fa-regular fa-circle-check me-3"></i>{' '}
										<strong>
											{capturesQCd.length} captures have been QC'd
										</strong>{' '}
										but not uploaded; these captures can be uploaded at a later
										date outside of this QC session.
										<br></br>
										<i className="fa-regular fa-circle-xmark me-3"></i>
										<strong>
											{capturesToReset.length} captures have not been QC'd
										</strong>{' '}
										and will be removed from your pipeline and available for
										someone else to QC.
									</p>

									{comm === 'smelters' && (
										<SmeltersTableBasic
											props={props}
										/>
									)}

									{comm === 'stockpiles' && (
										<StockpilesTableBasic
											props={props}
										/>
									)}
								</>
							</>
						)}
					</div>
					<div class="modal-footer">
						{/* <button
							type="button"
							className="btn btn-danger"
							data-bs-dismiss="modal"
						>
							Exit QC
						</button> */}

						{capturesQCd.length > 0 && (
							<>
								{/* <button
									type="button"
									className="btn btn-info"
									data-bs-dismiss="modal"
									//onClick={exitQCWithCaps}
									data-tooltip-id="exit-qc-upload-button"
								>
									Upload Captures
								</button> 
								<Tooltip
									id="exit-qc-upload-button"
									place="right"
									effect="solid"
								>
									
									This button currently does absolutely nothing
								</Tooltip>*/}
							</>
						)}

						{capsInPipeline.length > 0 ? (
							<>
								<button
									type="button"
									className="btn interactButtonSecondary"
									data-bs-dismiss="modal"
									onClick={exitQCWithCaps}
									data-tooltip-id="exit-qc-button"
								>
									Exit QC
								</button>
								<Tooltip id="exit-qc-button" place="right" effect="solid">
									Exit QC and remove {capturesToReset.length} captures from QC
									pipeline
								</Tooltip>
							</>
						) : (
							<button
								type="button"
								className="btn btn-danger"
								data-bs-dismiss="modal"
								onClick={exitQCNoCaps}
							>
								Exit QC
							</button>
						)}

						<button
							type="button"
							className="btn interactButton"
							data-bs-dismiss="modal"
						>
							Continue with QC
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExitQCModal;
