import React from 'react';
import { Tooltip } from 'react-tooltip';
import config from '../config';

const CapturesTable = ({ fields, captures, qcCat }) => {
	let currentCaptureIndex = 2;

	return (
		<>
			<table className="table table-hover">
				<thead className="thead-dark">
					<tr>
						{fields.map((field, idx) => (
							<th key={idx} scope="col">{field.header}</th>
						))}
					</tr>
				</thead>
				<tbody className="table-group-divider">
					{captures && captures.length > 0 ? (
						<>
							{captures.map((capture, idx) => (
								<tr
									data-tooltip-id={
										currentCaptureIndex === idx && 'currentCaptureTT'
									}
									key={idx}
								>
									{fields.map((field, fIdx) => (
										<td
											className={
												currentCaptureIndex === idx ? 'table-secondary fw-bold' : undefined
											}
											key={fIdx}
											href={capture?.[field.link]}
										>
											{qcCat === 'smelters' &&
											field.data === 'state' &&
											field.data !== null
												? config.qcStates.filter(
														(qstate) =>
															qstate.stateInt === capture?.[field.data]
												  )[0].stateString
												: field.data === 'captured' &&
												  field.header === 'Capture Date'
												? capture?.[field.data]?.slice(0, 10) ?? ''
												: field.data === 'captured' &&
												  field.header === 'Capture Time'
												? capture?.[field.data]?.slice(11, 16) ?? ''
												: capture?.[field.data] ?? ''}
										</td>
									))}
								</tr>
							))}
						</>
					) : (
						<tr>
							{fields.map((field, fIdx) => (
								<td key={fIdx}>-</td>
							))}
						</tr>
					)}
				</tbody>
			</table>
			<Tooltip id="currentCaptureTT">Current capture</Tooltip>
		</>
	);
};

export default CapturesTable;
