import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import divideReturnQCList from '../../helpers/divideReturnQCList';
import { putInfo } from '../../actions/getActions';
import StockpilesTableBasic from '../StockpilesTableBasic';
import SmeltersTableBasic from '../SmeltersTableBasic';
import QC_Finished_Modal from './Modals/QC_Finished_Modal';
import { Modal } from 'bootstrap';
import config from '../../config';
import ErrorComp from '../ErrorComp';
import { pipelineContext } from '../../contexts/pipelineContext';
import useGainsQCApi from '../../services/useGainsQCApi';

const QC_Upload = ({ setProgressBar, setQcInProgress, props }) => {
	const [capsUploaded, setCapsUploaded] = useState(false);
	const [manCapsUpload, setManCapsUpload] = useState([]);
	const [capsUploadedToStaging, setCapsUploadedToStaging] = useState(false);
	const [capsUploadedToProd, setCapsUploadedToProd] = useState(false);
	const [capsToSendBack, setCapsToSendBack] = useState([]);
	const [postQCPerformed, setPostQCPerformed] = useState(false);
	const [postQCData, setPostQCData] = useState();
	const [overwriteOnPostQC, setOverwriteOnPostQC] = useState(false);
	const [uploaded, setUploaded] = useState(false);
	const [uploadedToDev, setUploadedToDev] = useState(false);
	const [uploadedToProd, setUploadedToProd] = useState(false);
	const [loading, setLoading] = useState(false);
	const [uploadToDatabase, setUploadToDatabase] = useState('dev');
	const [apiError, setApiError] = useState(false);

	const { error: errorSendingCapsBack, makeRequest: sendCapsBack } =
		useGainsQCApi();
	const runPostQC = useGainsQCApi();
	const uploadToDev = useGainsQCApi();
	const uploadToProd = useGainsQCApi();

	const pipelineContextQCUpload = useContext(pipelineContext);

	let navigate = useNavigate();

	useEffect(() => {
		setManCapsUpload(
			pipelineContextQCUpload.comm === 'stockpiles'
				? pipelineContextQCUpload.capsInPipeline.filter(
						(cap) => cap['qc_state'] === 4
				  )
				: pipelineContextQCUpload.capsInPipeline.filter(
						(cap) => cap.state !== null
				  )
		);
		setCapsToSendBack(
			pipelineContextQCUpload.comm === 'stockpiles'
				? pipelineContextQCUpload.capsInPipeline.filter(
						(cap) => cap['qc_state'] !== 4
				  )
				: pipelineContextQCUpload.capsInPipeline.filter(
						(cap) => cap.state === null
				  )
		);

		// needs to depend on progressBar to retrigger this calc when moving to upload
	}, [
		pipelineContextQCUpload.capsInPipeline,
		pipelineContextQCUpload.progressBar
	]);

	let comms;
	if (pipelineContextQCUpload.comm === 'stockpiles') {
		comms = 7;
	} else {
		comms = '';
	}

	const runPostQCAndUploadToDev = async () => {
		setLoading(true);

		let siteIds1 = manCapsUpload.map((x) => x.site_id);
		const siteIds = [...new Set(siteIds1)];

		let user = `${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`;
		let apiString = `post_qc?commodities=${comms}&siteIds=${siteIds}&overwrite=${overwriteOnPostQC}&username=${user}&lookback=-1`;

		await runPostQC.makeRequest(
			`${config.baseUrl}/${pipelineContextQCUpload.comm}/${apiString}`,
			'POST',
			null,
			'post qc'
		);

		// try {
		// 	putInfo(
		// 		apiString,
		// 		'stockpiles',
		// 		'Performing Post-QC..',
		// 		'Successfully performed Post-QC!',
		// 		'Error performing Post-QC!'
		// 	)
		// 		.then((items) => {
		// 			// if response ok
		// 			if (items) {
		// 				console.log(items);
		// 				if (items.data) {
		// 					console.log(items);
		// 					setPostQCData(items.data);
		// 					setPostQCPerformed(true);

		// 					let apiString2 = `upload?data=${items.data}&commodity=${comms}&database=dev`;
		// 					try {
		// 						putInfo(
		// 							apiString2,
		// 							'stockpiles',
		// 							'Uploading to development..',
		// 							'Successfully uploaded to development!',
		// 							'Error uploading to development!'
		// 						)
		// 							.then((items) => {
		// 								// if response ok
		// 								if (items) {
		// 									console.log(items);
		// 									if (items.body) {
		// 										console.log(items);
		// 										setUploadedToDev(true);
		// 										setLoading(false);
		// 									} else {
		// 										console.log(items);
		// 									}
		// 								}
		// 							})
		// 							.catch((error) => {
		// 								setLoading(false);
		// 								setApiError(error);
		// 							});
		// 					} catch (e) {
		// 						console.log(e);
		// 					}
		// 				} else {
		// 					console.log(items);
		// 					setLoading(false);
		// 				}
		// 			}
		// 		})
		// 		.catch((error) => {
		// 			console.error('Error:', error);
		// 		});
		// } catch (e) {
		// 	console.log(e);
		// }
	};

	useEffect(() => {
		console.log(runPostQC);
		if (runPostQC.data && !runPostQC.error) {
			setPostQCData(runPostQC.data.data);
			setPostQCPerformed(true);

			let apiString = `upload?data=${runPostQC.data.data}&commodity=${comms}&database=dev`;

			uploadToDev.makeRequest(
				`${config.baseUrl}/${pipelineContextQCUpload.comm}/${apiString}`,
				'POST',
				null,
				'upload to dev'
			);
		} else {
			console.log(runPostQC.error);
		}
	}, [runPostQC.data, runPostQC.error]);

	useEffect(() => {
		console.log(uploadToDev);

		if (!uploadToDev.error && uploadToDev.data) {
			if (uploadToDev.data.message === 'Success') {
				setUploadedToDev(true);
				setLoading(false);
			}
		}
	}, [uploadToDev.data, uploadToDev.error]);

	const uploadToProdDB = () => {
		setLoading(true);

		let comms;
		if (pipelineContextQCUpload.comm === 'stockpiles') {
			comms = 7;
		} else {
			comms = '';
		}

		let apiString = `upload?data=${postQCData}&commodity=${comms}&database=prod`;
		console.log(apiString);

		uploadToProd.makeRequest(
			`${config.baseUrl}/${pipelineContextQCUpload.comm}/${apiString}`,
			'POST',
			null,
			'upload to prod'
		);
	};

	useEffect(() => {
		console.log(uploadToProd);

		if (!uploadToProd.error && uploadToProd.data) {
			if (uploadToProd.data.message === 'Success') {
				setLoading(false);
				setUploaded(true);
				setUploadedToProd(true);
				setQcInProgress(false);
			}
		}
	}, [uploadToProd.data, uploadToProd.error]);

	const navigateToHome = () => {
		if (capsToSendBack.length > 0) {
			const urls = divideReturnQCList(capsToSendBack);

			for (let i in urls) {
				let apiString = `reset_captures?table=SteelCaptureProcessingData&captureIds=${urls[i]}`;

				try {
					putInfo(apiString, 'smelters')
						.then((items) => {
							// if response ok
							if (items) {
								if (items.body) {
									console.log(items);

									// if response NOT ok
								} else {
									console.log(items);
								}
							}
						})
						.catch((error) => {
							console.error('Error:', error);
						});
				} catch (e) {
					console.log(e);
				}
			}
		}

		toast.success('QC completed!', { position: 'top-right', duration: 2000 });
		navigate('/');
	};

	const renderDevUploadTT = () => {
		if (!loading && !postQCPerformed && overwriteOnPostQC) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Perform Post-QC, upload to development and <strong>overwrite</strong>{' '}
					database
				</Tooltip>
			);
		}

		if (!loading && !postQCPerformed && !overwriteOnPostQC) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Perform Post-QC, upload to development but{' '}
					<strong>do not overwrite</strong> database
				</Tooltip>
			);
		}

		if (loading) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Performing Post-QC and uploading to development...
				</Tooltip>
			);
		}

		if (!loading && postQCPerformed) {
			return (
				<Tooltip id="performPostQCTT" place="bottom" effect="solid">
					Post-QC already performed and captures uploaded to development
				</Tooltip>
			);
		}

		return null;
	};

	const renderProdUploadTT = () => {
		if (!postQCPerformed) {
			return (
				<Tooltip id="uploadCapturesTT" place="bottom" effect="solid">
					You cannot upload captures before Post-QC has been performed and they
					have been uploaded to development
				</Tooltip>
			);
		}

		if (!loading && postQCPerformed && !uploaded) {
			return (
				<Tooltip id="uploadCapturesTT" place="bottom" effect="solid">
					Upload captures to the <strong>production</strong> database
				</Tooltip>
			);
		}

		if (postQCPerformed && uploaded) {
			return (
				<Tooltip id="uploadCapturesTT" place="bottom" effect="solid">
					Captures already uploaded to production
				</Tooltip>
			);
		}
	};

	const renderDevUploadButton = () => {
		if (!loading && !postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButton"
					data-tooltip-id="performPostQCTT"
					onClick={(e) => runPostQCAndUploadToDev()}
				>
					Upload to development
				</button>
			);
		}

		if (loading && postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="performPostQCTT"
				>
					<span>Uploading to dev</span>
					<span className="AnimatedEllipsis ps-3"></span>
				</button>
			);
		}

		if (!loading && postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="performPostQCTT"
				>
					Uploaded to dev <i className="ps-1 fa-solid fa-circle-check"></i>
				</button>
			);
		}

		return null;
	};

	const renderProdUploadButton = () => {
		if (loading || !postQCPerformed) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="uploadCapturesTT"
				>
					Upload to production
				</button>
			);
		}

		if (loading && postQCPerformed && uploadedToDev && !uploadedToProd) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="performPostQCTT"
				>
					<span>Uploading to prod</span>
					<span className="AnimatedEllipsis ps-3"></span>
				</button>
			);
		}

		if (postQCPerformed && !uploadedToProd && uploadedToDev && !loading) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButton"
					data-tooltip-id="uploadCapturesTT"
					onClick={(e) => uploadToProdDB()}
				>
					Upload to production
				</button>
			);
		}

		if (uploadedToProd) {
			return (
				<button
					style={{ width: '200px' }}
					className="btn interactButtonDisabled"
					data-tooltip-id="uploadCapturesTT"
				>
					Uploaded to prod <i className="ps-1 fa-solid fa-circle-check"></i>
				</button>
			);
		}

		return null;
	};

	const renderFinishQCButton = () => {
		if (uploadedToDev && uploadedToProd) {
			return (
				<>
					<button
						className="btn interactButton"
						data-tooltip-id="QCfinishedTT"
						style={{ height: '40px', width: '190px' }}
						onClick={(e) => finishQC()}
					>
						Finish QC
						<i className="ps-2 fa-solid fa-circle-check"></i>
					</button>
					<Tooltip id="QCfinishedTT" place="bottom" effect="solid">
						You can now finish QC and review the report
					</Tooltip>
				</>
			);
		} else {
			return (
				<>
					<button
						className="btn interactButtonDisabled"
						data-tooltip-id="QCNotfinishedTT"
						style={{ height: '40px', width: '190px' }}
					>
						Finish QC
					</button>
					<Tooltip id="QCNotfinishedTT" place="bottom" effect="solid">
						Complete all steps of uploaded before completing QC
					</Tooltip>
				</>
			);
		}
	};

	const finishQC = () => {
		const urls = divideReturnQCList(capsToSendBack);

		for (let i in urls) {
			sendCapsBack(
				`${config.baseUrl}/${
					pipelineContextQCUpload.comm
				}/reset_captures?table=${
					config.tableNames[pipelineContextQCUpload.comm]
				}&captureIds=${urls[i]}`,
				'POST',
				null,
				'sending caps back'
			);
		}

		// reset everything else here
		pipelineContextQCUpload.setCapsInPipeline([]);
		pipelineContextQCUpload.setProgressBar({ width: 100, title: 'QC Report' });
		pipelineContextQCUpload.setQCInProgress(false)
	};

	console.log(manCapsUpload);
	console.log(postQCData);
	console.log(loading);
	console.log(postQCPerformed);
	console.log(uploadedToDev);
	console.log(uploadedToProd);
	console.log(pipelineContextQCUpload);

	return (
		<>
			<Row className="mt-4 mb-2 text-center">
				<h3>Upload Captures</h3>
				{/* <button onClick={(e)=>setLoading(false)}> </button> */}
			</Row>
			<Row>
				{apiError && (
					<ErrorComp error={apiError} setProgressBar={setProgressBar} />
				)}
				<Col></Col>
				<Col className="d-flex justify-content-center ">
					<>
						<div className="table-responsive">
							<table className="table" style={{ tableLayout: 'auto' }}>
								<tr>
									<td
										className="fw-bold px-4 py-2"
										style={{ whiteSpace: 'nowrap' }}
									>
										Step 1
									</td>
									<td className="px-4 py-2">{renderDevUploadButton()}</td>

									{!postQCPerformed && (
										<td
											className="px-4 py-2 "
											style={{ whiteSpace: 'nowrap' }}
											rowSpan={2}
										>
											<div className="d-flex flex-column bg-light">
												<p>
													Perform post-QC and upload to development<br></br>
													Overwrite existing data in database?
												</p>

												<div>
													<input
														className="form-check-input form-commodity"
														key="overwrite1"
														type="radio"
														id="overwritePostQCFalse"
														name="overwritePostQC"
														value={false}
														onChange={(e) => setOverwriteOnPostQC(false)}
														checked={overwriteOnPostQC === false}
													></input>
													<label
														for="overwritePostQCFalse"
														className="px-2 form-label"
													>
														Do not overwrite
													</label>
												</div>
												<div>
													<input
														className="form-check-input form-commodity"
														key="overwrite2"
														type="radio"
														id="overwritePostQCTrue"
														name="overwritePostQC"
														value={true}
														onChange={(e) => setOverwriteOnPostQC(true)}
														checked={overwriteOnPostQC === true}
													></input>
													<label
														for="overwritePostQCTrue"
														className="px-2 form-label"
													>
														Overwrite
													</label>
												</div>
											</div>
										</td>
									)}
								</tr>

								<tr>
									<td
										className="fw-bold px-4 py-2"
										style={{ whiteSpace: 'nowrap' }}
									>
										Step 2
									</td>

									<td className="px-4 py-2">{renderProdUploadButton()}</td>
								</tr>
							</table>
						</div>

						{renderDevUploadTT()}
						{renderProdUploadTT()}
					</>
				</Col>
				<Col className="d-flex justify-content-end">
					{renderFinishQCButton()}
				</Col>
			</Row>

			{!pipelineContextQCUpload.straightToUpload ? (
				<>
					<Row className="mt-5 px-2">
						<h5>Captures QC'd ({manCapsUpload.length})</h5>
						<p>
							Captures QC'd are available to be uploaded. <br></br>
							Captures not QC'd will be removed from your pipeline upon
							completion of QC.
						</p>
						{pipelineContextQCUpload.comm === 'stockpiles' && (
							<div className="table-responsive" style={{ maxHeight: '40vh' }}>
								<StockpilesTableBasic
									props={props}
									captures={manCapsUpload}
									capturesToReset={[]}
									postQCPerformed={postQCPerformed}
									uploadedToDev={uploadedToDev}
									uploadedToProd={uploadedToProd}
								/>
							</div>
						)}

						{pipelineContextQCUpload === 'smelters' && (
							<div className="table-responsive" style={{ maxHeight: '40vh' }}>
								<SmeltersTableBasic
									props={props}
									captures={manCapsUpload}
									capturesToReset={[]}
								/>
							</div>
						)}
					</Row>

					{/* {capsToSendBack.length > 0 && (
						<Row className="mt-5 px-2">
							<h5>Captures not QC'd ({capsToSendBack.length})</h5>
							<p>
								These captures will be removed from your pipeline when you
								complete the QC process
							</p>

							<div className="table-responsive" style={{ maxHeight: '40vh' }}>
								{pipelineContextQCUpload.comm === 'stockpiles' && (
									<div
										className="table-responsive"
										style={{ maxHeight: '40vh' }}
									>
										<StockpilesTableBasic
											props={props}
											captures={capsToSendBack}
											capturesToReset={[]}
										/>
									</div>
								)}

								{pipelineContextQCUpload === 'smelters' && (
									<div
										className="table-responsive"
										style={{ maxHeight: '40vh' }}
									>
										<SmeltersTableBasic
											props={props}
											captures={capsToSendBack}
											capturesToReset={[]}
										/>
									</div>
								)}
							</div>
						</Row>
					)} */}
				</>
			) : (
				<>
					<Row className="mt-5 px-2">
						<h5>Captures QC'd ({manCapsUpload.length})</h5>

						{!uploaded && !postQCPerformed && (
							<p>
								These captures have been QC'd and are available to be uploaded.{' '}
								<br></br>Perform <strong>Post-QC</strong> first, then choose the
								database and perform <strong>upload</strong>.
							</p>
						)}

						{!uploaded && postQCPerformed && (
							<p>
								These captures have been QC'd, Post-QC has been performed and
								are ready to be uploaded. <br></br>Choose the database and
								perform <strong>upload</strong>.
							</p>
						)}

						{uploaded && postQCPerformed && (
							<p>
								These captures have been successfully uploaded to the{' '}
								<strong>{uploadToDatabase}</strong> database. <br></br>You can
								now finish QC and view the report for these captures.
							</p>
						)}

						{pipelineContextQCUpload.comm === 'stockpiles' && (
							<div className="table-responsive" style={{ maxHeight: '40vh' }}>
								<StockpilesTableBasic
									props={props}
									captures={manCapsUpload}
									capturesToReset={[]}
									postQCPerformed={postQCPerformed}
									uploadedToDev={uploadedToDev}
									uploadedToProd={uploadedToProd}
									allowJumpCapture={false}
								/>
							</div>
						)}

						{pipelineContextQCUpload.comm === 'smelters' && (
							<div className="table-responsive" style={{ maxHeight: '40vh' }}>
								<SmeltersTableBasic
									props={props}
									captures={manCapsUpload}
									capturesToReset={[]}
								/>
							</div>
						)}
					</Row>
				</>
			)}
		</>
	);
};

export default QC_Upload;
