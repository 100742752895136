import React from 'react';

const QC_Report = ({ manualQCCaptures, qcInProgess, qcCat }) => {
	
	return (
		<div className="mt-5 ms-4">
			<h1>FINISHED !!</h1>
			<h3>Well done.</h3>
			<h5>(This isn't what this page will actually look like by the way.)</h5>
		</div>
	);
};

export default QC_Report;
