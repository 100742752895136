import { useState, useCallback } from 'react';
import toast from 'react-hot-toast';

export const conditionalToastPromise = (promise, msjString, method) => {
	if (msjString) {
		return toast.promise(
			promise,
			{
				loading:
					method === 'GET'
						? `Fetching ${msjString}...`
						: `Posting ${msjString}...`,
				success:
					method === 'GET'
						? `${
								msjString.charAt(0).toUpperCase() + msjString.slice(1)
						  } fetched successfully!`
						: `${
								msjString.charAt(0).toUpperCase() + msjString.slice(1)
						  } posted successfully!`,
				error: (err) =>
					method === 'GET'
						? `${err.message} failed to fetch ${msjString}`
						: `${err.message} failed to post ${msjString}`
				// error: (err) => ({
				// 	render: () => (
				// 		<div>
				// 			<p>Failed to fetch data: {err.message}</p>
				// 			<button onClick={() => toast.dismiss()}>Dismiss</button>
				// 		</div>
				// 	),
				// 	//icon: <FiXCircle style={{ color: 'red' }} />,
				// 	style: {
				// 		border: '1px solid #f44336',
				// 		padding: '16px',
				// 		color: '#f44336',
				// 	},
				// 	duration: Infinity, // Keep the toast visible until manually dismissed
				// }),
			},
			{
				position: 'top-right',
				style: {
					minWidth: '250px',
					textAlign: 'left',
					background: '#333',
					color: '#fff'
				}
			}
		);
	}
	return promise;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function useGainsQCApi() {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const makeRequest = useCallback(
		async (url, method, body = null, msjString, fakeDelay) => {
			setLoading(true);
			setError(null);

			const options = {
				method,
				headers: {
					accept: 'application/json'
				},
				body: body ? JSON.stringify(body) : null
			};

			const fetchPromise = fetch(url, options)
				.then((response) => {
					if (!response.ok) {
						setLoading(false);
						throw new Error(`Error ${response.status}: ${response.statusText}`);
					}
					return response.json();
				})
				.then(async (data) => {
					await delay(fakeDelay);
					setData(data);
					setLoading(false);
				});

			conditionalToastPromise(fetchPromise, msjString, method);

			try {
				await fetchPromise;
			} catch (err) {
				setError(err);
				setLoading(false);
			}
		},
		[]
	);

	const resetData = useCallback(() => {
		setData(null);
	}, []);

	const overrideData = (newData) => {
		setData(newData);
	};

	return { data, loading, error, makeRequest, resetData, overrideData };
}

export default useGainsQCApi;
