import { Row, Col } from 'reactstrap';

const ErrorComp = ({ error, setProgressBar }) => {
	return (
		<>
			<hr></hr>
			<Row>
				<Col className="d-flex justify-content-center">
					<div>
						<h6 className="text-danger">Error from database: {error.error}</h6>
						<p className="text-center">{error.message}</p>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default ErrorComp;
