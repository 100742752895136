const divideReturnQCList = (capsToReset) => {
	let listOfLists = [];
	function divideListIntoSublists(originalList, sublistSize) {
		const dividedLists = [];

		for (let i = 0; i < originalList.length; i += sublistSize) {
			dividedLists.push(originalList.slice(i, i + sublistSize));
		}

		return dividedLists;
	}

	const capsQC3 = capsToReset.filter((cap) => cap.qc_state === 3);
	const listofIds = capsQC3.map((obj) => obj.id);
	const originalList = listofIds;
	const sublistSize = 200;
	const sublists = divideListIntoSublists(originalList, sublistSize);

	for (let i in sublists) {
		let apiStr = sublists[i].join(',');
		listOfLists.push(apiStr);
	}

	return listOfLists;
};

export default divideReturnQCList;
