import { Auth } from 'aws-amplify';

export const getAuthHeaders = async () => {
	try {
		const user = await Auth.currentAuthenticatedUser();
		const token = user.signInUserSession.idToken.jwtToken;
		return {
			Authorization: token
		};
	} catch (err) {
		console.log('Error getting auth headers', err);
		return {
			Authorization: null
		};
	}
};

export const getAuthenticatedUserAttributes = async () => {
	try {
		const { username, attributes } = await Auth.currentUserInfo();
		const { idToken: { jwtToken: authToken } } = await Auth.currentSession();
		let { sub, given_name, family_name, email } = attributes;
		const givenName = given_name.trim();
		const familyName = family_name.trim();
		email = email.trim();

		return {
			sub,
			username,
			givenName,
			familyName,
			email,
			initials: givenName.substr(0, 1).toUpperCase() + familyName.substr(0, 1).toUpperCase(),
			authToken
		};
	} catch (err) {
		console.log('Error getting authenticated user attributes', err);
		return {};
	}
};

export const getAuthenticatedUserGroups = async () => {
	try {
		const { idToken } = await Auth.currentSession();
		return {
			authenticatedUserGroups: idToken.decodePayload()['cognito:groups'] || []
		};
	} catch (err) {
		console.log('Error getting authenticated user groups', err);
		return [];
	}
};
