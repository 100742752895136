import './App.css';
import React, {
	useState,
	useEffect,
	useRef,
	createContext,
	useContext
} from 'react';
import IdleTimer, { IdleTimerProvider } from 'react-idle-timer';
import { Auth } from 'aws-amplify';
import Routes from './routes';
import {
	getAuthenticatedUserGroups,
	getAuthenticatedUserAttributes
} from './actions/authUtils';
import config from './config';
import { Toaster } from 'react-hot-toast';
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { putInfo } from './actions/getActions';
import { getInfo } from './actions/getActions';
import { getDashboardData } from './actions/getActions';
import { EndpointProvider } from './contexts/endpointContext';
import { PipelineProvider, pipelineContext } from './contexts/pipelineContext';
import useGainsQCApi from './services/useGainsQCApi';
import divideReturnQCList from './helpers/divideReturnQCList';

export default function App() {
	const [environment, setEnvironment] = useState('prod');
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isAuthenticating, setIsAuthenticating] = useState(false);
	const [authGroups, setAuthGroups] = useState([]);
	const [userAttributes, setUserAttributes] = useState({});
	const [showWarning, setShowWarning] = useState(null);
	const [logoutTimer, setLogoutTimer] = useState(null);

	const idleTimerRef = useRef(null);
	const [remainingTime, setRemainingTime] = useState(null);
	const idleTimeout = config.idleTimeout; // Assuming you have config.idleTimeout defined
	const [changePage, setChangePage] = useState('');

	const [qcInProgress, setQcInProgress] = useState(false);
	const [capturesInPipeline, setCapturesInPipeline] = useState([]);

	const [straightToQC, setStraightToQC] = useState(false);
	const [straightToUpload, setStraightToUpload] = useState(false);

	const [sitesSmelters, setSitesSmelters] = useState([]);
	const [smelterSitesWithCaptures, setSmelterSitesWithCaptures] = useState([]);
	const [availableCapsSmelters, setAvailableCapsSmelters] = useState();

	const [sitesStockpiles, setSitesStockpiles] = useState([]);
	const [stockpilesSitesWithCaptures, setStockpilesSitesWithCaptures] =
		useState([]);
	const [availableCapsStockpiles, setAvailableCapsStockpiles] = useState();

	const pipelineContextApp = useContext(pipelineContext);
	const { error: errorSendingCapsBack, makeRequest: sendCapsBack } =
		useGainsQCApi();

	//const [userApp, setUserApp] = useState(null);

	useEffect(() => {
		setShowWarning(false);
		const interval = setInterval(() => {
			if (idleTimerRef.current) {
				const remaining = idleTimerRef.current.getRemainingTime();
				setRemainingTime(remaining > 0 ? remaining : 0); // Ensure remaining time is not negative
			}
		}, 1000); // Update every second

		return () => clearInterval(interval); // Cleanup interval
	}, []);

	// const fetchDashboardData = async (environment, setState) => {
	// 	getDashboardData(environment, setState);
	// };

	const handleOnActive = () => {
		console.log('active');
	};

	const onIdle = () => {
		if (isAuthenticated) {
			setShowWarning(true);
			setLogoutTimer(setTimeout(logoutUser, 1000 * 60 * 5));
		}
	};

	const handleCloseModal = () => {
		clearTimeout(logoutTimer);
		setShowWarning(false);
	};

	const userHasAuthenticated = (isAuthenticated, authGroups, userEmail) => {
		localStorage.setItem('authenticatedUser', userEmail);

		setIsAuthenticated(isAuthenticated);
		setAuthGroups(authGroups);
	};

	useEffect(() => {
		const loginUser = async () => {
			try {
				const { idToken } = await Auth.currentSession();

				if (idToken) {
					const { authenticatedUserGroups: authGroups } =
						await getAuthenticatedUserGroups();

					getUserAttributes().catch(console.error);
				}
			} catch (e) {
				if (e !== 'No current user') {
					console.error('Unexpected error in App:componentDidMount', e);
				}
			}
		};

		const getUserAttributes = async () => {
			const userAttributesAuth = await getAuthenticatedUserAttributes();

			// console.log(userAttributesAuth)
			// const { authenticatedUserGroups: authGroups } =
			// 	await getAuthenticatedUserGroups();

			if (userAttributesAuth) {
				setUserAttributes({
					givenName: userAttributesAuth.givenName,
					familyName: userAttributesAuth.familyName,
					email: userAttributesAuth.email,
					initials: userAttributesAuth.initials
				});

				userHasAuthenticated(true, authGroups);
			}
		};

		loginUser().catch(console.error);
	}, []);

	const authProps = {
		isAuthenticated: isAuthenticated,
		isAuthenticating: isAuthenticating,
		setIsAuthenticating: setIsAuthenticating,
		userHasAuthenticated: userHasAuthenticated,
		authGroups: authGroups,
		userAttributes: userAttributes,
		setUserAttributes: setUserAttributes,
		environment: environment,
		setEnvironment: setEnvironment
	};

	const qcProps = {
		setQcInProgress: setQcInProgress,
		qcInProgress: qcInProgress,
		capturesInPipeline: capturesInPipeline,
		setCapturesInPipeline: setCapturesInPipeline,
		straightToQC: straightToQC,
		setStraightToQC: setStraightToQC,
		straightToUpload: straightToUpload,
		setStraightToUpload: setStraightToUpload
	};

	const endpointProps = {
		sitesSmelters: sitesSmelters,
		setSitesSmelters: setSitesSmelters,
		availableCapsSmelters: availableCapsSmelters,
		setAvailableCapsSmelters: setAvailableCapsSmelters,
		smelterSitesWithCaptures: smelterSitesWithCaptures,
		setSmelterSitesWithCaptures: setSmelterSitesWithCaptures,
		sitesStockpiles: sitesStockpiles,
		setSitesStockpiles: setSitesStockpiles,
		availableCapsStockpiles: availableCapsStockpiles,
		setAvailableCapsStockpiles: setAvailableCapsStockpiles,
		stockpilesSitesWithCaptures: stockpilesSitesWithCaptures,
		setStockpilesSitesWithCaptures: setStockpilesSitesWithCaptures
	};

	const logoutUser = async () => {
		if (pipelineContextApp.capsInPipeline.length > 0) {
			const urls = divideReturnQCList(pipelineContextApp.capsInPipeline);

			for (let i in urls) {
				sendCapsBack(
					`${config.baseUrl}/${pipelineContextApp.comm}/reset_captures?table=${
						config.tableNames[pipelineContextApp.comm]
					}&captureIds=${urls[i]}`,
					'POST',
					null,
					'sending caps back'
				);
			}
		}

		setShowWarning(false);
		setQcInProgress(false);
		pipelineContextApp.resetState();
		setCapturesInPipeline([]);

		await Auth.signOut();
		setIsAuthenticated(false);
		setIsAuthenticating(false);
		setAuthGroups([]);
	};

	return (
		<div id="appContainer">
			{isAuthenticated && <Toaster />}

			<EndpointProvider env={environment}>
				<PipelineProvider>
					<IdleTimerProvider
						//ref={idleTimerRef}
						element={document}
						onIdle={onIdle}
						timeout={idleTimeout}
						onActive={handleOnActive}
					>
						<Routes
							authProps={authProps}
							qcProps={qcProps}
							endpointProps={endpointProps}
						/>
					</IdleTimerProvider>
				</PipelineProvider>
			</EndpointProvider>

			<div>
				<Modal isOpen={showWarning} onHide={handleCloseModal}>
					<ModalHeader closeButton>Session inactive</ModalHeader>
					<ModalBody>
						<p>Your session will expire in 5 minutes due to inactivity.</p>
					</ModalBody>
					<ModalFooter>
						<Button variant="secondary" onClick={logoutUser}>
							<i className="fas fa-fw fa-sign-out-alt mr-1" /> Logout
						</Button>
						<Button variant="secondary" onClick={handleCloseModal}>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		</div>
	);
}
