import React, { useState, useEffect, useContext } from 'react';
import earthiLogo from '../images/earthi-rev2.svg';
import packageJson from '../../package.json';
import {
	getAuthenticatedUserGroups,
	getAuthenticatedUserAttributes
} from '../actions/authUtils';
import Logout from './Logout';
import { Col } from 'reactstrap';
import Navlink from './Navlink';
import UserLogo from './UserLogo';
import { endpointContext } from '../contexts/endpointContext';
import { pipelineContext } from '../contexts/pipelineContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const securedElementGroups = ['basic', 'advanced'];

export default function Sidenav(props) {
	const [authGroups, setAuthGroups] = useState([]);
	const [showSecuredElements, setShowSecuredElements] = useState(false);
	const [userAttributes, setUserAttributes] = useState({});
	const [qcInProgress, setQcInProgress] = useState(false);
	const [onQCPage, setOnQCPage] = useState(false);
	const [capsInPipeline, setCapsInPipeline] = useState([]);
	const endpointContextSidenav = useContext(endpointContext);
	const pipelineContextSideNav = useContext(pipelineContext);

	useEffect(() => {
		const getUserAttributes = async () => {
			const userAttributesAuth = await getAuthenticatedUserAttributes();
			const { authenticatedUserGroups: authGroups } =
				await getAuthenticatedUserGroups();

			setUserAttributes({
				givenName: userAttributesAuth.givenName,
				familyName: userAttributesAuth.familyName,
				email: userAttributesAuth.email,
				initials: userAttributesAuth.initials
			});

			setAuthGroups(authGroups);
			setShowSecuredElements(
				securedElementGroups.length === 0 ||
					securedElementGroups.some((r) => authGroups.includes(r))
			);
		};

		getUserAttributes().catch(console.error);
	}, []);

	useEffect(() => {
		if (props.location.pathname === '/qc') {
			setOnQCPage(true);
		} else {
			setOnQCPage(false);
		}
	}, [props.location]);

	useEffect(() => {
		setQcInProgress(props.qcProps.qcInProgress);
	}, [props.qcProps.qcInProgress]);

	useEffect(() => {
		setCapsInPipeline(props.qcProps.capturesInPipeline);
	}, [props.qcProps.capturesInPipeline]);

	let userHasAuthenticated = props.authProps.userHasAuthenticated;

	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const toggleSidebar = (e) => {
		e.preventDefault();
		setIsSidebarOpen(!isSidebarOpen);
	};

	const resetQC = () => {
		console.log('resetting QC');
		//pipelineContextSideNav.resetState();
	};

	console.log(pipelineContextSideNav.qcInProgress);

	return (
		<>
			{!isSidebarOpen ? (
				<div
					className=" d-flex justify-content-center"
					style={{ minHeight: '100vh', width: '10vw', padding: '0' }}
				>
					<div
						id="side-nav"
						className="d-flex flex-column flex-shrink-0 p-2 pt-4 text-white bg-dark"
					>
						<div className="d-flex justify-content-evenly">
							<a
								href="#"
								className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
							>
								{/* <svg className="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg> */}
								<img
									src={earthiLogo}
									alt=""
									width="45"
									height="45"
									className="rounded-circle ms-1 me-3"
								></img>
								<span className="fs-4">QC</span>
							</a>

							{/* <button onClick={(e) => collapseSideNav()}>collapse</button> */}
						</div>
						<hr></hr>
						<ul className="nav nav-pills flex-column mb-auto">
							<li
								className="nav-item d-flex justify-content-start"
							>
								<Navlink
									path={'/'}
									icon={'fa-solid fa-house pe-2'}
									text={'Home'}
									userQCing={
										pipelineContextSideNav.capsInPipeline.length > 0
											? true
											: false
									}
									userUploading={pipelineContextSideNav.straightToUpload}
									setUserUploading={pipelineContextSideNav.setStraightToUpload}
									disabled={endpointContextSidenav.loading}
								/>
							</li>
							<li
								className="nav-item d-flex justify-content-start"
							>
								<Navlink
									path={'/qc'}
									icon={'fa fa-solid fa-calendar-day pe-3'}
									text={'QC'}
									userQCing={
										pipelineContextSideNav.capsInPipeline.length > 0
											? true
											: false
									}
									setUserUploading={pipelineContextSideNav.setStraightToUpload}
									userUploading={pipelineContextSideNav.straightToUpload}
									disabled={endpointContextSidenav.loading}
								/>
							</li>
							<li
								className="nav-item d-flex justify-content-start"
							>
								<Navlink
									path={'/users_captures'}
									icon={'fa fa-solid fa-images pe-3'}
									text={'My captures'}
									userQCing={
										pipelineContextSideNav.capsInPipeline.length > 0
											? true
											: false
									}
									userUploading={pipelineContextSideNav.straightToUpload}
									setUserUploading={pipelineContextSideNav.setStraightToUpload}
									disabled={endpointContextSidenav.loading}
								/>
							</li>

							{/* <li className="nav-item d-flex justify-content-start">
								<Navlink
									path={'/testing'}
									icon={'fa fa-solid fa-images pe-3'}
									text={'Testing'}
									userQCing={qcInProgress}
									userUploading={props.qcProps.straightToUpload}
									setUserUploading={props.qcProps.setStraightToUpload}
									disabled={endpointContextSidenav.loading}
								/>
							</li> */}

							{/* <li className="nav-item d-flex justify-content-start">
								<Navlink
									path={'/capture'}
									icon={'fa fa-solid fa-image pe-3'}
									text={'Captures'}
									userQCing={qcInProgress}
									userUploading={props.qcProps.straightToUpload}
									setUserUploading={props.qcProps.setStraightToUpload}
									disabled={endpointContextSidenav.loading}
								/>
							</li> */}
						</ul>

						{/* {pipelineContext.capsInPipeline.length > 0 && (
							<>
								<div class="flashing-dot"></div>
								<p>{pipelineContext.capsInPipeline.length} caps in pipeline</p>
							</>
						)} */}

						{/* {qcInProgress && <><p>QC IN PROGRESS</p> </>}
						{props.qcProps.straightToUpload && <><p>STRAIGHT TO UPLOAD</p> </>}
						{onQCPage && <p>ON QC PAGE</p>}  */}
						<hr></hr>

						<div className="dropdown px-2">
							<div class="dropdown d-flex justify-content-center">
								<button
									class="btn btn-dark dropdown-toggle"
									type="button"
									id="dropdownMenuButton1"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<strong className="px-1" title={userAttributes.email}>
										{userAttributes.initials}
									</strong>
								</button>
								<ul
									class="dropdown-menu bg-dark"
									aria-labelledby="dropdownMenuButton1"
								>
									<li>
										<a class="dropdown-item bg-dark text-white" href="#">
											{' '}
											{userAttributes.givenName} {userAttributes.familyName}
										</a>
									</li>
									<li>
										<a class="dropdown-item bg-dark text-white" href="#">
											{userAttributes.email}
										</a>
									</li>
									<li>
										<a class="dropdown-item bg-dark text-white" href="#">
											App version: {packageJson.version}
										</a>
									</li>
									<li>
										<hr className="dropdown-divider"></hr>
									</li>
									<li>
										<a class="dropdown-item bg-dark text-white" href="#">
											<Logout props={props} />
										</a>
									</li>
								</ul>
							</div>

							{/* <a
								//href="#"
								className="d-flex align-items-center justify-content-center text-white text-decoration-none dropdown-toggle"
								id="dropdownUser5"
								data-bs-toggle="dropdown"
								role="button"
								aria-expanded="false"
								onClick={(e)=>console.log("hello")}
							>
								<strong className="px-1" title={userAttributes.email}>
									{userAttributes.initials}
								</strong>
							</a> */}

							{/* <ul
								className="dropdown-menu dropdown-menu-dark text-small shadow"
								aria-labelledby="dropdownUser5"
							>
								<li>
									<a className="dropdown-item">
										{userAttributes.givenName} {userAttributes.familyName}
									</a>
								</li>
								<li>
									<a className="dropdown-item">{userAttributes.email}</a>
								</li>

								<li>
									<a className="dropdown-item">
										App version: {packageJson.version}
									</a>
								</li>
								<li>
									<hr className="dropdown-divider"></hr>
								</li>
								<li>
									<a className="dropdown-item" href="#">
										<Logout props={props} />
									</a>
								</li>
							</ul> */}
						</div>
					</div>
				</div>
			) : (
				<Col className="px-0 col-2" style={{ minHeight: '100vh' }}>
					<div
						id="side-nav"
						className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark col-2"
					>
						<div className="d-flex justify-content-evenly">
							<a
								href="#"
								className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
							>
								{/* <svg className="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg> */}
								<img
									src={earthiLogo}
									alt=""
									width="55"
									height="55"
									className="rounded-circle ms-1 me-3"
								></img>
								<span className="fs-5">Gains QC</span>
								<i
									onClick={(e) => toggleSidebar(e)}
									className="ps-4 fa-solid fa-caret-left fa-lg"
								></i>
							</a>

							{/* <button onClick={(e) => collapseSideNav()}>collapse</button> */}
						</div>
						<hr></hr>
						<ul className="nav nav-pills flex-column mb-auto">
							<li className="nav-item">
								<Navlink
									path={'/'}
									icon={'fa-solid fa-house pe-3'}
									text={'Dashboard'}
									userQCing={qcInProgress}
								/>
							</li>
							<li className="nav-item" onClick={(e) => toggleSidebar(e)}>
								<Navlink
									path={'/qc'}
									icon={'fa fa-solid fa-magnifying-glass pe-3'}
									text={'QC'}
									userQCing={qcInProgress}
								/>
							</li>
							<li className="nav-item" onClick={(e) => toggleSidebar(e)}>
								<Navlink
									path={'/user_captures'}
									icon={'fa fa-solid fa-images pe-3'}
									text={'My captures'}
									userQCing={qcInProgress}
								/>
							</li>
						</ul>

						{/* {capsInPipeline.length > 0 && (
							<p>{capsInPipeline.length} caps in pipeline</p>
						)}
						{qcInProgress && <p>QC IN PROGRESS</p>}
						{onQCPage && <p>ON QC PAGE</p>} */}
						<hr></hr>
						<div className="dropdown px-2">
							{props.authProps.userAttributes.givenName && (
								<a
									href="#"
									className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
									id="dropdownUser1"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									title={userAttributes.email}
								>
									<UserLogo
										userName={
											props.authProps.userAttributes.email.split('@')[0]
										}
										user={props.authProps.userAttributes}
										widthHeight={40}
										fontSize={20}
									/>
								</a>
							)}

							<ul
								className="dropdown-menu dropdown-menu-dark text-small shadow"
								aria-labelledby="dropdownUser1"
							>
								<li>
									<a className="dropdown-item">
										{userAttributes.givenName} {userAttributes.familyName}
									</a>
								</li>
								<li>
									<a className="dropdown-item">{userAttributes.email}</a>
								</li>

								<li>
									<a className="dropdown-item">
										App version: {packageJson.version}
									</a>
								</li>
								<li>
									<hr className="dropdown-divider"></hr>
								</li>
								<li>
									<a className="dropdown-item" href="#">
										<Logout props={props} />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</Col>
			)}
		</>
	);
}
