import React, { useEffect, useState, useContext } from 'react';
import config from '../../config';
import { Row, Col } from 'reactstrap';
import formatDate from '../../helpers/formatDate';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../../contexts/pipelineContext';

const QC_Form_Daily = ({
	handleSubmit,
	formValue,
	setFormValue,
	formValid,
	setFormValid,
	loading,
	setCapturesUrl,
	user,
}) => {
	const [todayDate, setTodayDate] = useState();
	const pipelineContextQCFormDaily = useContext(pipelineContext)

	useEffect(() => {
		let today = new Date();
		let yes = new Date();
		let yesterday = new Date(yes.setDate(yes.getDate() - 1));
		let dd = new Date(formValue.formEndDate);

		setFormValue({
			...formValue,
			formLookback: 10,
			formEndDateNow: today.toLocaleTimeString(),
			formStartDate: new Date(dd.setDate(dd.getDate() - 10))
				.toISOString()
				.substring(0, 10)
		});
		setTodayDate(today.toISOString().substring(0, 10));
	}, []);

	useEffect(() => {
		validateForm();

		setCapturesUrl(
			`captures?&siteTypeIds=1&siteIds=1112,1114,1176,1174,1178,1180,1404&startDate=${
				formValue.formStartDate
			}&endDate=${formValue.formEndDate}&sensors=${formValue.formSensors.join(
				','
			)}&commodities=${formValue.formCommodities.join(
				','
			)}&prodStates=${formValue.formProdStates.join(
				','
			)}&qcUser=${user.givenName.toLowerCase()}.${user.familyName.toLowerCase()}`
		);
	}, [formValue]);

	const validateForm = () => {
		if (
			formValue.formCommodities.length > 0 &&
			formValue.formProdStates.length > 0 &&
			formValue.formSensors.length > 0 &&
			!parseInt(formValue.formLookback).isNaN
		) {
			setFormValid(true);
		} else {
			setFormValid(false);
		}
	};

	const calcDate = (e) => {
		let eDate, sDate, sDate2;

		console.log(formValue.formLookback);

		if (e.target.name === 'formLookback') {
			eDate = new Date(formValue.formEndDate);
			sDate = eDate.setDate(eDate.getDate() - e.target.value);
			sDate2 = new Date(sDate).toISOString().substring(0, 10);
			setFormValue({
				...formValue,
				formStartDate: sDate2,
				formLookback: e.target.value
			});
		}

		if (e.target.name === 'formEndDate') {
			eDate = new Date(e.target.value);
			sDate = eDate.setDate(eDate.getDate() - formValue.formLookback);
			console.log(sDate);
			console.log(eDate);
			sDate2 = new Date(sDate).toISOString().substring(0, 10);

			setFormValue({
				...formValue,
				formEndDate: e.target.value,
				formStartDate: sDate2
			});
		}
	};

	const handleOnChange = (state) => {
		// if changing sensors
		if (state === 'sentinel-2-l1c' || state === 'landsat-8-l1tp') {
			let newFormSensors = formValue.formSensors;

			if (!formValue.formSensors.includes(state)) {
				newFormSensors.push(state);
			} else {
				let x = newFormSensors.splice(newFormSensors.indexOf(state), 1);
			}

			setFormValue({
				...formValue,
				formSensors: newFormSensors
			});

			// if changing prod state
		} else {
			let newFormProdStates = formValue.formProdStates;

			if (!formValue.formProdStates.includes(state)) {
				newFormProdStates.push(state);
			} else {
				let x = newFormProdStates.splice(newFormProdStates.indexOf(state), 1);
			}

			setFormValue({
				...formValue,
				formProdStates: newFormProdStates
			});
		}
	};

	const handleBackButton = (e) => {
		e.preventDefault();
		setFormValue({
			formSiteId: '',
			formStartDate: '',
			formEndDate: new Date().toISOString().substring(0, 10),
			formAutoQC: true,
			formEndDateNow: '',
			fullHistory: true,
			formSensors: ['sentinel-2-l1c', 'landsat-8-l1tp'],
			formCommodities: [3],
			formProdStates: [0, 1]
		});

		pipelineContextQCFormDaily.setProgressBar({ width: 2*(100/7), title: 'QC Type' })
		pipelineContextQCFormDaily.setQCType(undefined)
	};

	console.log(formValue);

	return (
		<>
			<hr></hr>
			<form onSubmit={handleSubmit} className="px-3 mx-3">
				<Row>
					<Col className="d-flex justify-content-start">
						<button
							className="btn interactButtonSecondary"
							style={{ maxHeight: '40px', width: '200px' }}
							onClick={handleBackButton}
						>
							<i class="fa-solid fa-left-long pe-3"></i>
							Back
						</button>
					</Col>
					<Col></Col>
					<Col className="d-flex justify-content-end">
						{formValid && !loading ? (
							<button
								type="submit"
								className="btn interactButton mt-2 mx-0"
								style={{ maxHeight: '40px', width: '200px' }}
							>
								View captures <i class="fa-solid fa-right-long ps-2"></i>
							</button>
						) : (
							<button
								disabled
								className="btn btn-secondary mt-2 mx-0"
								style={{ maxHeight: '40px', width: '200px' }}
							>
								View captures <i class="fa-solid fa-right-long ps-2"></i>
							</button>
						)}
					</Col>
				</Row>

				<Row>
					<h4 className="py-5">Fill in the captures criteria for daily QC</h4>

					<Col className="col-lg-2 col-6">
						<div className="form-group">
							<h5 className="card-title">Sensor</h5> <br></br>
							<input
								className="form-check-input form-sensor"
								type="checkbox"
								id="landsat8"
								name="formSensors"
								value="landsat-8-l1tp"
								onChange={(e) => handleOnChange('landsat-8-l1tp')}
								checked={formValue.formSensors.includes('landsat-8-l1tp')}
							></input>
							<label className="px-2" for="landsat8">
								landsat-8-l1tp
							</label>
							<br></br>
							<input
								className="form-check-input form-sensor"
								type="checkbox"
								id="sentinel"
								name="formSensors"
								value="sentinel-2-l1c"
								onChange={(e) => handleOnChange('sentinel-2-l1c')}
								checked={formValue.formSensors.includes('sentinel-2-l1c')}
							></input>
							<label className="px-2" for="sentinel">
								sentinel-2-l1c
							</label>
							<br></br>
							{formValue.formSensors.length === 0 && (
								<p id="sensor-err" className="text-warning pt-3">
									<i class="fa-solid fa-triangle-exclamation"></i> Select at
									least one sensor
								</p>
							)}
						</div>
					</Col>

					<Col className="col-lg-2 col-6">
						<div className="form-group">
							<h5 className="card-title">Commodity</h5>

							<br></br>
							{config.commodities.map((comm, idx) => (
								<>
									<input
										className="form-check-input form-commodity"
										key={idx}
										type="radio"
										id={comm.id}
										name="daily-qc-comms"
										value={comm.index}
										onClick={(e) =>
											setFormValue({
												...formValue,
												formCommodities: [comm.index]
											})
										}
										checked={formValue.formCommodities.includes(comm.index)}
										// onClick={(e) =>
										// 	commodityProdTypes(e, comm.id, comm.index)
										// }
										// onChange={(e) =>
										// 	setFormValue({
										// 		...formValue,
										// 		formCommodities: [...formValue.formCommodities, comm.index]
										// 	})
										// }
									></input>
									<label className="px-2" for={comm.id}>
										{comm.commodity}
									</label>
									<br></br>
								</>
							))}
						</div>
						<br></br>

						{formValue.formCommodities.length === 0 && (
							<p id="comm-err" className="text-warning">
								<i class="fa-solid fa-triangle-exclamation"></i> Select at least
								one commodity
							</p>
						)}
					</Col>

					<Col className="col-lg-4 col-12">
						<h5 className="card-title">Production State</h5> <br></br>
						{config.production_states.map(
							({ state, descript, formText }, idx) => (
								<>
									<input
										className="form-check-input form-prod-state"
										type="checkbox"
										id={state}
										name="daily-qc-prods"
										value={state}
										checked={formValue.formProdStates.includes(state)}
										onChange={() => handleOnChange(state)}
									></input>
									<label
										className="px-2"
										for={state}
										data-tooltip-id={`prodStatToolTip${state}`}
									>
										{`${state} - ${formText}`}
									</label>{' '}
									<Tooltip
										id={`prodStatToolTip${state}`}
										place="top"
										effect="solid"
									>
										{descript}
									</Tooltip>
									<br></br>
								</>
							)
						)}
						{formValue.formProdStates.length === 0 && (
							<p id="prod-state-err" className="text-warning pt-3">
								<i class="fa-solid fa-triangle-exclamation"></i> Select at least
								one production state
							</p>
						)}
					</Col>

					<Col className="col-lg-4 col-12">
						<div className="form-group">
							<h5 className="card-title">Interval</h5> <br></br>
							<label for="num-lookback-days">
								Days lookback: <strong>{formValue.formLookback}</strong>
							</label>
							<input
								type="range"
								className="form-range"
								id="lookback-range"
								name="formLookback"
								min="1"
								max="10"
								step="1"
								value={formValue.formLookback}
								defaultValue="10"
								onChange={(e) => calcDate(e)}
							></input>
							<Row>
								<Col className="col-6 pe-1">
									<label for="start_date">Start date</label> <br></br>
									<input
										className="form-control text-sm"
										id="start_date"
										name="start_date"
										title="start date of interval"
										type="date"
										value={formValue.formStartDate}
										disabled
									></input>
									<br></br>
								</Col>
								<Col className="col-6 ps-1">
									<label for="end_date">End date</label> <br></br>
									<input
										className="form-control text-sm"
										id="end_date"
										name="formEndDate"
										title="End date of interval"
										type="date"
										max={todayDate}
										value={formValue.formEndDate}
										onChange={(e) => calcDate(e)}
									></input>
									<br></br>
								</Col>

								<p>
									Selecting captures between <br></br>
									<span className="fw-light">
										{formatDate(formValue.formStartDate)}
									</span>{' '}
									at <span className="fw-light">00:00:00</span>
									<br></br> to <br></br>
									<span className="fw-light">
										{formatDate(formValue.formEndDate)}
									</span>{' '}
									at{' '}
									<span className="fw-light">{formValue.formEndDateNow}</span>
								</p>
							</Row>
						</div>

						<div className="form-group mt-5">
							<Row>
								<Col className="d-flex justify-content-end">
									<label
										title="Disabled for now to focus on fully working manual QC pipeline"
										className="px-2 text-decoration-line-through"
										for="set_auto_qc"
									>
										set parameters for Auto QC
									</label>
									<input
										className="form-check-input"
										type="checkbox"
										id="set_auto_qc"
										name="set_auto_qc"
										disabled
										value={formValue.formAutoQC}
										defaultChecked={formValue.formAutoQC}
										onClick={() =>
											setFormValue({
												...formValue,
												formAutoQC: !formValue.formAutoQC
											})
										}
									></input>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>

				{/* <Row
					className="pt-2"
					style={{ position: 'absolute', bottom: '30px', right: '20px' }}
				>
					<Col className="d-flex justify-content-end">
						{formValue.formCommodities.length > 0 &&
						formValue.formProdStates.length > 0 &&
						!loading ? (
							<button type="submit" className="btn interactButton mt-2 mx-0">
								View captures <i class="fa-solid fa-right-long ps-2"></i>
							</button>
						) : (
							<button disabled className="btn btn-secondary mt-2 mx-0">
								View captures <i class="fa-solid fa-right-long ps-2"></i>
							</button>
						)}
					</Col>
				</Row> */}
			</form>
		</>
	);
};

export default QC_Form_Daily;
