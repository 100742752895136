import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';
import config from '../../config';

// helper funcs
import { getSentinelHubUrl } from '../../helpers/leafletHelpers';
import formatDate from '../../helpers/formatDate';

// services
import { pipelineContext } from '../../contexts/pipelineContext';
import useGainsQCApi from '../../services/useGainsQCApi';

// child comps
import QC_Image from './QC_Image';
import Barplot from './Plots/Barplot';
import HotspotsProfile from './Plots/HotspotsProfile';
import PixelCountPlot from './Plots/PixelCountPlot';
import QCing_Form_Smelter from './QCing_Form_Smelter';
import QCing_Form_Stockpiles from './QCing_Form_Stockpiles';
import CompareImageModal from './Modals/CompareImageModal';

const QC_ing = ({
	currentCapture,
	manualQCCaptures,
	autoQCCaptures,
	currentCaptureIndex,
	qcState,
	setQCState,
	onQCSubmit,
	onStockpilesQCSubmit,
	setDisagreeReason,
	numCapsQCd,
	moveToUpload,
	loading,
	editedGeojson,
	setEditedGeojson,
	featureCollection,
	setFeatureCollection,
	featureLayers,
	setFeatureLayers,
	stockpilesFormValue,
	setStockpilesFormValue
}) => {
	const pipelineContextQCing = useContext(pipelineContext);
	console.log(pipelineContextQCing);

	const [modalOpen, setModalOpen] = useState(false);
	const [selectedRadio, setSelectedRadio] = useState();
	const [userSelectedQCState, setUserSelectedQCState] = useState(false);
	const [sentinelUrl, setSentinelUrl] = useState('#');
	const [imageDataError, setImageDataError] = useState(false);
	const [drawnItemsValid, setDrawnItemsValid] = useState(true);
	const [stockpilesGeojsons, setStockpilesGeojsons] = useState();

	const {
		data: imageData2,
		loading: loadingImageData,
		error: errorImageData,
		makeRequest: makeRequestImageData,
		resetData: resetImageData
	} = useGainsQCApi();

	const {
		data: plotData2,
		loading: loadingPlotData,
		error: errorPlotData,
		makeRequest: makeRequestPlotData,
		resetData: resetPlotData
	} = useGainsQCApi();

	const {
		data: hostpotsData,
		loading: loadingHotspotsData,
		error: errorHotspotsData,
		makeRequest: makeRequestHotspotsData,
		resetData: resetHotspotsData,
		overrideData: overrideHotspotsData
	} = useGainsQCApi();

	const {
		data: pixelPlotData2,
		loading: loadingPixelPlotData,
		error: errorPixelPlotData,
		makeRequest: makeRequestPixelPlotData,
		resetData: resetPixelPlotData
	} = useGainsQCApi();

	useEffect(() => {
		/**
		 * on mount, fetch image data
		 * reset form
		 * on unmount, remove image data
		 */

		setUserSelectedQCState(false);
		setStockpilesFormValue(1);

		if (pipelineContextQCing.comm === 'stockpiles') {
			makeRequestImageData(
				`${config.baseUrl}/${pipelineContextQCing.comm}/capture_image?stacItemId=${currentCapture.stac_item_id}&sensor=${currentCapture.stac_collection}&siteId=${currentCapture.site_id}&siteName=${currentCapture.name}`,
				'GET',
				null,
				`capture ${currentCapture.id}`
			);
		} else if (pipelineContextQCing.comm === 'smelters') {
			makeRequestImageData(
				`${config.baseUrl}/${pipelineContextQCing.comm}/capture_image?stacItemId=${currentCapture.stac_item_id}&sensor=${currentCapture.sensor}&siteId=${currentCapture.site_id}`,
				'GET',
				null,
				`capture ${currentCapture.id}`
			);
		}

		return () => {
			resetImageData();
			resetPlotData();
			resetHotspotsData();
			resetPixelPlotData();
		};
	}, [currentCapture]);

	useEffect(() => {
		/**
		 * once imageData has been fetched for capture
		 * fetch additional data (historic plot, hotspots profile, pixel plot)
		 */

		if (imageData2) {
			if (pipelineContextQCing.comm === 'smelters') {
				makeRequestPlotData(
					`${config.baseUrl}/${pipelineContextQCing.comm}/barplot?siteId=${
						currentCapture.site_id
					}&date=${currentCapture.captured.slice(0, 10)}&rawData=true`,
					'GET',
					null,
					'bar plot data'
				);

				if (currentCapture.hotspots > 0) {
					makeRequestHotspotsData(
						`${config.baseUrl}/${pipelineContextQCing.comm}/hotspot_profile?sensor=${currentCapture.sensor}&stacItemId=${currentCapture.stac_item_id}`,
						'GET',
						null,
						'hotspots data'
					);
				} else {
					overrideHotspotsData([]);
				}

				setSentinelUrl(getSentinelHubUrl(imageData2, currentCapture));

				// if/not capture has already been QC'd
				if (currentCapture) {
					if (currentCapture.hasOwnProperty('newQC')) {
						setSelectedRadio(currentCapture.newQC);
						setQCState(currentCapture.newQC);
					} else {
						setSelectedRadio(currentCapture.initial_state);
						setQCState(currentCapture.initial_state);
					}
				}
			} else if (pipelineContextQCing.comm === 'stockpiles') {
				makeRequestPixelPlotData(
					`${config.baseUrl}/${pipelineContextQCing.comm}/pixel_count?siteId=${currentCapture.site_id}`,
					'GET',
					null,
					'pixel plot data'
				);

				setStockpilesGeojsons({
					0: {
						original: imageData2.hasOwnProperty('stockpiles')
							? JSON.parse(imageData2.stockpiles).features
							: [],
						edited: [],
						deleted: [],
						created: [],
						simplified: []
					}
				});
			}
		}
	}, [imageData2]);

	const userHasSelected = (selectedState) => {
		setUserSelectedQCState(true);
		setQCState(selectedState);
		setSelectedRadio(selectedState);
	};

	const handleQCReasonChange = (reasonCode) => {
		setDisagreeReason(reasonCode);
	};

	const skipCapture = (e) => {
		/**
		 * if user skips capture, move to next
		 * if capture is last capture, jump to first capture not QC'd
		 */

		e.preventDefault();

		if (
			pipelineContextQCing.currentCaptureIndex !==
			pipelineContextQCing.capsInPipeline.length - 1
		) {
			pipelineContextQCing.setCurrentCaptureIndex(
				pipelineContextQCing.currentCaptureIndex + 1
			);
		} else {
			pipelineContextQCing.setCurrentCaptureIndex(0);
		}
	};

	console.log(currentCapture);
	console.log(featureCollection)

	if (pipelineContextQCing.comm === 'smelters') {
		return (
			<>
				<div style={{ paddingX: '20px' }}>
					<hr></hr>

					{currentCapture && imageData2 && (
						<>
							<CompareImageModal
								imageData={imageData2}
								imageType="True Colour"
								imageUrl={imageData2.true_color}
								modalId={'id1'}
								currentCap={currentCapture}
							/>
							<CompareImageModal
								imageData={imageData2}
								imageType="Ratio"
								imageUrl={imageData2.ratio}
								modalId={'id2'}
								currentCap={currentCapture}
							/>
							<CompareImageModal
								imageData={imageData2}
								imageType="Composite"
								imageUrl={imageData2.comp}
								modalId={'id3'}
								currentCap={currentCapture}
							/>
						</>
					)}

					<div id="viewer" />

					<Row className="px-5" style={{ height: '5vh' }}>
						<Col>
							<p className="py-0 my-0" data-tooltip-id="QCingSiteInfo">
								<i class="fa-solid fa-location-dot me-2"></i>
								{!currentCapture ? (
									<Skeleton width={80} />
								) : (
									`${currentCapture.site_id} - ${currentCapture.site_name}`
								)}
							</p>
							<Tooltip id="QCingSiteInfo" place="top" effect="solid">
								Site id - Site name
							</Tooltip>
						</Col>
						<Col className="d-flex justify-content-center">
							<Row>
								<div className="d-flex justify-content-end">
									<p
										className="text-capitalize ps-4 py-0 my-0"
										data-tooltip-id="QCingCaptureInfo"
									>
										<i className="fa-regular fa-image  me-2"></i>
										{!currentCapture ? (
											<Skeleton width={80} />
										) : (
											`${currentCapture.id} - ${currentCapture.sensor}`
										)}
									</p>
									<Tooltip id="QCingCaptureInfo" place="top" effect="solid">
										Capture id - Sensor
									</Tooltip>
								</div>
							</Row>
						</Col>
						<Col className="d-flex justify-content-center">
							<Row>
								<div className="d-flex justify-content-end">
									<p
										className="text-capitalize ps-4 py-0 my-0"
										data-tooltip-id="QCingCaptureDate"
									>
										<i class="fa-solid fa-calendar-day me-2"></i>
										{!currentCapture ? (
											<Skeleton width={80} />
										) : (
											`${formatDate(currentCapture.captured.slice(0, 10))}`
										)}
									</p>
									<p className="ms-2">
										<a href={sentinelUrl} target="_blank">
											Sentinel Hub
										</a>
									</p>
									<Tooltip id="QCingCaptureDate" place="top" effect="solid">
										Capture date
									</Tooltip>
								</div>
							</Row>
						</Col>
						<Col className="d-flex justify-content-end">
							<p className="mx-3" data-tooltip-id="QCingCloudFracInfo">
								<i class="fa-solid fa-cloud pe-1"></i>
								{!currentCapture ? (
									<Skeleton width={30} />
								) : (
									currentCapture.cloud_frac
								)}
							</p>
							<Tooltip id="QCingCloudFracInfo" place="top" effect="solid">
								{currentCapture &&
									`Cloud fraction - ${currentCapture.cloud_frac}`}
							</Tooltip>

							<p className="mx-3" data-tooltip-id="QCingNoHotspots">
								<i class="fa-solid fa-fire pe-1"></i>
								{!currentCapture ? (
									<Skeleton width={30} />
								) : (
									currentCapture.hotspots
								)}
							</p>
							<Tooltip id="QCingNoHotspots" place="top" effect="solid">
								{currentCapture &&
									`Number of hotspots - ${currentCapture.hotspots}`}
							</Tooltip>

							<p className="mx-3" data-tooltip-id="QCingValidDataFracInfo">
								<i class="fa-solid fa-chart-line pe-1"></i>
								{!currentCapture ? (
									<Skeleton width={30} />
								) : (
									currentCapture.invalid_data_frac
								)}
							</p>
							<Tooltip id="QCingValidDataFracInfo" place="top" effect="solid">
								{currentCapture &&
									`Invalid data fraction - ${currentCapture.invalid_data_frac}`}
							</Tooltip>

							<p className="mx-3" data-tooltip-id="QCingMBRInfo">
								<i class="fa-solid fa-signal pe-1"></i>
								{!currentCapture ? (
									<Skeleton width={30} />
								) : (
									currentCapture.max_band_ratio
								)}
							</p>
							<Tooltip id="QCingMBRInfo" place="top" effect="solid">
								{currentCapture &&
									`Max band ratio - ${currentCapture.max_band_ratio}`}
							</Tooltip>

							{!currentCapture ? (
								<>
									<p className="mx-3">
										<i class="fa-solid fa-volcano"></i> <Skeleton width={30} />
									</p>
									<Tooltip id="QCingUseSmokeYes" place="top" effect="solid">
										Use smoke (yes)
									</Tooltip>
								</>
							) : (
								<>
									<p className="mx-3">
										{currentCapture.use_smoke === 1 ? (
											<i
												class="fa-solid fa-volcano"
												data-tooltip-id="QCingUseSmokeYes"
											></i>
										) : (
											<div style={{ position: 'relative' }}>
												<div style={{ position: 'absolute' }}>
													<i
														class="fa-solid fa-volcano"
														data-tooltip-id="QCingUseSmokeNo"
													></i>
												</div>
												<div style={{ position: 'absolute' }}>
													<i
														class="fa-solid fa-slash"
														data-tooltip-id="QCingUseSmokeNo"
													></i>
												</div>
											</div>
										)}
									</p>
									<Tooltip id="QCingUseSmokeNo" place="top" effect="solid">
										Use smoke (no)
									</Tooltip>
								</>
							)}
						</Col>
					</Row>

					{/* IMAGES */}
					<Row id="qcImagesRow" style={{ height: '45vh' }}>
						<Col xs="12" md="4" className="d-flex justify-content-center">
							{!imageData2 ? (
								<div style={{ width: '100%' }}>
									<Skeleton height={'100%'} data-tooltip-id="imageTrueColour" />
								</div>
							) : (
								<div
									style={{ width: '100%' }}
									// data-bs-toggle="modal"
									// data-bs-target="#id1"
									// onClick={() => setModalOpen(true)}
									data-tooltip-id="imageTrueColour"
								>
									<QC_Image
										modalId={'id1'}
										imageData={imageData2}
										imageUrl={imageData2.data.true_color}
										openModal={modalOpen}
										editedGeojson={editedGeojson}
										setEditedGeojson={setEditedGeojson}
									/>
								</div>
							)}
							<Tooltip id="imageTrueColour" place="top" effect="solid">
								True colour
							</Tooltip>
						</Col>
						<Col xs="12" md="4" className="d-flex justify-content-center">
							{!imageData2 ? (
								<div style={{ width: '100%' }}>
									<Skeleton height={'100%'} data-tooltip-id="imageRatio" />
								</div>
							) : (
								<div
									style={{ width: '100%' }}
									// data-bs-toggle="modal"
									// data-bs-target="#id2"
									data-tooltip-id="imageRatio"
								>
									<QC_Image
										modalId={'id2'}
										imageUrl={imageData2.data.ratio}
										imageData={imageData2}
										openModal={true}
									/>
								</div>
							)}
							{currentCapture && currentCapture.sensor === 'sentinel-2-l1c' ? (
								<Tooltip id="imageRatio" place="top" effect="solid">
									B12/B11
								</Tooltip>
							) : (
								<Tooltip id="imageRatio" place="top" effect="solid">
									B07/B06
								</Tooltip>
							)}
						</Col>
						<Col xs="12" md="4" className="d-flex justify-content-center">
							{!imageData2 ? (
								<div style={{ width: '100%' }}>
									<Skeleton height={'100%'} data-tooltip-id="imageComp" />
								</div>
							) : (
								<div
									style={{ width: '100%' }}
									// data-bs-toggle="modal"
									// data-bs-target="#id3"
									data-tooltip-id="imageComp"
								>
									<QC_Image
										modalId={'id3'}
										imageData={imageData2}
										imageUrl={imageData2.data.comp}
										openModal={true}
									/>
								</div>
							)}
							<Tooltip id="imageComp" place="top" effect="solid">
								Thermal Composite
							</Tooltip>
						</Col>
					</Row>

					{/* BARPLOT - HOTSPOTS - LAST SENSOR STATE - QC FORM */}
					<Row style={{ overflow: 'none', height: '38vh' }}>
						<div className="col-6 mt-3">
							<Barplot
								plotData={plotData2}
								captureDate={currentCapture.captured}
							/>
						</div>
						<div className="col-3 mt-3">
							<HotspotsProfile
								hotspotProfile={hostpotsData}
								fetchHotspotsData={undefined}
							/>
						</div>
						<div className="col-3 mt-3">
							<QCing_Form_Smelter
								onQCSubmit={onQCSubmit}
								currentCap={currentCapture}
								imageData={imageData2}
								userSelectedQCState={userSelectedQCState}
								qcState={qcState}
								selectedRadio={selectedRadio}
								setDisagreeReason={setDisagreeReason}
								numCapsQCd={numCapsQCd}
								userHasSelected={userHasSelected}
								skipCapture={skipCapture}
								imageDataError={imageDataError}
							/>
						</div>
					</Row>
				</div>
			</>
		);
	} else if (pipelineContextQCing.comm === 'stockpiles') {
		return (
			<>
				<div style={{ paddingX: '20px' }}>
					<hr></hr>
					<Row className="px-5" style={{ height: '5vh' }}>
						<Col>
							<p className="py-0 my-0" data-tooltip-id="QCingSiteInfo">
								<i class="fa-solid fa-location-dot me-2"></i>
								{!currentCapture ? (
									<Skeleton width={80} />
								) : (
									`${currentCapture.site_id} - ${currentCapture.name}`
								)}
							</p>
							<Tooltip id="QCingSiteInfo" place="top" effect="solid">
								Site id - Site name
							</Tooltip>
						</Col>
						<Col className="d-flex justify-content-center">
							<Row>
								<div className="d-flex justify-content-end">
									<p
										className="text-capitalize ps-4 py-0 my-0"
										data-tooltip-id="QCingCaptureInfo"
									>
										<i className="fa-regular fa-image  me-2"></i>
										{!currentCapture ? (
											<Skeleton width={80} />
										) : (
											`${currentCapture.id} - ${currentCapture.model_name}`
										)}
									</p>
									<Tooltip id="QCingCaptureInfo" place="top" effect="solid">
										Capture id - Sensor
									</Tooltip>
								</div>
							</Row>
						</Col>
						<Col className="d-flex justify-content-center">
							<Row>
								<div className="d-flex justify-content-end">
									<p
										className="text-capitalize ps-4 py-0 my-0"
										data-tooltip-id="QCingCaptureDate"
									>
										<i class="fa-solid fa-calendar-day me-2"></i>
										{!currentCapture ? (
											<Skeleton width={80} />
										) : (
											`${formatDate(currentCapture.captured.slice(0, 10))}`
										)}
									</p>
									{/* <p className="ms-2">
										<a href={sentinelUrl} target="_blank">
											Sentinel Hub
										</a>
									</p> */}
									<Tooltip id="QCingCaptureDate" place="top" effect="solid">
										Capture date
									</Tooltip>
								</div>
							</Row>
						</Col>
					</Row>

					<Row style={{ height: '80vh' }}>
						<div className="col-6">
							{!imageData2 ? (
								<div style={{ width: '100%', height: '100%' }}>
									<Skeleton height={'100%'} data-tooltip-id="imageTrueColour" />
								</div>
							) : (
								<div
									style={{ width: '100%', height: '100%' }}
									//data-bs-toggle="modal"
									//data-bs-target="#id1"
									onClick={() => setModalOpen(true)}
									//data-tooltip-id="imageTrueColour"
								>
									<QC_Image
										modalId={'id4'}
										stockpilesGeojsons={stockpilesGeojsons}
										setStockpilesGeojsons={setStockpilesGeojsons}
										imageUrl={imageData2.true_color}
										geojson={imageData2.aoi}
										perm_regions={imageData2.perm_regions}
										hotspots={imageData2.hotspots}
										bbox={imageData2.bbox}
										imageData={imageData2}
										setEditedGeojson={setEditedGeojson}
										openModal={modalOpen}
										featureCollection={featureCollection}
										setFeatureCollection={setFeatureCollection}
										featureLayers={featureLayers}
										setFeatureLayers={setFeatureLayers}
										currentCapture={currentCapture}
										setDrawnItemsValid={setDrawnItemsValid}
										drawnItemsValid={drawnItemsValid}
									/>
								</div>
							)}
						</div>

						<div className="col-6">
							<Row style={{ height: '50%' }}>
								<div className="col">
									<PixelCountPlot pixelPlotData={pixelPlotData2} />
								</div>
							</Row>
							<Row style={{ height: '50%' }} className="d-flex align-items-end">
								<div className="col-5 pt-2" style={{ height: '100%' }}>
									{/* <StockpilesGeojsonsList
										featureLayers={featureLayers}
										setFeatureLayers={setFeatureLayers}
										stockpilesGeojsons={stockpilesGeojsons}
									/> */}
								</div>
								<div
									className="col-7 pt-2 d-flex align-items-end justify-content-end"
									style={{ height: '100%' }}
								>
									{!imageData2 ? (
										<Skeleton width={80} />
									) : (
										<QCing_Form_Stockpiles
											onStockpilesQCSubmit={onStockpilesQCSubmit}
											currentCapture={currentCapture}
											imageData={imageData2}
											skipCapture={skipCapture}
											drawnItemsValid={drawnItemsValid}
											stockpilesFormValue={stockpilesFormValue}
											setStockpilesFormValue={setStockpilesFormValue}
										/>
									)}
								</div>
							</Row>
						</div>
					</Row>
				</div>
			</>
		);
	}
};

export default QC_ing;
