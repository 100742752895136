import React, { useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb';
import { Navigate } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

export default function Captures(props) {
	let sites = {
		message: 'Success',
		data: [
			{
				id: 20567,
				name: 'Nippon Steel Kyushu Yawata Kokura - Blast Furnace 2',
				commodity_id: null,
				site_type_id: 5,
				prod_state: '0'
			},
			{
				id: 1044,
				name: 'Hbis Laoting Steel',
				commodity_id: 3,
				site_type_id: 1,
				prod_state: '0'
			}
		]
	};
	const options = sites.data.map(({ id, name }) => ({
		value: id,
		label: name
	}));

	const [selectedOptions, setSelectedOptions] = useState([]);

	const handleChange = (selectedValues) => {
		setSelectedOptions(selectedValues);
	};

	let captureProps = [
		{
			title: 'Home',
			currentPage: false,
			link: '/',
			afterCurrent: false,
			warning: false
		},
		{
			title: 'Captures',
			currentPage: false,
			link: '/capture',
			afterCurrent: false,
			warning: false
		},
		{
			title: 'Capture 5060',
			currentPage: true,
			link: '/capture/5060',
			afterCurrent: false,
			warning: false
		}
	];

	useEffect(() => {
		document.title = 'GAINS | Capture';
	}, []);

	return (
		<>
			{props.authProps.isAuthenticated ? (
				<Col className="px-0">
					<div className="contentContainer">
						<Breadcrumb props={captureProps} marginTop={25} />
						<hr></hr>
						<h2>Captures</h2>
						<p>Use this page to view individual captures</p>

						<Row>
							<Col xl="3">
								{/* <form>
									<div className="form-group">
										<h6>QC</h6>

										<label for="captures-capture-id">QC Status</label>
										<select className="form-select">
											<option selected>Select QC status</option>
											<option value="0">Not QC'd</option>
											<option value="1">QC'd</option>
											<option value="3">Currently in QC</option>
										</select>

										<label for="captures-user">QC user</label>
										<select className="form-select">
											<option selected>Select a user</option>
											{config.qcUsers.map(({ name, value }, idx) => (
												<option key={idx} value={value}>
													{name}
												</option>
											))}
										</select>

										<h6>Site Info</h6>
										<label for="captures-site-id">Site ID</label>
										<input
											id="captures-site-id"
											className="form-control"
											type="number"
										></input>

										<label for="captures-site-id">Site Name</label>
										<Select
											isMulti
											options={options}
											value={selectedOptions}
											onChange={handleChange}
											isSearchable
											placeholder="Search and select options..."
										/>
									

										<h6>Capture Info</h6>
										<label for="captures-capture-id">Capture ID</label>
										<input
											id="captures-capture-id"
											className="form-control"
											type="number"
										></input>

										<h6>Capture State</h6>

										<label for="captures-user">Initial state</label>
										<select className="form-select">
											<option selected>Select initial capture state</option>
											<option value="1">Active</option>
											<option value="-1">Inactive</option>
											<option value="0">Invalid</option>
										</select>

										<label for="captures-user">QC'd state</label>
										<select className="form-select">
											<option selected>Select capture QC state</option>
											<option value="1">Active</option>
											<option value="-1">Inactive</option>
											<option value="0">Invalid</option>
										</select>

										<h6>Capture Data</h6>
									</div>
								</form> */}
							</Col>
						</Row>
					</div>
				</Col>
			) : (
				<Navigate to="/login" />
			)}
		</>
	);
}
