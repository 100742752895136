import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import React, {useContext} from 'react';
import { pipelineContext } from '../contexts/pipelineContext';

const Navlink = ({
	path,
	icon,
	text,
	onclick,
	userQCing,
	userUploading,
	setUserUploading,
	disabled
}) => {

	const {
		resetState
	} = useContext(pipelineContext);

	if (userUploading) {
		return (
			<NavLink
				to={path}
				className={({ isActive }) =>
					isActive ? 'nav-link navHover' : 'nav-link text-white'
				}
				onClick={(e) => setUserUploading(false)}
			>
				<span className={icon}></span>
				{text}
			</NavLink>
		);
	}

	if (userQCing) {
		return (
			<NavLink
				data-bs-toggle="modal"
				data-bs-target="#exitQCModal"
				to={path}
				className={({ isActive }) =>
					isActive ? 'nav-link navHover' : 'nav-link text-white'
				}
			>
				<span className={icon}></span>
				{text}
			</NavLink>
		);
	} else {
		if (!disabled) {
			return (
				<NavLink
					to={path}
					onClick={(e)=> resetState()}
					className={({ isActive }) =>
						isActive ? 'nav-link navHover' : 'nav-link text-white'
					}
				>
					<span className={icon}></span>
					{text}
				</NavLink>
			);
		} else {
			return (
				<>
					<NavLink
						to={'hello'}
						data-tooltip-id={`disabledNavLink${path}`}
						className={({ isActive }) =>
							isActive ? 'nav-link navHover' : 'nav-link text-white'
						}
						onClick={(e) => e.preventDefault()}
					>
						<span className={icon}></span>
						{text}
					</NavLink>
					<Tooltip id={`disabledNavLink${path}`}>App loading ..</Tooltip>
				</>
			);
		}
	}
};

export default Navlink;
