import { Routes, Route, BrowserRouter, redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import React from 'react';
import Dashboard from './components/Pages/Dashboard';
import Error from './components/Pages/Error';
import Login from './components/Pages/Login';
import Captures from './components/Pages/Captures';
import QC_Page from './components/Pages/QC_Page';
import Testing from './components/Pages/Testing';
import Users_Captures from './components/Pages/Users_Captures';

export default ({ authProps, qcProps, endpointProps }) => (
	<BrowserRouter>
		<Routes>
			<Route
				path="/"
				element={
					<ProtectedRoute
						endpointProps={endpointProps}
						authProps={authProps}
						component={Dashboard}
						qcProps={qcProps}
					/>
				}
			/>

			<Route
				path="/qc"
				element={
					<ProtectedRoute
						endpointProps={endpointProps}
						authProps={authProps}
						component={QC_Page}
						qcProps={qcProps}
					/>
				}
			/>

			<Route
				path="/capture"
				element={
					<ProtectedRoute
						endpointProps={endpointProps}
						authProps={authProps}
						component={Captures}
						qcProps={qcProps}
					/>
				}
			/>

			<Route
				path="/testing"
				element={
					<ProtectedRoute
						endpointProps={endpointProps}
						authProps={authProps}
						component={Testing}
						qcProps={qcProps}
					/>
				}
			/>

		

			{/* <Route
				path="/removecaps"
				element={
					<ProtectedRoute
						endpointProps={endpointProps}
						authProps={authProps}
						component={RemoveCaps}
						qcProps={qcProps}
					/>
				}
			/> */}

			<Route
				path="/users_captures"
				element={
					<ProtectedRoute
						endpointProps={endpointProps}
						authProps={authProps}
						component={Users_Captures}
						qcProps={qcProps}
					/>
				}
			/>

			<Route
				path="/404"
				element={
					<ProtectedRoute
						endpointProps={endpointProps}
						authProps={authProps}
						component={Error}
						qcProps={qcProps}
					/>
				}
			/>

			<Route
				path="*"
				element={
					<ProtectedRoute
						endpointProps={endpointProps}
						authProps={authProps}
						component={Error}
						qcProps={qcProps}
					/>
				}
			/>

			<Route path="/login" element={<Login {...authProps} />} />

			<Route
				path="*"
				element={
					<ProtectedRoute
						endpointProps={endpointProps}
						authProps={authProps}
						component={Error}
						qcProps={qcProps}
					/>
				}
			/>
		</Routes>
	</BrowserRouter>
);
