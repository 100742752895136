import config from '../config';
import toast from 'react-hot-toast';
import { API } from 'aws-amplify';
import divideReturnQCList from '../helpers/divideReturnQCList';

function createToastMsjs(msjString) {
	let toastMsjs = {
		loadingMsj: `Loading ${msjString}..`,
		successMsj: `Successfully fetched ${msjString}!`,
		erroMsj: `Error fetching ${msjString}`
	};

	return toastMsjs;
}

export async function getDashboardData(env) {
	const urls = [
		`${
			config.baseUrl
		}/smelters/captures?prodStates=-1,0,1,2,5&commodities=3&siteTypeIds=1&qc=3`,
		`${
			config.baseUrl
		}/stockpiles/captures?prodStates=-1,0,1,2,5&commodities=7&siteTypeIds=1&qc=3`,
		`${
			config.baseUrl
		}/smelters/captures?prodStates=-1,0,1,2,5&commodities=3&siteTypeIds=1&qc=4`,
		`${
			config.baseUrl
		}/stockpiles/captures?prodStates=-1,0,1,2,5&commodities=7&siteTypeIds=1&qc=4`,
		//`${config.baseUrl}/smelters/sites`,
		`${config.baseUrl}/smelters/sites?hasCaptures=true`,
		//`${config.baseUrl}/stockpiles/sites`,
		`${config.baseUrl}/stockpiles/sites?hasCaptures=true`
		//`${config.baseUrl}/smelters/available-captures?siteTypeIds=1`,
		//`${config.baseUrl}/stockpiles/available-captures`
	];

	try {
		// Create an array of fetch promises
		const fetchPromises = urls.map((url) =>
			fetch(url).then((response) => {
				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}
				return response.json();
			})
		);

		// Use Promise.all to wait for all fetches to complete
		const results = await Promise.all(fetchPromises);
		return results;
	} catch (error) {
		console.error('Error fetching data:', error);
	}
}

// export async function getInfo2(
// 	setState,
// 	baseUrl,
// 	qcCat,
// 	endpoint,
// 	params,
// 	msjString,
// 	sortData,
// 	sortKey
// ) {
// 	let toastMsjs = createToastMsjs(msjString);
// 	const queryString = Object.keys(params)
// 		.map((key) => `${key}=${params[key]}`)
// 		.join('&');

// 	let fullUrl = `${baseUrl}/${qcCat}/${endpoint}?${queryString}`;

// 	if (msjString) {
// 		return new Promise(async (resolve, reject) => {
// 			const loadingToast = toast.loading(toastMsjs.loadingMsj, {
// 				duration: 4000,
// 				position: 'top-right'
// 			});

// 			try {
// 				const response = await fetch(fullUrl, {
// 					method: 'GET',
// 					headers: {
// 						accept: 'application/json'
// 					}
// 				});

// 				toast.dismiss(toastMsjs.loadingToast);

// 				if (!response.ok) {
// 					toast.dismiss(toastMsjs.loadingToast);
// 					const result = await response.json();
// 					toast.error(`Error ${response.status} - ${toastMsjs.erroMsj}`, {
// 						duration: 4000,
// 						position: 'top-right'
// 					});

// 					// This function is called when the asynchronous operation fails.
// 					// The reason (usually an error) passed to reject will be available
// 					// to the catch method of the Promise.
// 					reject(response);
// 				} else {
// 					if (toastMsjs.successMsj) {
// 						toast.success(toastMsjs.successMsj, { position: 'top-right' });
// 					}

// 					const result = await response.json();
// 					if (!sortData) {
// 						setState(result.data);
// 					} else {
// 						result.data.sort((a, b) => a[sortKey] - b[sortKey]);
// 						setState(result.data);
// 					}

// 					// This function is called when the asynchronous operation completes successfully.
// 					// The value passed to resolve will be available to the then method of the Promise.
// 					resolve(result);
// 				}
// 			} catch (err) {
// 				//console.log(err);

// 				toast.dismiss(toastMsjs.loadingToast);

// 				toast.error(`Error: ${err} - ${toastMsjs.erroMsj}`, {
// 					duration: 10000,
// 					position: 'top-right'
// 				});

// 				return null;
// 			}
// 		});
// 	} else {
// 		return new Promise(async (resolve, reject) => {
// 			try {
// 				const response = await fetch(fullUrl, {
// 					method: 'GET',
// 					headers: {
// 						accept: 'application/json'
// 					}
// 				});

// 				if (!response.ok) {
// 					const result = await response.json();

// 					// This function is called when the asynchronous operation fails.
// 					// The reason (usually an error) passed to reject will be available
// 					// to the catch method of the Promise.
// 					reject(response);
// 				} else {
// 					const result = await response.json();
// 					if (!sortData) {
// 						setState(result.data);
// 					} else {
// 						result.data.sort((a, b) => a[sortKey] - b[sortKey]);
// 						setState(result.data);
// 					}

// 					// This function is called when the asynchronous operation completes successfully.
// 					// The value passed to resolve will be available to the then method of the Promise.
// 					resolve(result);
// 				}
// 			} catch (err) {
// 				return null;
// 			}
// 		});
// 	}
// }

// general GET function
export async function getInfo(apiString, qcCat, msjString) {
	let apiString2;
	if (qcCat === 'stockpiles') {
		apiString2 = `${config.baseUrl}/stockpiles/${apiString}`;
	} else if (qcCat === 'smelters') {
		apiString2 = `${config.baseUrl}/smelters/${apiString}`;
	}

	let loadingMsj = `Loading ${msjString}..`;
	let successMsj = `Successfully fetched ${msjString}!`;
	let erroMsj = `Error fetching ${msjString}`;

	if (msjString) {
		return new Promise(async (resolve, reject) => {
			const loadingToast = toast.loading(loadingMsj, {
				duration: 4000,
				position: 'top-right'
			});

			try {
				const response = await fetch(apiString2, {
					method: 'GET',
					headers: {
						accept: 'application/json'
					}
				});

				toast.dismiss(loadingToast);

				if (!response.ok) {
					toast.dismiss(loadingToast);
					const result = await response.json();
					toast.error(`Error ${response.status} - ${erroMsj}`, {
						duration: 4000,
						position: 'top-right'
					});

					// This function is called when the asynchronous operation fails.
					// The reason (usually an error) passed to reject will be available
					// to the catch method of the Promise.
					reject(response);
				} else {
					if (successMsj) {
						toast.success(successMsj, { position: 'top-right' });
					}

					const result = await response.json();

					// This function is called when the asynchronous operation completes successfully.
					// The value passed to resolve will be available to the then method of the Promise.
					resolve(result);
				}
			} catch (err) {
				//console.log(err);

				toast.dismiss(loadingToast);

				toast.error(`Error: ${err} - ${erroMsj}`, {
					duration: 10000,
					position: 'top-right'
				});

				return null;
			}
		});
	} else {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await fetch(apiString2, {
					method: 'GET',
					//mode: 'no-cors',
					headers: {
						accept: 'application/json'
					}
				});

				const result = await response.json();
				resolve(result);
			} catch (err) {
				console.log(err);

				return null;
			}
		});
	}
}

// general PUT function
export async function putInfo(
	apiString,
	qcCat,
	loadingMsj,
	successMsj,
	erroMsj
) {
	let apiString2;
	if (qcCat === 'stockpiles') {
		apiString2 = `${config.baseUrl}/stockpiles/${apiString}`;
	} else if (qcCat === 'smelters') {
		apiString2 = `${config.baseUrl}/smelters/${apiString}`;
	}

	if (loadingMsj) {
		return new Promise(async (resolve, reject) => {
			const loadingToast = toast.loading(loadingMsj, {
				duration: 4000,
				position: 'top-right'
			});

			try {
				const response = await fetch(apiString2, {
					method: 'POST',
					//mode: 'no-cors',
					headers: {
						accept: 'application/json'
					}
				});

				console.log(response)

				if (!response.ok) {
					toast.dismiss(loadingToast);
					toast.error(`Error status ${response.status} - erroMsj`, { position: 'top-right' });
					reject(new Error(`Error! status: ${response.status}`));
					
				}

				if (successMsj) {
					toast.success(successMsj, { position: 'top-right' });
				}

				const result = await response.json();
				toast.dismiss(loadingToast);

				//console.log(result);

				resolve(result);
			} catch (err) {
				console.log(err);

				toast.dismiss(loadingToast);

				toast.error(erroMsj, { position: 'top-right' });

				reject(err);
			}
		});
	} else {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await fetch(apiString2, {
					method: 'POST',
					//mode: 'no-cors',
					headers: {
						accept: 'application/json'
					}
				});

				if (!response.ok) {
					reject(new Error(`Error! status: ${response.status}`));
				}

				const result = await response.json();

				//console.log(result);

				resolve(result);
			} catch (err) {
				console.log(err);

				reject(err);
			}
		});
	}
}

export async function putStockpilesInfo(
	apiString,
	qcCat,
	loadingMsj,
	successMsj,
	erroMsj
) {
	let apiString2;
	if (qcCat === 'stockpiles') {
		apiString2 = `${config.baseUrl}/stockpiles/${apiString}`;
	} else if (qcCat === 'smelters') {
		apiString2 = `${config.baseUrl}/smelters/${apiString}`;
	}

	if (loadingMsj) {
		return new Promise(async (resolve, reject) => {
			const loadingToast = toast.loading(loadingMsj, {
				duration: 4000,
				position: 'top-right'
			});

			try {
				const response = await fetch(apiString2, {
					method: 'PUT',
					//mode: 'no-cors',
					headers: {
						accept: 'application/json'
					}
				});

				toast.dismiss(loadingToast);

				if (!response.ok) {
					toast.dismiss(loadingToast);
					reject(new Error(`Error! status: ${response.status}`));
				}

				if (successMsj) {
					toast.success(successMsj, { position: 'top-right' });
				}

				const result = await response.json();

				resolve(result);
			} catch (err) {
				console.log(err);

				toast.dismiss(loadingToast);

				toast.error(erroMsj, { position: 'top-right' });

				reject(err);
			}
		});
	} else {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await fetch(apiString2, {
					method: 'PUT',
					//mode: 'no-cors',
					headers: {
						accept: 'application/json'
					}
				});

				if (!response.ok) {
					reject(new Error(`Error! status: ${response.status}`));
				}

				const result = await response.json();

				//console.log(result);

				resolve(result);
			} catch (err) {
				console.log(err);

				reject(err);
			}
		});
	}
}
