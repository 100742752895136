const userCaptures = ({ user, allCaps, setState }) => {
	if (allCaps) {
		let x = allCaps.filter(
			(cap) =>
				cap.qc_user ===
				`${user.givenName.toLowerCase()}.${user.familyName.toLowerCase()}`
		);

		setState(x);
	}
};

export default userCaptures;
