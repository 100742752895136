import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Col } from 'reactstrap';
import Lottie from 'lottie-react'
import angryface from '../Dashboard/angryface.json'


export default function Error() {
	
	useEffect(() => {
		document.title = 'GAINS | 404';
	}, []);

	return (
		<>
			
				<Col className="px-0">
					<div className="contentContainer" style={{ overflow: 'auto' }}>
						
						<div style={{border: 'solid orange 1px'}}>
						<Lottie animationData={angryface} style={{height: 400}}/>
						</div>

						<div className="d-flex justify-content-centre">
							<h1>You broke QC!</h1>
						</div>
					</div>
				</Col>
			
		</>
	);
}
