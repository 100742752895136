import React from 'react';
import config from '../config';
import { Tooltip } from 'react-tooltip';

const UserLogo = ({userName, user, widthHeight, fontSize}) => {
	

	return (
		<>
			<div
				style={{
					borderRadius: '50%',
					width: `${widthHeight}px`,
					height: `${widthHeight}px`,
					backgroundColor: "grey",
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					fontWeight: 'bolder',
					fontSize: `${fontSize}px`,
					color: `white`,
					margin: 'auto'
				}}
				data-tooltip-id={`userLogo${user.initials}`}
			>
				{user.initials}
			</div>
			<Tooltip id={`userLogo${user.initials}`} place="bottom" effect="solid">
				{user.qcAlias}
			</Tooltip>
		</>
	);
};

export default UserLogo;
