// external exports
import React, { useContext, useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import { Navigate } from 'react-router-dom';

// local imports
import config from '../../config';
import {
	QC_Report,
	QC_Progress_Bar,
	QC_Captures,
	QC_Progress_Modal,
	QC_ing,
	ExitQCModal,
	QC_Upload,
	QC_Type,
	QC_Form_Historic,
	QC_Form_Daily,
	QC_Finished_Modal,
	QC_Category
} from '../QC';

// custom hooks & context
import useGainsQCApi from '../../services/useGainsQCApi';
import { pipelineContext } from '../../contexts/pipelineContext';

// hardcoded data
import stockpiles from '../../helpers/stockpiles';

export default function QC_Page(props) {
	const pipelineContextQCPage = useContext(pipelineContext);

	const fetchCapturesObj = useGainsQCApi();
	const postCaptureStateSmelters = useGainsQCApi();
	const postCaptureStateStockpiles = useGainsQCApi();
	const [progressBar, setProgressBar] = useState({
		width: 100 / 7,
		title: ''
	});
	const [currentCaptureIndex, setCurrentCaptureIndex] = useState(0);
	let [currentCapture, setCurrentCapture] = useState();

	const [loading, setLoading] = useState(true);
	const [qcState, setQCState] = useState(1);
	const [disagreeReason, setDisagreeReason] = useState(0);
	let [captures, setCaptures] = useState([]);
	const [formValue, setFormValue] = useState({
		formSiteId: '',
		formStartDate: '',
		formEndDate: new Date().toISOString().substring(0, 10),
		formAutoQC: true,
		formEndDateNow: '',
		fullHistory: true,
		formSensors: ['sentinel-2-l1c', 'landsat-8-l1tp'],
		formCommodities: [3],
		formProdStates: [0, 1]
	});
	const [manualQCCaptures, setManualQCCaptures] = useState([]);
	let [autoQCCaptures, setAutoQCCaptures] = useState([]);
	const [formValid, setFormValid] = useState(false);
	const [filters, setFilters] = useState({
		// AUTO QC disabled for now
		autoQCFilterStatusInvalid: false,
		autoQCFilterStatusInactive: false,
		autoQCFilterCR: '',
		autoQCFilterMinSwath: '',
		autoQCFilter1211Invalid: '',
		autoQCFilter1211Active: ''
	});
	const [capturesQcd, setCapturesQCd] = useState([]);

	const [capturesUrl, setCapturesUrl] = useState('');
	const [editedGeojson, setEditedGeojson] = useState('');
	let [numCapsQCd, setNumCapsQCd] = useState(0);

	// 1 = accept, 0 = reject
	const [stockpilesFormValue, setStockpilesFormValue] = useState(1);

	let [featureCollection, setFeatureCollection] = useState();
	let [featureLayers, setFeatureLayers] = useState({ layers: [] });

	console.log(pipelineContextQCPage);

	useEffect(() => {
		/**
		 * if user goes straight to QC from Dashboard
		 */

		document.title = 'GAINS | QC';
		if (pipelineContextQCPage.straightToQC) {
			pipelineContextQCPage.setProgressBar({
				width: 4 * (100 / 7),
				title: 'View Captures'
			});
		} else if (pipelineContextQCPage.straightToUpload) {
			pipelineContextQCPage.setQCPerc(100);
			pipelineContextQCPage.setProgressBar({
				width: 6 * (100 / 7),
				title: 'Upload Captures'
			});

			if (
				pipelineContextQCPage.capsInPipeline[0].hasOwnProperty('model_version')
			) {
				pipelineContextQCPage.setComm('stockpiles');
			} else {
				pipelineContextQCPage.setComm('smelters');
			}
		} else {
			setTimeout(() => {
				pipelineContextQCPage.setProgressBar({
					width: 1 * (100 / 7),
					title: ''
				});
			}, 500);

			let today = new Date();

			// set default form values
			setFormValue({
				...formValue,
				formEndDateNow: today.toLocaleTimeString(),
				formAutoQC: false
			});

			setLoading(false);
		}

		/**
		 * adjust stockpiles object to add additional props
		 * for leaflet feature
		 */

		if (pipelineContextQCPage.comm === 'stockpiles') {
			let idCounter = 1;
			stockpiles.features.forEach((feature) => {
				feature.properties.id = idCounter;
				feature.status = '';
				feature.newGeom = '';
				idCounter++;
			});

			// change to API call instead of hardcoded data
			setFeatureCollection(stockpiles);
			setFeatureLayers({ layers: [] });
		}

		pipelineContextQCPage.setOnQCPage(true);
	}, []);

	useEffect(() => {
		/**
		 * check if this actually does anything !!
		 */

		const handleBeforeUnload = (event) => {
			event.preventDefault();
			event.returnValue = '';
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [captures]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		pipelineContextQCPage.setProgressBar({
			width: 4 * (100 / 7),
			title: 'View Captures'
		});

		await fetchCapturesObj.makeRequest(
			`${config.baseUrl}/${pipelineContextQCPage.comm}/${capturesUrl}`,
			'GET',
			null,
			'captures'
		);
	};

	useEffect(() => {
		if (fetchCapturesObj?.data?.data) {
			props.qcProps.setCapturesInPipeline(fetchCapturesObj.data.data);
			pipelineContextQCPage.setCapsInPipeline(fetchCapturesObj.data.data);
			pipelineContextQCPage.setQCInProgress(true);
		}
	}, [fetchCapturesObj.data]);

	// start QCing
	const moveToQC = (caps, auto_caps) => {
		// this all should already be declared in pipelineContext, can use button to set
		// pipelineContext.setProgressBar directly
		// except will need to set the currentCapture but this can stay on this page

		setCurrentCapture(
			pipelineContextQCPage.capsInPipeline[
				pipelineContextQCPage.currentCaptureIndex
			]
		);

		pipelineContextQCPage.setQCInProgress(true);
		pipelineContextQCPage.setProgressBar({
			width: 5 * (100 / 7),
			title: 'Perform QC'
		});
	};

	const onQCSubmit = async (e) => {
		e.preventDefault();

		let caps = pipelineContextQCPage.capsInPipeline;
		let cci = pipelineContextQCPage.currentCaptureIndex;
		let cc = caps[cci];

		// if the capture has NOT been QCd already OR user is changing the state
		// change use of newQC to currentCapture.state

		// if capture hasn't been QCd OR
		// if changing QC value OR
		// if changing disagree reason
		if (
			!cc.hasOwnProperty('beenQCd') ||
			cc.newQC != qcState ||
			(cc.hasOwnProperty('disagreeReason') &&
				cc.disagreeReason != disagreeReason)
		) {
			let apiString;

			if (disagreeReason) {
				apiString = `captures?table=SteelCaptureProcessingData&captureId=${
					currentCapture.id
				}&state=${qcState}&qcUser=${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}&reason=${disagreeReason}&useSmoke=0`;
			} else {
				apiString = `captures?table=SteelCaptureProcessingData&captureId=${
					currentCapture.id
				}&state=${qcState}&qcUser=${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}&useSmoke=0`;
			}

			console.log(apiString);

			await postCaptureStateSmelters.makeRequest(
				`${config.baseUrl}/${pipelineContextQCPage.comm}/${apiString}`,
				'POST',
				null,
				`posting capture ${currentCapture.id}`
			);
		} else {
			// do nothing
		}
	};

	useEffect(() => {
		console.log(postCaptureStateSmelters);
		if (!postCaptureStateSmelters.error && postCaptureStateSmelters.data) {
			let params = JSON.parse(postCaptureStateSmelters.data.body.params);
			currentCapture.newQC = qcState;
			currentCapture.beenQCd = true;
			currentCapture.state = qcState;
			currentCapture.qc_state = 4;
			currentCapture.disagreeReason = disagreeReason;
			currentCapture.qc_date = params['qc_date'];

			setLoading(false);

			const firstCapNotQCd = pipelineContextQCPage.capsInPipeline.filter(
				(obj) => !obj.hasOwnProperty('newQC')
			)[0];
			const firstCapIndex =
				pipelineContextQCPage.capsInPipeline.indexOf(firstCapNotQCd);

			let cci = pipelineContextQCPage.currentCaptureIndex;
			if (cci <= pipelineContextQCPage.capsInPipeline.length - 1) {
				if (numCapsQCd !== pipelineContextQCPage.capsInPipeline.length) {
					pipelineContextQCPage.setCurrentCaptureIndex(cci + 1);
				} else {
					console.log('stop QC here');
				}
			} else {
				pipelineContextQCPage.setCurrentCaptureIndex(firstCapIndex);
			}

			let capsQCd = pipelineContextQCPage.capsInPipeline.filter((cap) =>
				cap.hasOwnProperty('newQC')
			).length;
			pipelineContextQCPage.setQCPerc(
				((capsQCd / pipelineContextQCPage.capsInPipeline.length) * 100).toFixed(
					0
				)
			);
		} else if (postCaptureStateSmelters.error) {
			console.log(postCaptureStateSmelters);
			currentCapture.newQC = 'failed';
		}
	}, [postCaptureStateSmelters.data, postCaptureStateSmelters.error]);

	const onStockpilesQCSubmit = async (e) => {
		e.preventDefault();

		let apiString = '';
		apiString = `captures?table=ScrapSteelStockpilesCaptureProcessingData&captureId=${
			currentCapture.id
		}&state=${stockpilesFormValue}&qcUser=${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`;

		setLoading(true);

		await postCaptureStateStockpiles.makeRequest(
			`${config.baseUrl}/${pipelineContextQCPage.comm}/${apiString}`,
			'PUT',
			null,
			`posting capture ${currentCapture.id}`
		);
	};

	useEffect(() => {
		console.log(postCaptureStateStockpiles);
		if (!postCaptureStateStockpiles.error && postCaptureStateStockpiles.data) {
			let data = postCaptureStateStockpiles.data.body;
			currentCapture.newQC = data.state;
			currentCapture.beenQCd = true;
			currentCapture.state = data.state;
			currentCapture.qc_state = data.qc_state;
			currentCapture.qc_date = data.qc_date;

			setLoading(false);

			const firstCapNotQCd = pipelineContextQCPage.capsInPipeline.filter(
				(obj) => !obj.hasOwnProperty('newQC')
			)[0];
			const firstCapIndex =
				pipelineContextQCPage.capsInPipeline.indexOf(firstCapNotQCd);
			let numCapsQCd = pipelineContextQCPage.capsInPipeline.filter((cap) =>
				cap.hasOwnProperty('beenQCd')
			).length;
			let cci = pipelineContextQCPage.currentCaptureIndex;

			console.log(cci);
			console.log(numCapsQCd);
			console.log(pipelineContextQCPage.capsInPipeline.length);
			console.log(firstCapIndex);

			if (cci <= pipelineContextQCPage.capsInPipeline.length - 1) {
				if (numCapsQCd !== pipelineContextQCPage.capsInPipeline.length) {
					pipelineContextQCPage.setCurrentCaptureIndex(cci + 1);
				} else {
					console.log('stop QC here');
				}
			} else {
				pipelineContextQCPage.setCurrentCaptureIndex(firstCapIndex);
			}

			let capsQCd = pipelineContextQCPage.capsInPipeline.filter((cap) =>
				cap.hasOwnProperty('newQC')
			).length;
			pipelineContextQCPage.setQCPerc(
				((capsQCd / pipelineContextQCPage.capsInPipeline.length) * 100).toFixed(
					0
				)
			);
		} else if (postCaptureStateStockpiles.error) {
			console.log(postCaptureStateStockpiles);
			currentCapture.newQC = 'failed';
		}
	}, [postCaptureStateStockpiles.data, postCaptureStateStockpiles.error]);

	useEffect(() => {
		setCurrentCapture(
			pipelineContextQCPage.capsInPipeline[
				pipelineContextQCPage.currentCaptureIndex
			]
		);
	}, [pipelineContextQCPage.currentCaptureIndex]);

	return (
		<>
			{props.authProps.isAuthenticated ? (
				<Col className="px-0">
					<div className="contentContainer" style={{ overflow: 'auto' }}>
						<QC_Progress_Bar />

						<ExitQCModal props={props} />

						<QC_Progress_Modal props={props} />

						<QC_Finished_Modal
							props={props}
							captures={captures}
							currentCaptureIndex={currentCaptureIndex}
							setProgressBar={setProgressBar}
						/>

						{!pipelineContextQCPage.straightToQC && (
							<>
								{/* {progressBar.title === 'QC Type' && (
									<QCSummaryInfo
										loading={loading}
										capturesQCStatus={capturesQCStatus}
									/>
								)} */}

								{pipelineContextQCPage.progressBar.title === '' && (
									<QC_Category />
								)}

								{pipelineContextQCPage.progressBar.title === 'QC Type' && (
									<QC_Type loading={loading} />
								)}

								{pipelineContextQCPage.progressBar.title === 'Daily' && (
									<QC_Form_Daily
										handleSubmit={handleSubmit}
										formValue={formValue}
										setFormValue={setFormValue}
										formValid={formValid}
										setFormValid={setFormValid}
										loading={loading}
										setCapturesUrl={setCapturesUrl}
										user={props.authProps.userAttributes}
									/>
								)}

								{pipelineContextQCPage.progressBar.title === 'Historic' && (
									<QC_Form_Historic
										fetchCapturesObj={fetchCapturesObj}
										handleSubmit={handleSubmit}
										formValue={formValue}
										setFormValue={setFormValue}
										formValid={formValid}
										setFormValid={setFormValid}
										loading={loading}
										setCapturesUrl={setCapturesUrl}
										props={props}
									/>
								)}
							</>
						)}

						{pipelineContextQCPage.progressBar.title === 'View Captures' && (
							<QC_Captures
								filters={filters}
								formValue={formValue}
								moveToQC={moveToQC}
								setFilters={setFilters}
							/>
						)}

						{pipelineContextQCPage.progressBar.title === 'Perform QC' && (
							<QC_ing
								loading={loading}
								currentCapture={currentCapture}
								manualQCCaptures={pipelineContextQCPage.capsInPipeline}
								autoQCCaptures={autoQCCaptures}
								currentCaptureIndex={pipelineContextQCPage.currentCaptureIndex}
								qcState={qcState}
								setQCState={setQCState}
								onQCSubmit={onQCSubmit}
								onStockpilesQCSubmit={onStockpilesQCSubmit}
								setDisagreeReason={setDisagreeReason}
								setCurrentCaptureIndex={setCurrentCaptureIndex}
								numCapsQCd={numCapsQCd}
								editedGeojson={editedGeojson}
								setEditedGeojson={setEditedGeojson}
								featureCollection={featureCollection}
								setFeatureCollection={setFeatureCollection}
								featureLayers={featureLayers}
								setFeatureLayers={setFeatureLayers}
								stockpilesFormValue={stockpilesFormValue}
								setStockpilesFormValue={setStockpilesFormValue}
							/>
						)}

						{pipelineContextQCPage.progressBar.title === 'Upload Captures' && (
							<QC_Upload
								manualQCCaptures={pipelineContextQCPage.capsInPipeline}
								setProgressBar={setProgressBar}
								setQcInProgress={props.qcProps.setQcInProgress}
								qcInProgress={props.qcProps.qcInProgress}
								straightToUpload={props.qcProps.straightToUpload}
								props={props}
								progressBar={progressBar}
							/>
						)}

						{pipelineContextQCPage.progressBar.title === 'QC Report' && (
							<QC_Report
								manualQCCaptures={pipelineContextQCPage.capsInPipeline}
								qcInProgress={props.qcProps.qcInProgress}
							/>
						)}
					</div>
				</Col>
			) : (
				<Navigate to="/login" />
			)}
		</>
	);
}
