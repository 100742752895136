let hotspotsSig = {
	data: [
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.18043741583824158, 0.11987005174160004, 0.13120631873607635,
				0.11987005174160004, 0.16351351141929626, 0.13976024091243744,
				0.2278519719839096, 0.12182457745075226, 0.0010117556666955352,
				0.5226214528083801, 0.5294061303138733
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.18043741583824158, 0.11987005174160004, 0.13120631873607635,
				0.11987005174160004, 0.16351351141929626, 0.13976024091243744,
				0.2278519719839096, 0.12826302647590637, 0.0010117556666955352,
				0.5226214528083801, 0.5294061303138733
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.18043741583824158, 0.11987005174160004, 0.13120631873607635,
				0.11987005174160004, 0.16351351141929626, 0.13976024091243744,
				0.2278519719839096, 0.12736624479293823, 0.0010117556666955352,
				0.5226214528083801, 0.5294061303138733
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.18043741583824158, 0.11987005174160004, 0.13120631873607635,
				0.11987005174160004, 0.16351351141929626, 0.13976024091243744,
				0.2278519719839096, 0.13014857470989227, 0.0010117556666955352,
				0.5226214528083801, 0.5294061303138733
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.17963261902332306, 0.11246583610773087, 0.12738923728466034,
				0.11246583610773087, 0.14654360711574554, 0.14668157696723938,
				0.2731740176677704, 0.12269836664199829, 0.0010807389626279473,
				0.5115002393722534, 0.5251507759094238
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.17963261902332306, 0.11246583610773087, 0.12738923728466034,
				0.11246583610773087, 0.14654360711574554, 0.14668157696723938,
				0.2731740176677704, 0.12256039679050446, 0.0010807389626279473,
				0.5115002393722534, 0.5251507759094238
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.18149515986442566, 0.11469630151987076, 0.12892986834049225,
				0.11469630151987076, 0.15679913759231567, 0.16073118150234222,
				0.2593773603439331, 0.12228446453809738, 0.0009197778417728841,
				0.5160498023033142, 0.5292956829071045
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.17963261902332306, 0.11246583610773087, 0.12738923728466034,
				0.11246583610773087, 0.14654360711574554, 0.14668157696723938,
				0.2731740176677704, 0.11991603672504425, 0.0010807389626279473,
				0.5115002393722534, 0.5251507759094238
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.17963261902332306, 0.11246583610773087, 0.12738923728466034,
				0.11246583610773087, 0.14654360711574554, 0.14668157696723938,
				0.2731740176677704, 0.121249720454216, 0.0010807389626279473,
				0.5115002393722534, 0.5251507759094238
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.18149515986442566, 0.11469630151987076, 0.12892986834049225,
				0.11469630151987076, 0.15679913759231567, 0.16073118150234222,
				0.2593773603439331, 0.12322723865509033, 0.0009197778417728841,
				0.5160498023033142, 0.5292956829071045
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.1808973103761673, 0.11154606193304062, 0.12748120725154877,
				0.11154606193304062, 0.15137244760990143, 0.1970853954553604,
				0.5030725002288818, 0.12283633649349213, 0.000827800075057894,
				0.5176587104797363, 0.5310626029968262
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.1808973103761673, 0.11154606193304062, 0.12748120725154877,
				0.11154606193304062, 0.15137244760990143, 0.1970853954553604,
				0.5030725002288818, 0.11890428513288498, 0.000827800075057894,
				0.5176587104797363, 0.5310626029968262
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.18025346100330353, 0.11391448974609375, 0.12789511680603027,
				0.11391448974609375, 0.14803825318813324, 0.15068261325359344,
				0.3066079616546631, 0.1219625473022461, 0.0009427723125554621,
				0.5154815912246704, 0.5284668207168579
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.1786208599805832, 0.1095685362815857, 0.12587159872055054,
				0.1095685362815857, 0.13987521827220917, 0.3576556146144867,
				1.0341522693634033, 0.1185823604464531, 0.0013106834376230836,
				0.48075196146965027, 0.4993109107017517
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.17894278466701508, 0.11182199418544769, 0.12750419974327087,
				0.11182199418544769, 0.1415078192949295, 0.16261672973632812,
				0.28391242027282715, 0.12053688615560532, 0.0011497223749756813,
				0.48560118675231934, 0.5023797750473022
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.17894278466701508, 0.11182199418544769, 0.12750419974327087,
				0.11182199418544769, 0.1415078192949295, 0.16261672973632812,
				0.28391242027282715, 0.12088180333375931, 0.0011497223749756813,
				0.48560118675231934, 0.5023797750473022
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.1778620481491089, 0.11016639322042465, 0.12559567391872406,
				0.11016639322042465, 0.14056505262851715, 0.14893503487110138,
				0.4189818203449249, 0.11793851852416992, 0.0007128278375603259,
				0.4719403088092804, 0.4934423863887787
			],
			type: 'scatter'
		},
		{
			x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
			y: [
				0.1778620481491089, 0.11016639322042465, 0.12559567391872406,
				0.11016639322042465, 0.14056505262851715, 0.14893503487110138,
				0.4189818203449249, 0.11931818723678589, 0.0007128278375603259,
				0.4719403088092804, 0.4934423863887787
			],
			type: 'scatter'
		}
	],
	layout: {
		template: {
			data: {
				histogram2dcontour: [
					{
						type: 'histogram2dcontour',
						colorbar: {
							outlinewidth: 0,
							ticks: ''
						},
						colorscale: [
							[0, '#0d0887'],
							[0.1111111111111111, '#46039f'],
							[0.2222222222222222, '#7201a8'],
							[0.3333333333333333, '#9c179e'],
							[0.4444444444444444, '#bd3786'],
							[0.5555555555555556, '#d8576b'],
							[0.6666666666666666, '#ed7953'],
							[0.7777777777777778, '#fb9f3a'],
							[0.8888888888888888, '#fdca26'],
							[1, '#f0f921']
						]
					}
				],
				choropleth: [
					{
						type: 'choropleth',
						colorbar: {
							outlinewidth: 0,
							ticks: ''
						}
					}
				],
				histogram2d: [
					{
						type: 'histogram2d',
						colorbar: {
							outlinewidth: 0,
							ticks: ''
						},
						colorscale: [
							[0, '#0d0887'],
							[0.1111111111111111, '#46039f'],
							[0.2222222222222222, '#7201a8'],
							[0.3333333333333333, '#9c179e'],
							[0.4444444444444444, '#bd3786'],
							[0.5555555555555556, '#d8576b'],
							[0.6666666666666666, '#ed7953'],
							[0.7777777777777778, '#fb9f3a'],
							[0.8888888888888888, '#fdca26'],
							[1, '#f0f921']
						]
					}
				],
				heatmap: [
					{
						type: 'heatmap',
						colorbar: {
							outlinewidth: 0,
							ticks: ''
						},
						colorscale: [
							[0, '#0d0887'],
							[0.1111111111111111, '#46039f'],
							[0.2222222222222222, '#7201a8'],
							[0.3333333333333333, '#9c179e'],
							[0.4444444444444444, '#bd3786'],
							[0.5555555555555556, '#d8576b'],
							[0.6666666666666666, '#ed7953'],
							[0.7777777777777778, '#fb9f3a'],
							[0.8888888888888888, '#fdca26'],
							[1, '#f0f921']
						]
					}
				],
				heatmapgl: [
					{
						type: 'heatmapgl',
						colorbar: {
							outlinewidth: 0,
							ticks: ''
						},
						colorscale: [
							[0, '#0d0887'],
							[0.1111111111111111, '#46039f'],
							[0.2222222222222222, '#7201a8'],
							[0.3333333333333333, '#9c179e'],
							[0.4444444444444444, '#bd3786'],
							[0.5555555555555556, '#d8576b'],
							[0.6666666666666666, '#ed7953'],
							[0.7777777777777778, '#fb9f3a'],
							[0.8888888888888888, '#fdca26'],
							[1, '#f0f921']
						]
					}
				],
				contourcarpet: [
					{
						type: 'contourcarpet',
						colorbar: {
							outlinewidth: 0,
							ticks: ''
						}
					}
				],
				contour: [
					{
						type: 'contour',
						colorbar: {
							outlinewidth: 0,
							ticks: ''
						},
						colorscale: [
							[0, '#0d0887'],
							[0.1111111111111111, '#46039f'],
							[0.2222222222222222, '#7201a8'],
							[0.3333333333333333, '#9c179e'],
							[0.4444444444444444, '#bd3786'],
							[0.5555555555555556, '#d8576b'],
							[0.6666666666666666, '#ed7953'],
							[0.7777777777777778, '#fb9f3a'],
							[0.8888888888888888, '#fdca26'],
							[1, '#f0f921']
						]
					}
				],
				surface: [
					{
						type: 'surface',
						colorbar: {
							outlinewidth: 0,
							ticks: ''
						},
						colorscale: [
							[0, '#0d0887'],
							[0.1111111111111111, '#46039f'],
							[0.2222222222222222, '#7201a8'],
							[0.3333333333333333, '#9c179e'],
							[0.4444444444444444, '#bd3786'],
							[0.5555555555555556, '#d8576b'],
							[0.6666666666666666, '#ed7953'],
							[0.7777777777777778, '#fb9f3a'],
							[0.8888888888888888, '#fdca26'],
							[1, '#f0f921']
						]
					}
				],
				mesh3d: [
					{
						type: 'mesh3d',
						colorbar: {
							outlinewidth: 0,
							ticks: ''
						}
					}
				],
				scatter: [
					{
						fillpattern: {
							fillmode: 'overlay',
							size: 10,
							solidity: 0.2
						},
						type: 'scatter'
					}
				],
				parcoords: [
					{
						type: 'parcoords',
						line: {
							colorbar: {
								outlinewidth: 0,
								ticks: ''
							}
						}
					}
				],
				scatterpolargl: [
					{
						type: 'scatterpolargl',
						marker: {
							colorbar: {
								outlinewidth: 0,
								ticks: ''
							}
						}
					}
				],
				bar: [
					{
						error_x: {
							color: '#2a3f5f'
						},
						error_y: {
							color: '#2a3f5f'
						},
						marker: {
							line: {
								color: '#E5ECF6',
								width: 0.5
							},
							pattern: {
								fillmode: 'overlay',
								size: 10,
								solidity: 0.2
							}
						},
						type: 'bar'
					}
				],
				scattergeo: [
					{
						type: 'scattergeo',
						marker: {
							colorbar: {
								outlinewidth: 0,
								ticks: ''
							}
						}
					}
				],
				scatterpolar: [
					{
						type: 'scatterpolar',
						marker: {
							colorbar: {
								outlinewidth: 0,
								ticks: ''
							}
						}
					}
				],
				histogram: [
					{
						marker: {
							pattern: {
								fillmode: 'overlay',
								size: 10,
								solidity: 0.2
							}
						},
						type: 'histogram'
					}
				],
				scattergl: [
					{
						type: 'scattergl',
						marker: {
							colorbar: {
								outlinewidth: 0,
								ticks: ''
							}
						}
					}
				],
				scatter3d: [
					{
						type: 'scatter3d',
						line: {
							colorbar: {
								outlinewidth: 0,
								ticks: ''
							}
						},
						marker: {
							colorbar: {
								outlinewidth: 0,
								ticks: ''
							}
						}
					}
				],
				scattermapbox: [
					{
						type: 'scattermapbox',
						marker: {
							colorbar: {
								outlinewidth: 0,
								ticks: ''
							}
						}
					}
				],
				scatterternary: [
					{
						type: 'scatterternary',
						marker: {
							colorbar: {
								outlinewidth: 0,
								ticks: ''
							}
						}
					}
				],
				scattercarpet: [
					{
						type: 'scattercarpet',
						marker: {
							colorbar: {
								outlinewidth: 0,
								ticks: ''
							}
						}
					}
				],
				carpet: [
					{
						aaxis: {
							endlinecolor: '#2a3f5f',
							gridcolor: 'white',
							linecolor: 'white',
							minorgridcolor: 'white',
							startlinecolor: '#2a3f5f'
						},
						baxis: {
							endlinecolor: '#2a3f5f',
							gridcolor: 'white',
							linecolor: 'white',
							minorgridcolor: 'white',
							startlinecolor: '#2a3f5f'
						},
						type: 'carpet'
					}
				],
				table: [
					{
						cells: {
							fill: {
								color: '#EBF0F8'
							},
							line: {
								color: 'white'
							}
						},
						header: {
							fill: {
								color: '#C8D4E3'
							},
							line: {
								color: 'white'
							}
						},
						type: 'table'
					}
				],
				barpolar: [
					{
						marker: {
							line: {
								color: '#E5ECF6',
								width: 0.5
							},
							pattern: {
								fillmode: 'overlay',
								size: 10,
								solidity: 0.2
							}
						},
						type: 'barpolar'
					}
				],
				pie: [
					{
						automargin: true,
						type: 'pie'
					}
				]
			},
			layout: {
				autotypenumbers: 'strict',
				colorway: [
					'#636efa',
					'#EF553B',
					'#00cc96',
					'#ab63fa',
					'#FFA15A',
					'#19d3f3',
					'#FF6692',
					'#B6E880',
					'#FF97FF',
					'#FECB52'
				],
				font: {
					color: '#2a3f5f'
				},
				hovermode: 'closest',
				hoverlabel: {
					align: 'left'
				},
				paper_bgcolor: 'white',
				plot_bgcolor: '#E5ECF6',
				polar: {
					bgcolor: '#E5ECF6',
					angularaxis: {
						gridcolor: 'white',
						linecolor: 'white',
						ticks: ''
					},
					radialaxis: {
						gridcolor: 'white',
						linecolor: 'white',
						ticks: ''
					}
				},
				ternary: {
					bgcolor: '#E5ECF6',
					aaxis: {
						gridcolor: 'white',
						linecolor: 'white',
						ticks: ''
					},
					baxis: {
						gridcolor: 'white',
						linecolor: 'white',
						ticks: ''
					},
					caxis: {
						gridcolor: 'white',
						linecolor: 'white',
						ticks: ''
					}
				},
				coloraxis: {
					colorbar: {
						outlinewidth: 0,
						ticks: ''
					}
				},
				colorscale: {
					sequential: [
						[0, '#0d0887'],
						[0.1111111111111111, '#46039f'],
						[0.2222222222222222, '#7201a8'],
						[0.3333333333333333, '#9c179e'],
						[0.4444444444444444, '#bd3786'],
						[0.5555555555555556, '#d8576b'],
						[0.6666666666666666, '#ed7953'],
						[0.7777777777777778, '#fb9f3a'],
						[0.8888888888888888, '#fdca26'],
						[1, '#f0f921']
					],
					sequentialminus: [
						[0, '#0d0887'],
						[0.1111111111111111, '#46039f'],
						[0.2222222222222222, '#7201a8'],
						[0.3333333333333333, '#9c179e'],
						[0.4444444444444444, '#bd3786'],
						[0.5555555555555556, '#d8576b'],
						[0.6666666666666666, '#ed7953'],
						[0.7777777777777778, '#fb9f3a'],
						[0.8888888888888888, '#fdca26'],
						[1, '#f0f921']
					],
					diverging: [
						[0, '#8e0152'],
						[0.1, '#c51b7d'],
						[0.2, '#de77ae'],
						[0.3, '#f1b6da'],
						[0.4, '#fde0ef'],
						[0.5, '#f7f7f7'],
						[0.6, '#e6f5d0'],
						[0.7, '#b8e186'],
						[0.8, '#7fbc41'],
						[0.9, '#4d9221'],
						[1, '#276419']
					]
				},
				xaxis: {
					gridcolor: 'white',
					linecolor: 'white',
					ticks: '',
					title: {
						standoff: 15
					},
					zerolinecolor: 'white',
					automargin: true,
					zerolinewidth: 2
				},
				yaxis: {
					gridcolor: 'white',
					linecolor: 'white',
					ticks: '',
					title: {
						standoff: 15
					},
					zerolinecolor: 'white',
					automargin: true,
					zerolinewidth: 2
				},
				scene: {
					xaxis: {
						backgroundcolor: '#E5ECF6',
						gridcolor: 'white',
						linecolor: 'white',
						showbackground: true,
						ticks: '',
						zerolinecolor: 'white',
						gridwidth: 2
					},
					yaxis: {
						backgroundcolor: '#E5ECF6',
						gridcolor: 'white',
						linecolor: 'white',
						showbackground: true,
						ticks: '',
						zerolinecolor: 'white',
						gridwidth: 2
					},
					zaxis: {
						backgroundcolor: '#E5ECF6',
						gridcolor: 'white',
						linecolor: 'white',
						showbackground: true,
						ticks: '',
						zerolinecolor: 'white',
						gridwidth: 2
					}
				},
				shapedefaults: {
					line: {
						color: '#2a3f5f'
					}
				},
				annotationdefaults: {
					arrowcolor: '#2a3f5f',
					arrowhead: 0,
					arrowwidth: 1
				},
				geo: {
					bgcolor: 'white',
					landcolor: '#E5ECF6',
					subunitcolor: 'white',
					showland: true,
					showlakes: true,
					lakecolor: 'white'
				},
				title: {
					x: 0.05
				},
				mapbox: {
					style: 'light'
				}
			}
		},
		font: {
			color: '#2a3f5f',
			family: 'sans-serif'
		},
		margin: {
			l: 0,
			r: 0,
			b: 0,
			t: 0,
			pad: 0
		},
		xaxis: {
			fixedrange: true,
			showgrid: false,
			dtick: 1,
			labelalias: {
				1: 'B01',
				2: 'B04',
				3: 'B03',
				4: 'B04',
				5: 'B05',
				6: 'B06',
				7: 'B07',
				8: 'B08',
				9: 'B09',
				10: 'B10',
				11: 'B11'
			}
		},
		yaxis: {
			fixedrange: true,
			showgrid: false,
			zerolinecolor: 'rgba(0,0,0,0)'
		},
		paper_bgcolor: '#f8f9fa',
		plot_bgcolor: '#f8f9fa',
		bargap: 0,
		showlegend: false
	}
};

export default hotspotsSig;
