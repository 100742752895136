import React from 'react';

const SmeltersTable = ({ captures }) => {

	if (captures.length > 0){
		return (
			<table className="table table-hover">
				<thead className="table-light">
					<tr>
						<th scope="col">ID</th>
						<th scope="col">Capture Date</th>
						<th scope="col">Capture Time</th>
						<th scope="col">Site</th>
						<th scope="col">Sensor</th>
						<th scope="col">Site ID</th>
						<th scope="col">Initial State</th>
						<th scope="col">State</th>
						<th scope="col">Hotspots</th>
						<th scope="col">Invalid Data Frac</th>
						<th scope="col">Cloud Frac</th>
						<th scope="col">Max Band Ratio</th>
					</tr>
				</thead>
				<tbody className="table-group-divider">
					{captures.map((capture, idx) => (
						<tr key={idx}>
							<td>{capture.id}</td>
							<td>{capture.capturedDate}</td>
							<td>{capture.capturedTime}</td>
							<td>{capture.site_name}</td>
							<td>{capture.sensor}</td>
							<td>{capture.site_id}</td>
							<td>{capture.initial_state}</td>
							<td>{capture.state}</td>
							<td>{capture.hotspots}</td>
							<td>{capture.invalid_data_frac}</td>
							<td>{capture.cloud_frac}</td>
							<td>{capture.max_band_ratio}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	}
	
};

export default SmeltersTable;
