import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../../contexts/pipelineContext';

const QC_Category = () => {
	const pipelineContextQCCat = useContext(pipelineContext);

	const handleSetQCCategory = (qc_cat) => {
		pipelineContextQCCat.setComm(qc_cat.toLowerCase());
		pipelineContextQCCat.setProgressBar({
			width: 2 * (100 / 7),
			title: 'QC Type'
		});
	};

	return (
		<>
			<hr></hr>
			<div className="px-3 mx-3">
				<Row>
					<Col className="d-flex justify-content-start">
						<button
							className="btn interactButtonSecondary"
							style={{ maxHeight: '40px', width: '200px' }}
							disabled
						>
							<i class="fa-solid fa-left-long pe-3"></i>
							Back
						</button>
					</Col>
					<Col></Col>
					<Col className="d-flex justify-content-end"></Col>
				</Row>

				<Row>
					<h4 className="py-5">Select the category of QC to perform</h4>

					<>
						<Col className="col-3">
							<button
								onClick={(e) => handleSetQCCategory('Smelters')}
								className="QCTypeButtons"
							>
								<div
									className="card QCTypeButtonsBtn"
									style={{ width: '18rem', height: '120px' }}
								>
									<div className="card-body  d-flex flex-column justify-content-center">
										<h5 className="card-title ">
											Smelters <i className="fa-solid fa-city ps-3"></i>
										</h5>
										<p className="card-text">
											steel, copper, titanium and nickel
										</p>
									</div>
								</div>
							</button>
						</Col>
						<Col className="col-3">
							<button
								onClick={(e) => handleSetQCCategory('Stockpiles')}
								className="QCTypeButtons"
							>
								<div
									className="card QCTypeButtonsBtn"
									style={{ width: '18rem', height: '120px' }}
								>
									<div className="card-body d-flex flex-column justify-content-center">
										<h5 className="card-title ">
											Stockpiles <i class="fa-solid fa-cubes-stacked ps-3"></i>
										</h5>
										<p className="card-text">iron ore and scrap steel</p>
									</div>
								</div>
							</button>
						</Col>
						<Col className="col-3">
							<button
								//onClick={(e) => handleSetQCCategory('Re QC Form')}
								data-tooltip-id="reQCType"
								className="QCTypeButtons"
							>
								<div
									className="card QCTypeButtonsBtn"
									style={{ width: '18rem', height: '120px' }}
								>
									<div className="card-body d-flex flex-column align-items-center justify-content-center">
										<h5 className="card-title">
											Airfields <i class="fa-solid fa-tower-broadcast ps-3"></i>
										</h5>
										<p className="card-text">aircraft</p>
									</div>
								</div>
							</button>

							<Tooltip
								id="reQCType"
								place="bottom"
								effect="solid"
								style={{ backgroundColor: 'grey' }}
							>
								The option to QC airfields is not currently available
							</Tooltip>
						</Col>
					</>

					{/* {loading ? (
					<Col className="d-flex justify-content-evenly col-5">
						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							disabled
						>
							Daily QC <i className="fa fa-solid fa-calendar-day ps-3"></i>
						</button>

						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							disabled
						>
							Historic QC <i className="fa fa-solid fa-calendar-days ps-3"></i>
						</button>

						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							disabled
						>
							Re QC <i className="fa fa-solid fa-calendar-check ps-3"></i>
						</button>
					</Col>
				) : (
					<Col className="d-flex justify-content-evenly col-5">
						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							onClick={(e) => handleSetQCCategory('Daily QC Form')}
							data-tooltip-id="dailyQC"
						>
							Daily QC <i className="fa fa-solid fa-calendar-day ps-3"></i>
						</button>
						<Tooltip id="dailyQC" place="bottom" effect="solid">
							Perform a routine QC on images captured in the last day
						</Tooltip>

						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							onClick={(e) => handleSetQCCategory('Historic QC Form')}
							data-tooltip-id="historicQC"
						>
							Historic QC <i className="fa fa-solid fa-calendar-days ps-3"></i>
						</button>
						<Tooltip id="historicQC" place="bottom" effect="solid">
							Perform a historic QC only on captures from a particular site
						</Tooltip>

						<button
							className="btn interactButton mx-2"
							style={{ width: '150px' }}
							onClick={(e) => handleSetQCCategory('Re-QC Form')}
							data-tooltip-id="reQC"
						>
							Re QC <i className="fa fa-solid fa-calendar-check ps-3"></i>
						</button>
						<Tooltip id="reQC" place="bottom" effect="solid">
							Re-QC previously QC'd captures
						</Tooltip>
					</Col>
				)} */}
				</Row>
			</div>
		</>
	);
};

export default QC_Category;
