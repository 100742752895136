import React, { useContext } from 'react';
import config from '../config';
import { Tooltip } from 'react-tooltip';
import { pipelineContext } from '../contexts/pipelineContext';

const SmeltersTableBasic = ({ props}) => {

	const {capsInPipeline, currentCaptureIndex} = useContext(pipelineContext)

	return (
		<table className="table">
			<thead>
				<tr>
					<th scope="col">#</th>
					<th scope="col">Capture ID</th>
					<th scope="col">Site</th>
					<th scope="col">Captured</th>
					<th scope="col">Pre QC State</th>
					<th scope="col">Set State</th>
					<th scope="col">QC'd</th>
					<th scope="col">QC User</th>
				</tr>
			</thead>

			<tbody>
				{capsInPipeline.map((capture, idx) => (
					<tr key={idx}>
						{currentCaptureIndex === idx ? (
							<>
								<th scope="row" className="table-secondary">
									{idx + 1}
								</th>
								<td
									className="table-secondary"
									data-bs-dismiss="modal"
									//onClick={(e) => openThisCapture(e, idx)}
									//onClick={(e) => sentDataToParent(e, idx)}
								>
									{capture.id}
								</td>
								<td className="table-secondary">{capture.site_name}</td>
								<td className="table-secondary">{capture.captured}</td>
								<td className="table-secondary">
									{
										config.qcStates.filter(
											(qstate) => qstate.stateInt === capture.initial_state
										)[0].stateString
									}
								</td>

								{props.qcProps.straightToUpload ? (
									<>
										<td className="table-secondary">
											{
												config.qcStates.filter(
													(qstate) => qstate.stateInt === capture.state
												)[0].stateString
											}
										</td>
									</>
								) : (
									<>
										{config.qcStates
											.map((obj) => obj.stateInt)
											.includes(capture.newQC) ? (
											<td className="table-secondary">
												{
													config.qcStates.filter(
														(qstate) => qstate.stateInt === capture.newQC
													)[0].stateString
												}
											</td>
										) : (
											<td className="table-secondary"></td>
										)}
									</>
								)}

								{props.qcProps.straightToUpload ? (
									<>
										{capture.state != null ? (
											<td className="table-secondary">
												<i class="fa-solid fa-circle-check"></i>
											</td>
										) : (
											<td className="table-secondary">
												<i class="fa-solid fa-circle-xmark"></i>
											</td>
										)}
									</>
								) : (
									<>
										{config.qcStates
									.map((obj) => obj.stateInt)
									.includes(capture.newQC) ? (
										<td className="table-secondary">
										<i class="fa-solid fa-circle-check"></i>
									</td>
								) : (
									<td className="table-secondary">
												{/* <i class="fa-solid fa-circle-xmark"></i> */}
											</td>
								)}
									</>
								)}

								{/* {config.qcStates
									.map((obj) => obj.stateInt)
									.includes(capture.newQC) ? (
									<td className="table-secondary">
										<i class="fa-solid fa-circle-check"></i> 

										{capture.newQC}
									</td>
								) : (
									<td className="table-secondary">
										<i class="fa-solid fa-circle-xmark"></i>
									</td>
								)} */}
								<td className="table-secondary">{`${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`}</td>
							</>
						) : (
							<>
								<th scope="row">{idx + 1}</th>
								<td
									data-bs-dismiss="modal"
									// onClick={(e) => openThisCapture(e, idx)}
									//onClick={(e) => sentDataToParent(e, idx)}
								>
									{capture.id}
								</td>
								<td>{capture.site_name}</td>
								<td>{capture.captured}</td>
								<td>
									{
										config.qcStates.filter(
											(qstate) => qstate.stateInt === capture.initial_state
										)[0].stateString
									}
								</td>
								{props.qcProps.straightToUpload ? (
									<>
										<td >
											{
												config.qcStates.filter(
													(qstate) => qstate.stateInt === capture.state
												)[0].stateString
											}
										</td>
									</>
								) : (
									<>
										{config.qcStates
											.map((obj) => obj.stateInt)
											.includes(capture.newQC) ? (
											<td >
												{
													config.qcStates.filter(
														(qstate) => qstate.stateInt === capture.newQC
													)[0].stateString
												}
											</td>
										) : (
											<td ></td>
										)}
									</>
								)}

								{props.qcProps.straightToUpload ? (
									<>
										{capture.state != null ? (
											<td >
												<i class="fa-solid fa-circle-check"></i>
											</td>
										) : (
											<td >
												<i class="fa-solid fa-circle-xmark"></i>
											</td>
										)}
									</>
								) : (
									<>
										{config.qcStates
									.map((obj) => obj.stateInt)
									.includes(capture.newQC) ? (
										<td >
										<i class="fa-solid fa-circle-check"></i>
									</td>
								) : (
									<td >
												{/* <i class="fa-solid fa-circle-xmark"></i> */}
											</td>
								)}
									</>
								)}
								<td>{`${props.authProps.userAttributes.givenName.toLowerCase()}.${props.authProps.userAttributes.familyName.toLowerCase()}`}</td>
							</>
						)}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default SmeltersTableBasic;
