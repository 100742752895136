import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getDashboardData } from '../actions/getActions';

const endpointContext = createContext();

const EndpointProvider = ({ children, env }) => {
   
	const [capsQC3QC4, setCapsQC3QC4] = useState({
		smelterCapsQC3: [],
		smelterCapsQC4: [],
		stockpilesCapsQC3: [],
		stockpilesCapsQC4: [],
		smelterSites: [],
		smelterSitesWithCaptures: [],
		stockpilesSites: [],
		stockpilesSitesWithCaptures: [],
		availableCapsSmelters: {},
		availableCapsStockpiles: {}
	});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const fetchData = useCallback(async () => {
		setLoading(true);
		setError(null);
		try {
			const results = await getDashboardData(env);
			setCapsQC3QC4({
                smelterCapsQC3: results[0].data,
				stockpilesCapsQC3: results[1].data,
				smelterCapsQC4: results[2].data,
				stockpilesCapsQC4: results[3].data,
				//smelterSites: results[4].data,
				smelterSitesWithCaptures: results[4].data,
				//stockpilesSites: results[6].data,
				stockpilesSitesWithCaptures: results[5].data,
				//availableCapsSmelters: results[8].data,
				//availableCapsStockpiles: results[9].data,
            });
		} catch (error) {
			setError(error.message);
		} finally {
			setLoading(false);
		}
	}, [env]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<endpointContext.Provider
			value={{ capsQC3QC4, loading, error, refresh: fetchData }}
		>
			{children}
		</endpointContext.Provider>
	);
};

export { endpointContext, EndpointProvider };
