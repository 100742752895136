export const layerColours = {
	original: { color: '#ffffff', weight: 1, opacity: 0.5 },
	edited: { color: '#68BC00', weight: 1, opacity: 0.5 },
	created: { color: '#0062B1', weight: 1, opacity: 0.5 },
	deleted: { color: '#D33115', weight: 1, opacity: 0.1 },
	selected: { color: '#ffffff', weight: 2, opacity: 0.8 },
	aoi: { color: 'blue', fillOpacity: 0, weight: 1 }
};


