import React from 'react';

const StockpilesTable = ({ captures }) => {
	console.log(captures)
	if (captures.length > 0) {
		return (
			<table className="table table-hover">
				<thead className="thead-dark">
					{captures[0]['qc_date'] !== null && (
						<tr>
							<th colSpan={8} className="text-center">
								Capture Info
							</th>
							<th colSpan={3} className="text-center table-info">
								QC Info
							</th>
						</tr>
					)}
					<tr>
						<th scope="col">ID</th>
						<th scope="col">Capture Date</th>
						<th scope="col">Capture Time</th>
						<th scope="col">Site</th>
						<th scope="col">Model Name</th>
						<th scope="col">Model Version</th>
						<th scope="col">Site ID</th>
						<th scope="col">Total Pixels</th>
						{captures[0]['qc_date'] !== null && (
							<>
								<th scope="col" className="table-info">
									QC date
								</th>
								<th scope="col" className="table-info">
									QC time
								</th>
								<th scope="col" className="table-info">
									QC user
								</th>
							</>
						)}
					</tr>
				</thead>
				<tbody className="table-group-divider">
					{captures.map((capture, idx) => (
						<tr key={idx}>
							<td>{capture?.id}</td>
							<td>{capture?.capturedDate}</td>
							<td>{capture?.capturedTime}</td>
							<td>{capture?.site_name}</td>
							<td>{capture?.model_name}</td>
							<td>{capture?.model_version}</td>
							<td>{capture?.site_id}</td>
							<td>{capture?.total_pixels}</td>
							{captures[0]?.['qc_date'] !== null && (
								<>
									<td className="table-info">
										{capture?.['qc_date']?.slice(0, 10)}
									</td>
									<td className="table-info">
										{capture?.['qc_date']?.slice(10, 16)}
									</td>
									<td className="table-info">{capture?.['qc_user']}</td>
								</>
							)}
						</tr>
					))}
				</tbody>
			</table>
		);
	}
};

export default StockpilesTable;
