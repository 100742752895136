import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Tooltip } from 'react-tooltip'
import Plot from 'react-plotly.js'
import hotspotsSig from '../../pixelPlot';


const HotspotsProfile = ({hotspotProfile, fetchHotspotsData}) => {
  return (
    <div >
					{!hotspotProfile ? (
						<fieldset className="form-group p-3 border rounded-3">
							<legend className="float-none w-auto px-3 my-0 fs-6">
								Hotspots Data{' '}
								<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
									<i
										data-tooltip-id="hotspots-plot-data"
										//onClick={(e) => fetchHotspotsData()}
										className="fa-solid fa-arrows-rotate ms-1 fa-sm"
										style={{ color: 'grey' }}
									></i>
								</button>
							</legend>

							<Tooltip id="hotspots-plot-data" place="right" effect="solid">
								reload Hotspots Data
							</Tooltip>
							<div
								style={{ position: 'relative', width: '100%', height: '280px' }}
							>
								<Skeleton
									style={{
										position: 'absolute',
										width: '100%',
										height: '100%'
									}}
								/>
							</div>
						</fieldset>
					) : hotspotProfile && !hotspotProfile.hasOwnProperty('data') ? (
						<fieldset className="form-group p-3 border rounded-3">
							<legend className="float-none w-auto px-3 my-0 fs-6">
								Hotspots Data{' '}
								<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
									<i
										data-tooltip-id="hotspots-plot-data"
										//onClick={(e) => fetchHotspotsData()}
										className="fa-solid fa-arrows-rotate ms-1 fa-sm"
										style={{ color: 'grey' }}
									></i>
								</button>
							</legend>

							<Plot
								data={[]} // Use an empty array if data is falsy
								useResizeHandler
								layout={hotspotsSig.layout}
								style={{ width: '100%', height: '280px' }}
							/>
						</fieldset>
					) : (
						<fieldset className="form-group p-3 border rounded-3">
							<legend className="float-none w-auto px-3 my-0 fs-6">
								Hotspots Data{' '}
								<button style={{ backgroundColor: '#f8f9fa', border: 'none' }}>
									<i
										data-tooltip-id="hotspots-plot-data"
										onClick={(e) => fetchHotspotsData()}
										className="fa-solid fa-arrows-rotate ms-1 fa-sm"
										style={{ color: 'rgb(98, 152, 64)' }}
									></i>
								</button>
							</legend>

							<Tooltip id="hotspots-plot-data" place="right" effect="solid">
								reload Hotspots Data
							</Tooltip>

							<Plot
								data={hotspotProfile.data}
								//layout={plotData.layout}
								useResizeHandler
								layout={hotspotProfile.layout}
								style={{ width: '100%', height: '280px' }}
							/>
						</fieldset>
					)}
				</div>
  )
}

export default HotspotsProfile
