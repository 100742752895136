import React, { useState, useEffect, useRef, useContext } from 'react';
import CurrentlyQCingUser from './CurrentlyQCingUser';
import { Row, Col } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import CurrentlyQCing from './CurrentlyQCing';
import BeingUploaded from './BeingUploaded';
import CapturesToQC from './CapturesToQC';
import { endpointContext } from '../../contexts/endpointContext';
import ErrorFallback from '../ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';

const QCInfo = ({ user }) => {
	const [qcType, setQCType] = useState('smelters');
	const endpointContextQCInfo = useContext(endpointContext);

	useEffect(() => {
		endpointContextQCInfo.refresh();
	}, []);

	return (
		<div
			style={{
				backgroundColor: 'white',
				borderRadius: '6px',
				display: 'inline-block',
				boxShadow: '2px 2px 7px #d9d9d9',
				width: '100%'
			}}
			className="p-3 my-4"
			data-tooltip-id="QCInfo"
		>
			<Row>
				<Col>
					<h4>QC Info</h4>
				</Col>
				<Col className="d-flex justify-content-end pt-1">
					<div class="form-check-dashboard-qcinfo">
						<input
							className="form-check-input-dashboard-qcinfo"
							type="radio"
							name="flexRadioDefault"
							id="flexRadioDefault1"
							defaultChecked={true}
							onClick={(e) => setQCType('smelters')}
						></input>
						<label
							className="form-check-label-dashboard-qcinfo"
							htmlFor="flexRadioDefault1"
							data-tooltip-id="dashboard-qcinfo-smelters"
						>
							<i className="fa-solid fa-city"></i>
						</label>
					</div>
					<div className="form-check-dashboard-qcinfo">
						<input
							className="form-check-input-dashboard-qcinfo"
							type="radio"
							name="flexRadioDefault"
							id="flexRadioDefault2"
							onClick={(e) => setQCType('stockpiles')}
						></input>
						<label
							className="form-check-label-dashboard-qcinfo"
							htmlFor="flexRadioDefault2"
							data-tooltip-id="dashboard-qcinfo-stockpiles"
						>
							<i className="fa-solid fa-cubes-stacked"></i>
						</label>
					</div>
					<div
						className="form-check-dashboard-qcinfo"
						data-tooltip-id="dashboard-qcinfo-airfields"
					>
						<input
							className="form-check-input-dashboard-qcinfo"
							type="radio"
							name="flexRadioDefault"
							id="flexRadioDefault3"
							disabled
						></input>
						<label
							className="form-check-label-dashboard-qcinfo"
							htmlFor="flexRadioDefault3"
						>
							<i className="fa-solid fa-tower-broadcast"></i>
						</label>
					</div>
				</Col>
			</Row>
			<Tooltip id="dashboard-qcinfo-stockpiles">stockpiles</Tooltip>
			<Tooltip id="dashboard-qcinfo-smelters">smelters</Tooltip>
			<Tooltip id="dashboard-qcinfo-airfields">
				airfields (not available)
			</Tooltip>
			{/* <CapturesToQC capturesToQC={qcAvailableCaps} qcCat={qcType}/> */}
			<CurrentlyQCing qcCat={qcType} user={user.email} />{' '}
			<CurrentlyQCingUser user={user} qcType={qcType} />
			{/* <BeingUploaded capsQC3QC4={capsQC3QC4}/> */}
		</div>
	);
};

export default QCInfo;
