import React, { useEffect, useState, useRef, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { getInfo, getInfo2 } from '../../actions/getActions';
import SendCapsBackModal from '../Dashboard/SendCapsBackModal';
import SendOtherUsersCapsBackModal from '../Dashboard/SendOtherUsersCapsBackModal';
import QCInfo from '../Dashboard/QCInfo';
import config from '../../config';
import ErrorComponent from '../Dashboard/ErrorComponent';
import Breadcrumb from '../Breadcrumb';
import { Tooltip } from 'react-tooltip';
import Environment from '../Dashboard/Environment';
import { getDashboardData } from '../../actions/getActions';
import { endpointContext } from '../../contexts/endpointContext';

export default function Dashboard(props) {
	let [smelterCapsQC3, setSmelterCapsQC3] = useState([]);
	let [smelterCapsQC4, setSmelterCapsQC4] = useState([]);
	let [stockpilesCapsQC3, setStockpilesCapsQC3] = useState([]);
	let [stockpilesCapsQC4, setStockpilesCapsQC4] = useState([]);

	const endpointContextDashboard = useContext(endpointContext);

	useEffect(() => {
		document.title = 'GAINS QC | Home';
	}, []);

	useEffect(() => {
		try {
			endpointContextDashboard.refresh();
		} catch (e) {
			console.log(e);
		}
	}, []);

	useEffect(() => {
		try {
			endpointContextDashboard.refresh();
		} catch (e) {
			console.log(e);
		}
	}, [props.authProps.environment, props.qcProps.qcInProgress]);

	return (
		<>
			{props.authProps.isAuthenticated ? (
				<>
					{/* <SendCapsBackModal
						fetchData={fetchData}
						user={props.authProps.userAttributes}
						setCapturesInPipeline={props.qcProps.setCapturesInPipeline}
						setCapturesCurrentlyInQC={setSmelterCapsQC3}
						capturesCurrentlyInQC={smelterCapsQC3}
					/>

					<SendOtherUsersCapsBackModal
						fetchData={fetchData}
						user={props.authProps.userAttributes}
						setCapturesInPipeline={props.qcProps.setCapturesInPipeline}
						setCapturesCurrentlyInQC={setSmelterCapsQC3}
						capturesCurrentlyInQC={smelterCapsQC3}
					/> */}

					<div className="px-0 col" style={{ width: '90vw' }}>
						<div className="contentContainer" id="dashboard-container">
							<div style={{ marginTop: '63px' }}>
								<hr></hr>
								<h3>
									Welcome to QC{' '}
									<strong>{props.authProps.userAttributes.givenName}</strong>
								</h3>

								<div className="row">
									<div className="col-6">
										<h6>
											Welcome to the Earth-i QC Tool, where you can find
											information about all captures in the database and perform
											a Quality Control inspection for a given set of captures.{' '}
										</h6>
									</div>
								</div>

								<Row>
									<Col>
										<QCInfo user={props.authProps.userAttributes} />
									</Col>

									<Col>{/* <Environment props={props} /> */}</Col>

									<Col></Col>
								</Row>
							</div>
						</div>
					</div>
				</>
			) : (
				<Navigate to="/login" />
			)}
		</>
	);
}
