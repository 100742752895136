import React, { useContext, useEffect, useState } from 'react';
import divideReturnQCList from '../../helpers/divideReturnQCList';
import { putInfo } from '../../actions/getActions';
import { endpointContext } from '../../contexts/endpointContext';
import { Modal } from 'bootstrap';
import config from '../../config';
import useGainsQCApi from '../../services/useGainsQCApi';

const SendCapsBackModal = ({
	setCapturesInPipeline,
	setCapturesCurrentlyInQC,
	capturesCurrentlyInQC,
	fetchData,
	user,
	qcCat
}) => {
	const [sendingCapsBack, setSendingCapsBack] = useState(false);

	const { error: errorSendingCapsBack, makeRequest: sendCapsBack } =
		useGainsQCApi();

	const modalElement = document.getElementById('sendCapsBackModal');
	const endpointContextSendCapsBack = useContext(endpointContext);

	const capsUser = capturesCurrentlyInQC.filter(
		(cap) => cap.qc_user === user.email.split('@')[0]
	);

	const functionSendCapsBack = () => {
		setSendingCapsBack(true);
		const urls = divideReturnQCList(capsUser);

		let cat = qcCat.toLowerCase();

		for (let i in urls) {
			sendCapsBack(
				`${config.baseUrl}/${cat}/reset_captures?table=${config.tableNames[qcCat]}&captureIds=${urls[i]}`,
				'POST',
				null,
				'sending caps back'
			);
		}

		setSendingCapsBack(false);
	};

	return (
		<div
			className="modal modal-md fade"
			id="sendCapsBackModal"
			tabIndex="-1"
			aria-labelledby="sendCapsBackModalLabel"
			aria-hidden="true"
			style={{ marginTop: '100px' }}
		>
			<div className="modal-dialog">
				<div className="modal-content" style={{ marginLeft: '40px' }}>
					<div className="modal-header">
						<h5 className="modal-title" id="sendCapsBackModalLabel">
							Are you sure you want to remove {capsUser.length} captures from
							your pipeline?
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>

					{sendingCapsBack && (
						<div className="modal-body">
							<h5>Sending captures back to database..</h5>
						</div>
					)}

					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-secondary"
							data-bs-dismiss="modal"
						>
							Close
						</button>
						<button
							type="button"
							className="btn btn-danger"
							data-bs-dismiss="modal"
							onClick={(e) => functionSendCapsBack()}
						>
							Remove all captures
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SendCapsBackModal;
