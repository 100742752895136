import React, { useContext } from 'react';
import config from '../../config';
import { Tooltip } from 'react-tooltip';
import { Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { pipelineContext } from '../../contexts/pipelineContext';

const QCing_Form_Smelter = ({
	onQCSubmit,
	currentCap,
	imageData,
	userSelectedQCState,
	qcState,
	selectedRadio,
	setDisagreeReason,
	numCapsQCd,
	userHasSelected,
	skipCapture,
	imageDataError,
}) => {

	const {currentCaptureIndex, capsInPipeline} = useContext(pipelineContext)

	return (
		<div>
			<form className="d-flex flex-column" onSubmit={(e) => onQCSubmit(e)}>
				<fieldset className="form-group p-3 border rounded-3 bg-white">
					<legend className="float-none w-auto px-3 my-0 fs-6">
						QC Capture
					</legend>
					<div style={{ position: 'relative', width: '100%', height: '280px' }}>
						

						{!currentCap ? (
							<Skeleton width={100} height={100} />
						) : !imageData ? (
							<>
								<Row>
									<table
										id="QCFormTable"
										className="table"
										style={{
											borderCollapse: 'separate',
											borderSpacing: '0',
											padding: '0.25rem'
										}}
									>
										<tr>
											<td>
												{currentCap.initial_state === 1 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
											<td>
												{currentCap.initial_state === 0 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
											<td>
												{currentCap.initial_state === -1 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
										</tr>

										<tr>
											<td
												style={{
													borderTopLeftRadius: '22px',
													borderBottomLeftRadius: '22px',
													borderTop: '1px solid grey',
													borderBottom: '1px solid grey',
													borderLeft: '1px solid grey',
													padding: '0.25em'
												}}
											>
												<span class="r-pill__item activePill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-active"
																name="daily-qc-state"
																className="r-pill__input"
																value={1}
																checked={qcState === 1}
																//onClick={(e) => userHasSelected(1)}
															></input>
															<label for="qc-state-active">Active</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-active"
																name="daily-qc-state"
																className="r-pill__input"
																value={1}
																checked={selectedRadio === 1}
																//onClick={(e) => userHasSelected(1)}
															></input>
															<label for="qc-state-active">Active</label>
														</>
													)}
												</span>
											</td>
											<td
												style={{
													borderTop: 'solid 1px grey',
													borderBottom: 'solid 1px grey',
													padding: '0.25em'
												}}
											>
												<span class="r-pill__item inactivePill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-inactive"
																name="daily-qc-state"
																className="r-pill__input"
																value={0}
																checked={qcState === 0}
																//onClick={(e) => userHasSelected(0)}
															></input>
															<label for="qc-state-inactive">Inactive</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-inactive"
																name="daily-qc-state"
																className="r-pill__input"
																value={0}
																checked={selectedRadio === 0}
																//onClick={(e) => userHasSelected(0)}
															></input>
															<label for="qc-state-inactive">Inactive</label>
														</>
													)}
												</span>
											</td>
											<td
												style={{
													borderTopRightRadius: '22px',
													borderBottomRightRadius: '22px',
													borderTop: '1px solid grey',
													borderBottom: '1px solid grey',
													borderRight: '1px solid grey',
													padding: '0.25em'
												}}
											>
												<span class="r-pill__item invalidPill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-invalid"
																name="daily-qc-state"
																className="r-pill__input"
																value={-1}
																checked={qcState === -1}
																//onClick={(e) => userHasSelected(-1)}
															></input>
															<label for="qc-state-invalid">Invalid</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-invalid"
																name="daily-qc-state"
																className="r-pill__input"
																value={-1}
																checked={selectedRadio === -1}
																//onClick={(e) => userHasSelected(-1)}
															></input>
															<label for="qc-state-invalid">Invalid</label>
														</>
													)}
												</span>
											</td>
										</tr>

										<tr>
											<td>
												{currentCap.newQC === 1 && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
											<td>
												{currentCap.newQC === 0 && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
											<td>
												{' '}
												{currentCap.newQC === -1 && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
										</tr>

										<Tooltip id="preQCvalue" place="top" effect="solid">
											Pre-QC has determined this capture to be{' '}
											{currentCap.initial_state === 1 ? (
												<span className="fw-bold activeCap">
													{
														config.qcStates.filter(
															(qstate) =>
																qstate.stateInt === currentCap.initial_state
														)[0].stateString
													}
												</span>
											) : currentCap.initial_state === 0 ? (
												<span className="fw-bold inactiveCap">
													{
														config.qcStates.filter(
															(qstate) =>
																qstate.stateInt === currentCap.initial_state
														)[0].stateString
													}
												</span>
											) : (
												<span className=" fw-bold invalidCap">
													{
														config.qcStates.filter(
															(qstate) =>
																qstate.stateInt === currentCap.initial_state
														)[0].stateString
													}
												</span>
											)}
										</Tooltip>

										{currentCap.newQC && (
											<Tooltip id="QCvalue" place="top" effect="solid">
												You have already QC'd this image and determined the
												state to be{' '}
												{currentCap.newQC === 1 ? (
													<span className="fw-bold activeCap">
														{
															config.qcStates.filter(
																(qstate) => qstate.stateInt === currentCap.newQC
															)[0].stateString
														}
													</span>
												) : currentCap.newQC === 0 ? (
													<span className="fw-bold inactiveCap">
														{
															config.qcStates.filter(
																(qstate) => qstate.stateInt === currentCap.newQC
															)[0].stateString
														}
													</span>
												) : (
													<span className="fw-bold invalidCap">
														{
															config.qcStates.filter(
																(qstate) => qstate.stateInt === currentCap.newQC
															)[0].stateString
														}
													</span>
												)}
											</Tooltip>
										)}
									</table>
								</Row>
							</>
						) : (
							<>
								<Row>
									<table
										id="QCFormTable"
										className="table"
										style={{
											borderCollapse: 'separate',
											borderSpacing: '0'
										}}
									>
										<tr>
											<td>
												{currentCap.initial_state === 1 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
											<td>
												{currentCap.initial_state === 0 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
											<td>
												{currentCap.initial_state === -1 && (
													<p className="my-0 py-0" data-tooltip-id="preQCvalue">
														Pre-QC
													</p>
												)}
											</td>
										</tr>

										<tr>
											<td
												style={{
													borderTopLeftRadius: '22px',
													borderBottomLeftRadius: '22px',
													borderTop: '1px solid grey',
													borderBottom: '1px solid grey',
													borderLeft: '1px solid grey'
												}}
											>
												<span class="r-pill__item activePill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-active"
																name="daily-qc-state"
																className="r-pill__input"
																value={1}
																checked={qcState === 1}
																onClick={(e) => userHasSelected(1)}
															></input>
															<label for="qc-state-active">Active</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-active"
																name="daily-qc-state"
																className="r-pill__input"
																value={1}
																checked={selectedRadio === 1}
																onClick={(e) => userHasSelected(1)}
															></input>
															<label for="qc-state-active">Active</label>
														</>
													)}
												</span>
											</td>
											<td
												style={{
													borderTop: 'solid 1px grey',
													borderBottom: 'solid 1px grey'
												}}
											>
												<span class="r-pill__item inactivePill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-inactive"
																name="daily-qc-state"
																className="r-pill__input"
																value={0}
																checked={qcState === 0}
																onClick={(e) => userHasSelected(0)}
															></input>
															<label for="qc-state-inactive">Inactive</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-inactive"
																name="daily-qc-state"
																className="r-pill__input"
																value={0}
																checked={selectedRadio === 0}
																onClick={(e) => userHasSelected(0)}
															></input>
															<label for="qc-state-inactive">Inactive</label>
														</>
													)}
												</span>
											</td>
											<td
												style={{
													borderTopRightRadius: '22px',
													borderBottomRightRadius: '22px',
													borderTop: '1px solid grey',
													borderBottom: '1px solid grey',
													borderRight: '1px solid grey'
												}}
											>
												<span class="r-pill__item invalidPill">
													{currentCap && userSelectedQCState ? (
														<>
															<input
																type="radio"
																id="qc-state-invalid"
																name="daily-qc-state"
																className="r-pill__input"
																value={-1}
																checked={qcState === -1}
																onClick={(e) => userHasSelected(-1)}
															></input>
															<label for="qc-state-invalid">Invalid</label>
														</>
													) : (
														<>
															<input
																type="radio"
																id="qc-state-invalid"
																name="daily-qc-state"
																className="r-pill__input"
																value={-1}
																checked={selectedRadio === -1}
																onClick={(e) => userHasSelected(-1)}
															></input>
															<label for="qc-state-invalid">Invalid</label>
														</>
													)}
												</span>
											</td>
										</tr>

										<tr>
											<td>
												{currentCap.newQC === 1 && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
											<td>
												{currentCap.newQC === 0 && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
											<td>
												{' '}
												{currentCap.newQC === -1 && (
													<p className="my-0 py-0" data-tooltip-id="QCvalue">
														QC
													</p>
												)}
											</td>
										</tr>

										<Tooltip id="preQCvalue" place="top" effect="solid">
											Pre-QC has determined this capture to be{' '}
											{currentCap.initial_state === 1 ? (
												<span className="fw-bold activeCap">
													{
														config.qcStates.filter(
															(qstate) =>
																qstate.stateInt === currentCap.initial_state
														)[0].stateString
													}
												</span>
											) : currentCap.initial_state === 0 ? (
												<span className="fw-bold inactiveCap">
													{
														config.qcStates.filter(
															(qstate) =>
																qstate.stateInt === currentCap.initial_state
														)[0].stateString
													}
												</span>
											) : (
												<span className=" fw-bold invalidCap">
													{
														config.qcStates.filter(
															(qstate) =>
																qstate.stateInt === currentCap.initial_state
														)[0].stateString
													}
												</span>
											)}
										</Tooltip>

										{currentCap.newQC && (
											<Tooltip id="QCvalue" place="top" effect="solid">
												You have already QC'd this image and determined the
												state to be{' '}
												{currentCap.newQC === 1 ? (
													<span className="fw-bold activeCap">
														{
															config.qcStates.filter(
																(qstate) => qstate.stateInt === currentCap.newQC
															)[0].stateString
														}
													</span>
												) : currentCap.newQC === 0 ? (
													<span className="fw-bold inactiveCap">
														{
															config.qcStates.filter(
																(qstate) => qstate.stateInt === currentCap.newQC
															)[0].stateString
														}
													</span>
												) : (
													<span className="fw-bold invalidCap">
														{
															config.qcStates.filter(
																(qstate) => qstate.stateInt === currentCap.newQC
															)[0].stateString
														}
													</span>
												)}
											</Tooltip>
										)}
									</table>
								</Row>
							</>
						)}

						{/* DISAGREE REASON */}
						{currentCap &&
						currentCap.initial_state != qcState &&
						currentCap.initial_state != -1 &&
						qcState === -1 ? (
							<Row>
								{!currentCap.hasOwnProperty('newQC') ? (
									<p className="text-center text-secondary">
										Reason for disagreeing with pre-QC state
									</p>
								) : (
									<p className="text-center text-secondary">
										Capture already QC'd: reason for changing previous answer
									</p>
								)}

								<Col className="d-flex justify-content-center">
									<div className="mb-2">
										<input
											className="form-check-input qcing-extra-reason"
											type="radio"
											id="site-cloud-covered"
											name="qc-extra-questions"
											value={1}
											defaultChecked={true}
											onClick={(e) => setDisagreeReason(1)}
										></input>

										<label
											className="ps-2 pe-4"
											for="site-cloud-covered"
											//data-tooltip-id="QCInvalidChoice"
										>
											Site cloud covered
										</label>

										<input
											className="form-check-input qcing-extra-reason"
											type="radio"
											id="site-out-of-swath"
											name="qc-extra-questions"
											value={2}
											onClick={(e) => setDisagreeReason(2)}
										></input>

										<label
											className="ps-2"
											for="site-out-of-swath"
											//data-tooltip-id="QCInvalidChoice"
										>
											Site out of swath
										</label>
									</div>
								</Col>
							</Row>
						) : currentCap &&
						  currentCap.initial_state != qcState &&
						  currentCap.initial_state != 1 &&
						  currentCap.use_smoke &&
						  qcState === 1 ? (
							<Row>
								{!currentCap.hasOwnProperty('newQC') ? (
									<p className="text-center text-secondary">
										Reason for disagreeing with pre-QC state
									</p>
								) : (
									<p className="text-center text-secondary">
										Capture already QC'd: reason for changing previous answer
									</p>
								)}
								<Col className="d-flex justify-content-center">
									<div className="mb-2">
										<input
											className="form-check-input qcing-extra-reason"
											type="radio"
											id="hotspots-visible"
											name="qc-extra-questions"
											value={3}
											defaultChecked={true}
											onClick={(e) => setDisagreeReason(3)}
										></input>

										<label
											className="ps-2 pe-4"
											for="hotspots-visible"
											//data-tooltip-id="QCInvalidChoice"
										>
											Hotspots visible
										</label>

										<input
											className="form-check-input qcing-extra-reason"
											type="radio"
											id="smoke-visible"
											name="qc-extra-questions"
											value={4}
											onClick={(e) => setDisagreeReason(4)}
										></input>

										<label
											className="ps-2"
											for="smoke-visible"
											//data-tooltip-id="QCInvalidChoice"
										>
											Smoke visible
										</label>

										<input
											className="form-check-input qcing-extra-reason"
											type="radio"
											id="hotspots-smoke-visible"
											name="qc-extra-questions"
											value={5}
											onClick={(e) => setDisagreeReason(5)}
										></input>

										<label
											className="ps-2 pe-4"
											for="hotspots-smoke-visible"
											//data-tooltip-id="QCInvalidChoice"
										>
											Hotspots and smoke visible
										</label>
									</div>
								</Col>
							</Row>
						) : (
							<p></p>
						)}

						{/* BUTTONS */}
						{currentCap && (
							<div
								className="position-absolute bottom-0 end-0"
								style={{ width: '100%' }}
							>
								<Row>
									<Col className="d-flex justify-content-end pt-1">
										<div style={{ width: '100%' }}>
											{/* if data did not arrive */}
											{imageDataError ? (
												<div className="row">
													<div className="col-3"></div>
													<div className="col-3">
														<button
															className="btn interactButton mt-2 mx-0 my-0"
															style={{ width: '100%' }}
															onClick={(e) => skipCapture(e)}
															data-tooltip-id="skipCapTT"
														>
															Skip
														</button>
														<Tooltip id="skipCapTT" place="top" effect="solid">
															Error collecting data from database; skip capture
														</Tooltip>
													</div>
													<div className="col-6 d-flex flex-column align-items-end">
														<button
															className="btn interactButtonSecondary mt-2 mx-0 my-0"
															style={{ width: '160px' }}
															disabled
														>
															{' '}
															<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
														</button>
													</div>
												</div>
											) : (
												<>
													<div className="row">
														<div className="col-3 d-flex align-items-center">
															<p className="my-0 py-0">
																<small>
																	({currentCaptureIndex + 1}/
																	{capsInPipeline.length})
																</small>{' '}
																<i
																	type="button"
																	data-bs-toggle="modal"
																	data-bs-target="#exampleModal"
																	className="interact ms-1 fa-solid fa-bars-progress fa-md"
																></i>
															</p>
														</div>

														<div className="col-3">
															{numCapsQCd !== capsInPipeline.length ? (
																<button
																	className="btn interactButtonSecondary mt-2 mx-0 my-0"
																	style={{ width: '100%' }}
																	onClick={(e) => skipCapture(e)}
																>
																	Skip
																</button>
															) : (
																<button
																	className="btn interactButtonSecondary mt-2 mx-0 my-0"
																	style={{ width: '100%' }}
																	disabled
																>
																	Skip
																</button>
															)}
														</div>

														{/* capture QC'd and not changing */}
														{config.qcStates
															.map((obj) => obj.stateInt)
															.includes(currentCap.newQC) &&
															currentCap.newQC === selectedRadio &&
															selectedRadio !== undefined && (
																<div className="col-6 d-flex flex-column align-items-end">
																	{numCapsQCd === capsInPipeline.length ? (
																		<>
																			<button
																				className="btn interactButton mt-2 mx-0 my-0"
																				style={{ width: '160px' }}
																				data-tooltip-id="moveToUploadTT"
																				data-bs-toggle="modal"
																				data-bs-target="#moveToUploadModal"
																				onClick={(e) => e.preventDefault()}
																			>
																				Finish QCing{' '}
																				<i class="ms-2 fa-solid fa-circle-check"></i>
																			</button>
																			<Tooltip
																				id="moveToUploadTT"
																				place="top"
																				effect="solid"
																			>
																				review captures and move to upload stage
																			</Tooltip>
																		</>
																	) : (
																		<>
																			<button
																				className="btn interactButton mt-2 mx-0 my-0"
																				style={{ width: '160px' }}
																				data-tooltip-id="captureAlreadyQCd2"
																			>
																				<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																			</button>
																			<Tooltip
																				id="captureAlreadyQCd2"
																				place="top"
																				effect="solid"
																			>
																				capture already QC'd: no change
																			</Tooltip>
																		</>
																	)}
																</div>
															)}

														{/* capture QC'd and changing state */}
														{config.qcStates
															.map((obj) => obj.stateInt)
															.includes(currentCap.newQC) &&
															currentCap.newQC !== selectedRadio &&
															selectedRadio !== undefined && (
																<div className="col-6 d-flex flex-column align-items-end">
																	{numCapsQCd === capsInPipeline.length ? (
																		<button
																			className="btn interactButton mt-2 mx-0 my-0"
																			style={{ width: '160px' }}
																			data-tooltip-id="captureAlreadyQCd3"
																			data-bs-toggle="modal"
																			data-bs-target="#moveToUploadModal"
																			onClick={(e) => e.preventDefault()}
																		>
																			<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																		</button>
																	) : (
																		<>
																			<button
																				className="btn interactButton mt-2 mx-0 my-0"
																				style={{ width: '160px' }}
																				data-tooltip-id="captureAlreadyQCd3"
																			>
																				Set to{' '}
																				<strong>
																					{
																						config.qcStates.filter(
																							(qstate) =>
																								qstate.stateInt ===
																								selectedRadio
																						)[0].stateString
																					}
																				</strong>{' '}
																				<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																			</button>
																			<Tooltip
																				id="captureAlreadyQCd3"
																				place="top"
																				effect="solid"
																			>
																				capture already QC'd:{' '}
																				<strong>change</strong> to{' '}
																				{selectedRadio === 1 ? (
																					<span className="fw-bold activeCap">
																						{
																							config.qcStates.filter(
																								(qstate) =>
																									qstate.stateInt ===
																									selectedRadio
																							)[0].stateString
																						}
																					</span>
																				) : selectedRadio === 0 ? (
																					<span className="fw-bold inactiveCap">
																						{
																							config.qcStates.filter(
																								(qstate) =>
																									qstate.stateInt ===
																									selectedRadio
																							)[0].stateString
																						}
																					</span>
																				) : (
																					<span className="fw-bold invalidCap">
																						{
																							config.qcStates.filter(
																								(qstate) =>
																									qstate.stateInt ===
																									selectedRadio
																							)[0].stateString
																						}
																					</span>
																				)}
																			</Tooltip>
																		</>
																	)}
																</div>
															)}

														{/* QC and agree */}
														{!config.qcStates
															.map((obj) => obj.stateInt)
															.includes(currentCap.newQC) &&
															currentCap.initial_state === selectedRadio &&
															selectedRadio !== undefined && (
																<div className="col-6 d-flex flex-column align-items-end">
																	{numCapsQCd === capsInPipeline.length ? (
																		<button
																			className="btn interactButton mt-2 mx-0 my-0"
																			style={{ width: '160px' }}
																			data-tooltip-id="captureAlreadyQCd3"
																			data-bs-toggle="modal"
																			data-bs-target="#moveToUploadModal"
																			onClick={(e) => e.preventDefault()}
																		>
																			<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																		</button>
																	) : (
																		<>
																			<button
																				className="btn interactButton mt-2 mx-0 my-0"
																				style={{ width: '160px' }}
																				data-tooltip-id="setQCagree"
																			>
																				Set to{' '}
																				<strong>
																					{
																						config.qcStates.filter(
																							(qstate) =>
																								qstate.stateInt ===
																								currentCap.initial_state
																						)[0].stateString
																					}
																				</strong>{' '}
																				<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																			</button>
																			<Tooltip
																				id="setQCagree"
																				place="top"
																				effect="solid"
																			>
																				<strong>agree</strong> with pre-QC state{' '}
																				{selectedRadio === 1 ? (
																					<span className="fw-bold activeCap">
																						{
																							config.qcStates.filter(
																								(qstate) =>
																									qstate.stateInt ===
																									selectedRadio
																							)[0].stateString
																						}
																					</span>
																				) : selectedRadio === 0 ? (
																					<span className="fw-bold inactiveCap">
																						{
																							config.qcStates.filter(
																								(qstate) =>
																									qstate.stateInt ===
																									selectedRadio
																							)[0].stateString
																						}
																					</span>
																				) : (
																					<span className="fw-bold invalidCap">
																						{
																							config.qcStates.filter(
																								(qstate) =>
																									qstate.stateInt ===
																									selectedRadio
																							)[0].stateString
																						}
																					</span>
																				)}
																			</Tooltip>
																		</>
																	)}
																</div>
															)}

														{/* QC and disagree */}
														{!config.qcStates
															.map((obj) => obj.stateInt)
															.includes(currentCap.newQC) &&
															currentCap.initial_state !== selectedRadio &&
															selectedRadio !== undefined && (
																<div className="col-6 d-flex flex-column align-items-end">
																	{numCapsQCd === capsInPipeline.length ? (
																		<button
																			className="btn interactButton mt-2 mx-0 my-0"
																			style={{ width: '160px' }}
																			data-tooltip-id="captureAlreadyQCd3"
																			data-bs-toggle="modal"
																			data-bs-target="#moveToUploadModal"
																			onClick={(e) => e.preventDefault()}
																		>
																			<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																		</button>
																	) : (
																		<>
																			<button
																				className="btn interactButton mt-2 mx-0 my-0"
																				style={{ width: '160px' }}
																				data-tooltip-id="setQCdisagree"
																			>
																				Set to{' '}
																				<strong>
																					{
																						config.qcStates.filter(
																							(qstate) =>
																								qstate.stateInt ===
																								selectedRadio
																						)[0].stateString
																					}
																				</strong>
																				<i class="fa-solid fa-arrow-right fa-xs ps-2"></i>
																			</button>
																			<Tooltip
																				id="setQCdisagree"
																				place="top"
																				effect="solid"
																			>
																				<strong>disagree</strong> with pre-QC
																				state{' '}
																				{currentCap.initial_state === 1 ? (
																					<span className="fw-bold activeCap">
																						{
																							config.qcStates.filter(
																								(qstate) =>
																									qstate.stateInt ===
																									currentCap.initial_state
																							)[0].stateString
																						}
																					</span>
																				) : currentCap.initial_state === 0 ? (
																					<span className="fw-bold inactiveCap">
																						{
																							config.qcStates.filter(
																								(qstate) =>
																									qstate.stateInt ===
																									currentCap.initial_state
																							)[0].stateString
																						}
																					</span>
																				) : (
																					<span className="fw-bold invalidCap">
																						{
																							config.qcStates.filter(
																								(qstate) =>
																									qstate.stateInt ===
																									currentCap.initial_state
																							)[0].stateString
																						}
																					</span>
																				)}
																			</Tooltip>
																		</>
																	)}
																</div>
															)}
													</div>
												</>
											)}
										</div>
									</Col>{' '}
								</Row>
							</div>
						)}
					</div>
				</fieldset>
			</form>
		</div>
	);
};

export default QCing_Form_Smelter;
