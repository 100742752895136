import React, { useEffect, useState, useRef, useContext } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-rastercoords';
import 'leaflet-easybutton/src/easy-button.css';
import 'leaflet-easybutton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import {
	addLabelToPolygon,
	getBoundingBox,
	getCenterOfFeatureCollection,
	validatePolygon
} from '../../helpers/leafletHelpers';
import { Tooltip } from 'react-tooltip';
import stockpiles from '../../helpers/stockpiles';
import { layerColours } from '../../helpers/mapHelpers';
import { pipelineContext } from '../../contexts/pipelineContext';

import * as turf from '@turf/turf';

// Override Leaflet deprecated _flat method with the recommended isFlat method
L.Polyline._flat = L.LineUtil.isFlat;

const QC_Image = ({
	imageData,
	modalId,
	imageUrl,
	openModal,
	editedGeojson,
	setEditedGeojson,
	currentCapture,
	setDrawnItemsValid,
	drawnItemsValid,
	featureCollection,
	setFeatureCollection,
	featureLayers,
	setFeatureLayers,
	stockpilesGeojsons,
	setStockpilesGeojsons
}) => {
	let [imageFeatureCollection, setImageFeatureCollection] = useState();
	const pipelineContextQCImage = useContext(pipelineContext);

	const [originalLayer, setOriginalLayer] = useState(new L.FeatureGroup());
	const [originalFeatureCollection, setOriginalFeatureCollection] = useState();

	const mapRef = useRef(null);
	const imageLayerRef = useRef(null);
	const aoiLayerRef = useRef(null);
	const originalLayerRef = useRef(new L.FeatureGroup());

	const [showAOILayer, setShowAOILayer] = useState(true);
	const [showEditedLayer, setShowEditedLayer] = useState(true);
	const [showImageLayer, setShowImageLayer] = useState(true);

	const stockpilesCase = 1;


	// useEffect(() => {
	// 	if (pipelineContextQCImage.comm === 'Stockpiles') {
	// 		let idCounter = 1;
	// 		console.log(imageFeatureCollection);
	// 		imageFeatureCollection.features.forEach((feature) => {
	// 			feature.properties.id = idCounter;
	// 			feature.status = '';
	// 			feature.newGeom = '';
	// 			idCounter++;
	// 		});

	// 		// change to API call instead of hardcoded data
	// 		setFeatureCollection(stockpiles);
	// 		setFeatureLayers({ layers: [] });
	// 	}
	// }, [imageFeatureCollection]);

	useEffect(() => {
		if (pipelineContextQCImage.comm === 'stockpiles') {
			if (stockpilesGeojsons) {
				try {
					const centerPoint = getCenterOfFeatureCollection(
						JSON.parse(imageData.data.aoi)
					);
					const bounds = getBoundingBox(JSON.parse(imageData.data.aoi));
					let currentlyEditingLayer = null;

					// create map ONCE
					if (!mapRef.current) {
						mapRef.current = L.map(`map-${modalId}`, {
							zoomControl: false
						}).setView(centerPoint, 13);
						L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
							lang: 'en',
							opacity: 0.2
						}).addTo(mapRef.current);
						mapRef.current.fitBounds(bounds, { padding: [0, 0] });
						mapRef.current.setMaxZoom(35);

						var recenterBtn = L.easyButton({
							states: [
								{
									stateName: 'Zoom to image',
									icon: 'fa-solid fa-location-crosshairs',
									title: 'Relocate map to image', // Tooltip text
									onClick: function () {
										mapRef.current.setView(centerPoint, 16);
									}
								}
							]
						}).addTo(mapRef.current);

						// if using more complex case, add draw control
						if (stockpilesCase === 2) {
							const drawControl = new L.Control.Draw({
								edit: {
									featureGroup: originalLayerRef.current,
									edit: false, // Disable editing via draw control
									remove: false // Disable removal via draw control
								},
								draw: {
									polygon: true,
									marker: false,
									polyline: false,
									circle: false,
									rectangle: false,
									circlemarker: false
								}
							});
							mapRef.current.addControl(drawControl);
						}
					}

					// image layer ONCE
					if (!imageLayerRef.current) {
						const imgUrl = imageData.data.visual;

						var imageBounds = [
							[imageData.data.bbox[1], imageData.data.bbox[0]],
							[imageData.data.bbox[3], imageData.data.bbox[2]]
						];
						imageLayerRef.current = L.imageOverlay(imgUrl, imageBounds).addTo(
							mapRef.current
						);

						imageLayerRef.current.on('error', (error) => {
							console.error('Error loading image:', error);
						});
					}

					// aoi layer ONCE
					if (!aoiLayerRef.current && mapRef.current) {
						aoiLayerRef.current = L.geoJSON(JSON.parse(imageData.data.aoi)).addTo(
							mapRef.current
						);
						aoiLayerRef.current.setStyle(layerColours.aoi);
						aoiLayerRef.current.bringToBack();
					}

					// stockpiles layers: Case 1 static, Case 2 editable
					const drawnItems = new L.layerGroup();
					mapRef.current.addLayer(drawnItems);
					let layerIdCounter = 1;
					let layers = [];

					if (originalLayerRef.current.getLayers().length === 0) {
						if (stockpilesCase === 2) {
							// for (let sp in stockpilesGeojsons[0].original) {
							// 	const geojsonLayer = L.geoJSON(stockpilesGeojsons[0].original[sp]);
							// 	//const geojsonLayer = L.geoJSON(JSON.parse(imageData.stockpiles.features))
							// 	geojsonLayer.addTo(mapRef.current)
							// 	drawnItems.addLayer(geojsonLayer);
							// 	layers.push(geojsonLayer);
							// }
							if (imageData.data.hasOwnProperty('stockpiles')) {
								for (let sp in JSON.parse(imageData.data.stockpiles).features) {
									const geojsonLayer = L.geoJSON(
										JSON.parse(imageData.data.stockpiles).features[sp]
									);
									//const geojsonLayer = L.geoJSON(JSON.parse(imageData.stockpiles.features))
									geojsonLayer.addTo(mapRef.current);
									drawnItems.addLayer(geojsonLayer);
									layers.push(geojsonLayer);

									geojsonLayer.setStyle(layerColours.original);
									geojsonLayer.bindTooltip(
										`Layer ${layerIdCounter} (original)`
									);

									//makeLayerEditable(geojsonLayer);
									originalLayerRef.current = drawnItems;
								}
							}

							// const geojsonLayer = L.geoJSON(stockpilesGeojsons[0].original, {
							// 	onEachFeature: function (feature, layer) {
							// 		console.log(feature);
							// 		console.log(layer);
							// 		feature.properties.id = layerIdCounter;

							// 		drawnItems.addLayer(layer);
							// 		layers.push(layer);

							// 		var poly = L.polygon(feature.geometry.coordinates);

							// 		try {
							// 			let label = addLabelToPolygon(layer, layerIdCounter);
							// 			label.addTo(mapRef.current);
							// 		} catch (e) {
							// 			console.log(e);
							// 		}

							// 		layer.setStyle(layerColours.original);
							// 		layer.bindTooltip(`Layer ${layerIdCounter} (original)`);

							// 		layerIdCounter++;

							// 		//makeLayerEditable(layer);

							// 		let x = imageFeatureCollection.features.filter(
							// 			(feature) =>
							// 				feature.properties.id === layer.feature.properties.id
							// 		)[0];

							// 		setFeatureLayers({
							// 			layers: Object.values(drawnItems._layers)
							// 		});
							// 	}
							// }).addTo(mapRef.current);

							// 		const handleMapClick = (e) => {
							// 			if (currentlyEditingLayer) {
							// 				console.log(currentlyEditingLayer);
							// 				try {
							// 					let status =
							// 						currentlyEditingLayer.feature.status.toLowerCase();
							// 					currentlyEditingLayer.setStyle(layerColours[status]);
							// 				} catch (e) {
							// 					console.log(e);
							// 				}
							// 			}

							// 			disableEditing();

							// 			currentlyEditingLayer = null;
							// 			disableButton(deleteBtn);
							// 			disableButton(simplifyBtn);
							// 		};

							// 		// on map click, disable editing & deleting capabilities
							// 		mapRef.current.on('click', handleMapClick);

							// 		mapRef.current.on(L.Draw.Event.CREATED, function (e) {
							// 			var type = e.layerType,
							// 				layer = e.layer;
							// 			layer.setStyle(layerColours.original);

							// 			drawnItems.addLayer(layer);

							// 			layer.on('dragend', handleLayerEdit);
							// 			layer.on('edit', handleLayerEdit);
							// 			layer.on('update', handleLayerEdit);

							// 			var geojson = layer.toGeoJSON();

							// 			const isValid = validatePolygon(
							// 				geojson,
							// 				JSON.parse(imageData.aoi)
							// 			);

							// 			if (!isValid) {
							// 				layer.setStyle(layerColours.deleted);
							// 				setDrawnItemsValid(false);
							// 				mapRef.current.off('click', handleMapClick);
							// 				mapRef.current.removeControl(deleteBtn);
							// 			} else {
							// 				setDrawnItemsValid(true);
							// 				mapRef.current.on('click', handleMapClick);
							// 				layer.setStyle(layerColours.original);
							// 				mapRef.current.addControl(deleteBtn);
							// 			}

							// 			layer.on('mouseover', () => {
							// 				layer.setStyle({ weight: 3, opacity: 0.8 });
							// 			});
							// 			layer.on('mouseout', () => {
							// 				layer.setStyle({ weight: 1, opacity: 0.5 });
							// 			});

							// 			try {
							// 				let label = addLabelToPolygon(
							// 					layer,
							// 					originalLayerRef.current.getLayers().length
							// 				);
							// 				label.addTo(mapRef.current);
							// 			} catch (e) {
							// 				console.log(e);
							// 			}

							// 			layer.bindTooltip(
							// 				`Layer ${
							// 					originalLayerRef.current.getLayers().length
							// 				} (created)`
							// 			);

							// 			layer.off('dblclick');

							// 			layer.on('dblclick', function (event) {
							// 				L.DomEvent.stopPropagation(event); // Prevent the map click event from firing
							// 				enableEditing(layer);
							// 				deleteBtn.addTo(mapRef.current);
							// 				currentlyEditingLayer = layer;
							// 			});

							// 			layer.feature = {
							// 				type: 'Feature',
							// 				properties: {
							// 					stockpiles: 1,
							// 					id: originalLayerRef.current.getLayers().length
							// 				},
							// 				geometry: geojson.geometry,
							// 				status: 'Created',
							// 				newGeom: ''
							// 			};

							// 			let newObj = {
							// 				type: 'Feature',
							// 				properties: {
							// 					stockpiles: 1,
							// 					id: originalLayerRef.current.getLayers().length
							// 				},
							// 				newGeom: '',
							// 				status: 'Created',
							// 				geometry: geojson.geometry
							// 			};

							// 			setFeatureLayers((prevState) => ({
							// 				layers: [...prevState.layers, layer]
							// 			}));

							// 			setFeatureCollection((prevState) => ({
							// 				...imageFeatureCollection,
							// 				features: [...prevState.features, newObj]
							// 			}));

							// 			originalLayerRef.current.addLayer(layer);
							// 		});

							// 		function handleLayerEdit(event) {
							// 			const latLngArray = event.sourceTarget.editing.latlngs[0][0];

							// 			event.sourceTarget.setStyle(layerColours.edited);

							// 			const coordinates = latLngArray.map((latLng) => [
							// 				latLng.lng,
							// 				latLng.lat
							// 			]);

							// 			coordinates.push(coordinates[0]);
							// 			geojson = turf.polygon([coordinates]);

							// 			const isValid = validatePolygon(
							// 				geojson,
							// 				JSON.parse(imageData.aoi)
							// 			);

							// 			if (isValid) {
							// 				setDrawnItemsValid(true);
							// 				mapRef.current.on('click', handleMapClick);
							// 				event.sourceTarget.setStyle(layerColours.edited);
							// 				mapRef.current.addControl(deleteBtn);
							// 			} else {
							// 				event.sourceTarget.setStyle(layerColours.deleted);
							// 				setDrawnItemsValid(false);
							// 				mapRef.current.off('click', handleMapClick);
							// 				mapRef.current.removeControl(deleteBtn);
							// 			}

							// 			let layer = event.target;
							// 			layer.bindTooltip(
							// 				`Layer ${event.sourceTarget.feature.properties.id} (edited)`
							// 			);

							// 			setFeatureCollection((prevState) => ({
							// 				...prevState,
							// 				features: prevState.features.map((item) => {
							// 					if (
							// 						item.properties.id ===
							// 						event.sourceTarget.feature.properties.id
							// 					) {
							// 						return {
							// 							...item,
							// 							status: 'Edited',
							// 							newGeom: event.sourceTarget.feature.geometry
							// 						};
							// 					}
							// 					return item;
							// 				})
							// 			}));

							// 			setFeatureLayers((prevState) => ({
							// 				layers: prevState.layers.map((item) => {
							// 					if (
							// 						item.feature.properties.id ===
							// 						event.sourceTarget.feature.properties.id
							// 					) {
							// 						return {
							// 							...item,
							// 							feature: {
							// 								...item.feature,
							// 								status: 'Edited',
							// 								newGeom: event.sourceTarget.feature.geometry
							// 							}
							// 						};
							// 					}
							// 					return item;
							// 				})
							// 			}));
							// 		}

							// 		function enableEditing(layer) {
							// 			if (currentlyEditingLayer && currentlyEditingLayer !== layer) {
							// 				currentlyEditingLayer.editing.disable();
							// 			}

							// 			layer.editing.enable();

							// 			currentlyEditingLayer = layer;
							// 		}

							// 		function disableEditing() {
							// 			try {
							// 				if (currentlyEditingLayer) {
							// 					currentlyEditingLayer.editing.disable();

							// 					currentlyEditingLayer = null;
							// 				}
							// 			} catch (e) {
							// 				console.log(e);
							// 			}
							// 		}

							// 		function simplifyPolygon() {
							// 			var latLngArray = currentlyEditingLayer.editing.latlngs[0][0];

							// 			const coordinates = latLngArray.map((latLng) => [
							// 				latLng.lng,
							// 				latLng.lat
							// 			]);

							// 			coordinates.push(coordinates[0]);
							// 			geojson = turf.polygon([coordinates]);

							// 			var options = { tolerance: 0.00001, highQuality: true };
							// 			var simplified = turf.simplify(geojson, options);

							// 			var simplifiedCoordinates =
							// 				simplified.geometry.coordinates[0].map((coord) => [
							// 					coord[1],
							// 					coord[0]
							// 				]);

							// 			var simplifiedPolygon = L.polygon(simplifiedCoordinates, {
							// 				color: 'blue'
							// 			});
							// 			simplifiedPolygon.feature = currentlyEditingLayer.feature;

							// 			var simplifiedGeoJSON = {
							// 				type: 'Feature',
							// 				properties: currentlyEditingLayer.feature.properties,
							// 				geometry: simplified.geometry
							// 			};

							// 			var simplifiedLayer = L.geoJSON(simplifiedGeoJSON, {
							// 				style: function (feature) {
							// 					return { color: 'blue' };
							// 				}
							// 			});

							// 			var geojsonData = {
							// 				type: 'FeatureCollection',
							// 				features: [simplifiedGeoJSON]
							// 			};

							// 			var simplifiedLayers = new L.FeatureGroup();
							// 			originalLayerRef.current.removeLayer(currentlyEditingLayer);
							// 			mapRef.current.addLayer(simplifiedLayers);

							// 			// Add GeoJSON data to the editable layer
							// 			L.geoJSON(geojsonData, {
							// 				onEachFeature: function (feature, layer) {
							// 					simplifiedLayers.addLayer(layer);
							// 					feature.properties.id =
							// 						currentlyEditingLayer.feature.properties.id;
							// 					layer.setStyle(layerColours.edited);
							// 					makeLayerEditable(layer);
							// 				}
							// 			});

							// 			// simplifiedLayer.feature = currentlyEditingLayer.feature;

							// 			// drawnItems.addLayer(simplifiedPolygon);

							// 			//originalLayerRef.current.addLayer(simplifiedLayer);

							// 			// makeLayerEditable(simplifiedLayer);
							// 			// console.log(currentlyEditingLayer)
							// 			// console.log(simplifiedLayer)
							// 			// console.log(imageFeatureCollection)

							// 			setFeatureCollection((prevState) => ({
							// 				...prevState,
							// 				features: prevState.features.map((item) => {
							// 					try {
							// 						if (
							// 							item.properties.id ===
							// 							currentlyEditingLayer.feature.properties.id
							// 						) {
							// 							return {
							// 								...item,
							// 								status: 'Simplified',
							// 								newGeom: simplified.geometry
							// 							};
							// 						}
							// 					} catch (e) {
							// 						console.log(e);
							// 					}
							// 					return item;
							// 				})
							// 			}));

							// 			setFeatureLayers((prevState) => ({
							// 				layers: prevState.layers.map((item) => {
							// 					if (
							// 						item.feature.properties.id ===
							// 						currentlyEditingLayer.feature.properties.id
							// 					) {
							// 						return {
							// 							...item,
							// 							feature: {
							// 								...item.feature,
							// 								status: 'Simplified',
							// 								newGeom: simplified.geometry
							// 							}
							// 						};
							// 					}
							// 					return item;
							// 				})
							// 			}));

							// 			//disableEditing();
							// 		}

							// 		var restoreBtn = L.easyButton({
							// 			states: [
							// 				{
							// 					stateName: 'Restore deleted layer',
							// 					icon: 'fa-solid fa-rotate-left',
							// 					title: 'Restore deleted layer', // Tooltip text
							// 					onClick: function () {
							// 						// get layer
							// 						let layer = currentlyEditingLayer;
							// 						// change style back to normal/edited
							// 						layer.setStyle(layerColours.original);

							// 						layer.off('click');

							// 						// add all previous click events
							// 						layer.on('dblclick', function (event) {
							// 							L.DomEvent.stopPropagation(event); // Prevent the map click event from firing
							// 							enableEditing(layer);
							// 							deleteBtn.addTo(mapRef.current);
							// 							currentlyEditingLayer = layer;
							// 						});

							// 						// change tooltip
							// 						layer.bindTooltip(
							// 							`Layer ${layer.feature.properties.id} (restored)`
							// 						);

							// 						// change status back to edited
							// 						setFeatureCollection((prevState) => ({
							// 							...prevState,
							// 							features: prevState.features.map((item) => {
							// 								if (
							// 									item.properties.id === layer.feature.properties.id
							// 								) {
							// 									return {
							// 										...item,
							// 										status: 'Edited',
							// 										newGeom: layer.feature.geometry
							// 									};
							// 								}
							// 								return item;
							// 							})
							// 						}));

							// 						setFeatureLayers((prevState) => ({
							// 							layers: prevState.layers.map((item) => {
							// 								if (
							// 									item.feature.properties.id ===
							// 									layer.feature.properties.id
							// 								) {
							// 									return {
							// 										...item,
							// 										feature: {
							// 											...item.feature,
							// 											status: ''
							// 										}
							// 									};
							// 								}
							// 								return item;
							// 							})
							// 						}));

							// 						// remove button
							// 						disableEditing();
							// 						currentlyEditingLayer = null;
							// 						disableButton(restoreBtn);
							// 					}
							// 				}
							// 			]

							// 			// 'fa-solid fa-rotate-left',
							// 			// function () {
							// 			// 	// get layer
							// 			// 	let layer = currentlyEditingLayer;
							// 			// 	// change style back to normal/edited
							// 			// 	layer.setStyle(layerColours.original);

							// 			// 	layer.off('click');

							// 			// 	// add all previous click events
							// 			// 	layer.on('dblclick', function (event) {
							// 			// 		L.DomEvent.stopPropagation(event); // Prevent the map click event from firing
							// 			// 		enableEditing(layer);
							// 			// 		deleteBtn.addTo(mapRef.current);
							// 			// 		currentlyEditingLayer = layer;
							// 			// 	});

							// 			// 	// change tooltip
							// 			// 	layer.bindTooltip(
							// 			// 		`Layer ${layer.feature.properties.id} (restored)`
							// 			// 	);

							// 			// 	// change status back to edited
							// 			// 	setFeatureCollection((prevState) => ({
							// 			// 		...prevState,
							// 			// 		features: prevState.features.map((item) => {
							// 			// 			if (item.properties.id === layer.feature.properties.id) {
							// 			// 				return {
							// 			// 					...item,
							// 			// 					status: 'Edited',
							// 			// 					newGeom: layer.feature.geometry
							// 			// 				};
							// 			// 			}
							// 			// 			return item;
							// 			// 		})
							// 			// 	}));

							// 			// 	setFeatureLayers((prevState) => ({
							// 			// 		layers: prevState.layers.map((item) => {
							// 			// 			if (
							// 			// 				item.feature.properties.id ===
							// 			// 				layer.feature.properties.id
							// 			// 			) {
							// 			// 				return {
							// 			// 					...item,
							// 			// 					feature: {
							// 			// 						...item.feature,
							// 			// 						status: ''
							// 			// 					}
							// 			// 				};
							// 			// 			}
							// 			// 			return item;
							// 			// 		})
							// 			// 	}));

							// 			// 	// remove button
							// 			// 	disableEditing();
							// 			// 	currentlyEditingLayer = null;
							// 			// 	removeEasyButton(restoreBtn);
							// 		});

							// 		function makeLayerEditable(layer) {
							// 			layer.on('dblclick', function (event) {
							// 				currentlyEditingLayer = layer;
							// 				L.DomEvent.stopPropagation(event);
							// 				enableButton(simplifyBtn);
							// 				enableEditing(layer);
							// 				enableButton(deleteBtn);
							// 			});

							// 			layer.on('click', function (event) {
							// 				L.DomEvent.stopPropagation(event);

							// 				layer.setStyle(layerColours.selected);
							// 			});

							// 			layer.on('mouseover', () => {
							// 				layer.setStyle({ weight: 3, opacity: 0.8 });
							// 			});
							// 			layer.on('mouseout', () => {
							// 				layer.setStyle({ weight: 1, opacity: 0.5 });
							// 			});

							// 			layer.on('dragend', handleLayerEdit);
							// 			layer.on('edit', handleLayerEdit);
							// 			layer.on('update', handleLayerEdit);
							// 		}

							// 		function disableButton(button) {
							// 			button.state('disabled');
							// 			button.button.setAttribute('disabled', true);
							// 			button.button.classList.add('leaflet-disabled');
							// 			button.button.style.backgroundColor = '#f2f2f2';
							// 		}

							// 		// Function to enable the button
							// 		function enableButton(button) {
							// 			button.state('enabled');
							// 			button.button.removeAttribute('disabled');
							// 			button.button.classList.remove('leaflet-disabled');
							// 			button.button.style.backgroundColor = 'white';
							// 		}

							// 		var simplifyBtn = L.easyButton(
							// 			{
							// 				states: [
							// 					{
							// 						stateName: 'Simplify polygon',
							// 						icon: 'fa-solid fa-vector-square',
							// 						title: 'Simplify polygon', // Tooltip text
							// 						onClick: simplifyPolygon
							// 					}
							// 				]
							// 			}
							// 			// 'fa-solid fa-vector-square',
							// 			// simplifyPolygon
							// 		).addTo(mapRef.current);
							// 		disableButton(simplifyBtn);

							// 		function deleteLayer() {
							// 			let layer = currentlyEditingLayer;

							// 			layer.setStyle(layers.deleted);

							// 			// FOR WHEN LAYER HAS BEEN EDITED, THEN DELETING
							// 			try {
							// 				originalLayerRef.current
							// 					.getLayer(layer._leaflet_id)
							// 					.setStyle(layerColours.deleted);
							// 				originalLayerRef.current
							// 					.getLayer(layer._leaflet_id)
							// 					.bindTooltip(
							// 						`Layer ${layer.feature.properties.id} (deleted)`
							// 					);
							// 			} catch (e) {
							// 				console.log(e);
							// 			}

							// 			console.log(
							// 				originalLayerRef.current.getLayer(layer._leaflet_id)
							// 			);
							// 			console.log(layer);

							// 			try {
							// 				setFeatureCollection((prevState) => ({
							// 					...prevState,
							// 					features: prevState.features.map((item) => {
							// 						if (item.properties.id === layer.feature.properties.id) {
							// 							return {
							// 								...item,
							// 								status: 'Edited',
							// 								newGeom: layer.feature.geometry
							// 							};
							// 						}
							// 						return item;
							// 					})
							// 				}));

							// 				setFeatureLayers((prevState) => ({
							// 					layers: prevState.layers.map((item) => {
							// 						if (
							// 							item.feature.properties.id ===
							// 							layer.feature.properties.id
							// 						) {
							// 							return {
							// 								...item,
							// 								feature: {
							// 									...item.feature,
							// 									status: 'Deleted'
							// 								}
							// 							};
							// 						}
							// 						return item;
							// 					})
							// 				}));
							// 			} catch (e) {
							// 				console.log(e);
							// 			}

							// 			// setFeatureLayers((prevState) => ({
							// 			// 	layers: prevState.layers.map((item) => {
							// 			// 		if (
							// 			// 			item.feature.properties.id ===
							// 			// 			currentlyEditingLayer.feature.properties.id
							// 			// 		) {
							// 			// 			return {
							// 			// 				...item,
							// 			// 				feature: {
							// 			// 					...item.feature,
							// 			// 					status: 'Deleted',
							// 			// 				}

							// 			// 			};
							// 			// 		}
							// 			// 		return item;
							// 			// 	})
							// 			// }));

							// 			layer.off('dblclick');
							// 			layer.off('click');
							// 			layer.on('click', function (event) {
							// 				L.DomEvent.stopPropagation(event);

							// 				restoreBtn.addTo(mapRef.current);
							// 				currentlyEditingLayer = layer;
							// 			});

							// 			disableEditing();
							// 			currentlyEditingLayer = null;
							// 			disableButton(deleteBtn);
							// 		}

							// 		var deleteBtn = L.easyButton({
							// 			states: [
							// 				{
							// 					stateName: 'Delete layer',
							// 					icon: 'fa-solid fa-trash-can',
							// 					title: 'Delete layer', // Tooltip text
							// 					onClick: deleteLayer
							// 				}
							// 			]
							// 		}).addTo(mapRef.current);
							// 		disableButton(deleteBtn);
						} else {
							// const geojsonLayer = L.geoJSON(JSON.parse(imageData.stockpiles));
							// geojsonLayer.setStyle(layerColours.original);
							// originalLayerRef.current = geojsonLayer;
							// geojsonLayer.addTo(mapRef.current);

							if (originalLayerRef.current) {
								const drawnItems = new L.layerGroup();
								mapRef.current.addLayer(drawnItems);
								let layerIdCounter = 1;
								let layers = [];
								for (let sp in JSON.parse(imageData.data.stockpiles).features) {
									const geojsonLayer = L.geoJSON(
										JSON.parse(imageData.data.stockpiles).features[sp]
									);

									geojsonLayer.on('mouseover', () => {
										geojsonLayer.setStyle({ weight: 3, opacity: 0.8 });
									});
									geojsonLayer.on('mouseout', () => {
										geojsonLayer.setStyle({ weight: 1, opacity: 0.5 });
									});

									drawnItems.addLayer(geojsonLayer);

									geojsonLayer.setStyle(layerColours.original);
									geojsonLayer.bindTooltip(`Stockpiles ${layerIdCounter}`);
									layerIdCounter++;
								}

								originalLayerRef.current = drawnItems;
							}
						}
					}

					// Toggle layer visibility
					if (showEditedLayer) {
						mapRef.current.addLayer(originalLayerRef.current);
					} else {
						mapRef.current.removeLayer(originalLayerRef.current);
					}

					if (showImageLayer) {
						mapRef.current.addLayer(imageLayerRef.current);
					} else {
						mapRef.current.removeLayer(imageLayerRef.current);
					}

					if (showAOILayer) {
						mapRef.current.addLayer(aoiLayerRef.current);
						aoiLayerRef.current.bringToBack();
					} else {
						mapRef.current.removeLayer(aoiLayerRef.current);
					}
				} catch (e) {
					console.log(e);
				}
			} else {
			}
		}
	}, [
		showEditedLayer,
		showImageLayer,
		showAOILayer,
		// imageFeatureCollection,
		stockpilesGeojsons,
		imageData,
		currentCapture
	]);

	useEffect(() => {
		if (pipelineContextQCImage.comm === 'smelters' && imageData) {
			
			let modalIdName = `#${modalId}`;
			const mapContainerId = `map-${modalId}`;
			//var map;

			// AOI
			let aoi = JSON.parse(imageData.data.aoi);
			var geojsonFeature = {
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'Polygon',
					coordinates: aoi.coordinates[0]
				}
			};

			var aoiLayer = L.geoJSON(geojsonFeature);
			aoiLayer.setStyle({
				color: 'yellow',
				weight: 3,
				fillOpacity: 0,
				opacity: 0.5
			});
			var bounds = aoiLayer.getBounds();

			// PERMS
			let perms = [];
			if (imageData.data.perm_regions) {
				try {
					let permsJson = JSON.parse(imageData.data.perm_regions);

					for (let i in permsJson.features) {
						let geojsonPerms = {
							type: 'Feature',
							properties: {},
							geometry: {
								type: 'Polygon',
								coordinates: permsJson.features[i].geometry.coordinates
							}
						};
						let permLayer = L.geoJSON(geojsonPerms);
						permLayer.setStyle({
							color: 'orange',
							weight: 1,
							fillOpacity: 0
						});
						perms.push(permLayer);
					}
				} catch (e){
					console.log(e)
				}
				
			}

			// HOTSPOTS
			let hotspotsList = [];
			if (imageData.data.hotspots) {
				let hotspotsJson = JSON.parse(imageData.data.hotspots);

				for (let i in hotspotsJson.features) {
					let geojsonPerms = {
						type: 'Feature',
						properties: {},
						geometry: {
							type: 'Polygon',
							coordinates: hotspotsJson.features[i].geometry.coordinates
						}
					};
					let hotspotsLayer = L.geoJSON(geojsonPerms);
					hotspotsLayer.setStyle({
						color: 'red',
						weight: 1,
						fillOpacity: 1
					});
					hotspotsList.push(hotspotsLayer);
				}
			}

			// create map
			var map = L.map(mapContainerId, {
				zoomControl: false
				//maxBounds: bounds,
				//maxBoundsViscosity: 1.0
			});

			map.setMaxBounds(bounds);

			// add layers to map
			aoiLayer.addTo(map);
			if (imageData.data.perm_regions) {
				for (let i in perms) {
					perms[i].addTo(map);
				}
			}

			if (imageData.data.hotspots) {
				for (let i in hotspotsList) {
					hotspotsList[i].addTo(map);
				}
			}

			aoiLayer
				.bindTooltip('site aoi', {
					direction: 'top'
				})
				.openTooltip();

			///console.log(bounds)

			const recenterButton = L.easyButton('fa-crosshairs', function () {
				map.setView([0, 0], 13);
			}).addTo(map);

			const imgUrl = imageUrl;
			var imageBounds = [
				[imageData.data.bbox[1], imageData.data.bbox[0]],
				[imageData.data.bbox[3], imageData.data.bbox[2]]
			];

			// Add the image overlay
			const imageOverlay = L.imageOverlay(imgUrl, imageBounds).addTo(map);

			// Add a tile layer (e.g., OpenStreetMap)
			var mapLayer = L.tileLayer(
				'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
				{
					lang: 'en',
					opacity: 0.2
				}
			).addTo(map);
			mapLayer.addTo(map);

			imageOverlay.on('error', (error) => {
				console.error('Error loading image:', error);
			});

			var permGroups = L.layerGroup(perms);
			var hotspotsGroup = L.layerGroup(hotspotsList);
			var baseLayers = {
				Hotspots: hotspotsGroup,
				AOI: aoiLayer,
				'Perm Regions': permGroups
			};

			//map.setView([0, 0], 13);
			map.fitBounds(bounds, { padding: [0, 0] });

			return () => {
				map.remove();
			};
		}
	}, [imageUrl, openModal, pipelineContextQCImage.comm, imageData]);

	const toggleImageLayer = () => {
		console.log('here');
		setShowImageLayer(!showImageLayer);
	};
	const toggleEditedLayer = () => {
		setShowEditedLayer(!showEditedLayer);
	};

	const toggleAOI = () => {
		setShowAOILayer(!showAOILayer);
	};

	return (
		<div className="QCImage-map-general-div">
			<div className="QCImage-map-absolute-div">
				<div className="QCImage-leaflet-map" id={`map-${modalId}`}></div>
			</div>

			{pipelineContextQCImage.comm === 'stockpiles' && (
				<div
					style={{
						position: 'absolute',
						bottom: '5px',
						left: '10px',
						zIndex: '9000'
					}}
				>
					<fieldset
						className="form-group p-1 border border-dark border-1  rounded-3 "
						style={{ height: '100%' }}
						data-tooltip-id="QCImageMapToggleLayers"
					>
						<legend className="float-none w-auto px-1 my-0 fs-6">
							<i class="fa-solid fa-eye-slash"></i>
						</legend>
						{showImageLayer ? (
							<button
								onClick={toggleImageLayer}
								className="leaflet-map-toggle-btns-on"
								style={{ backgroundColor: 'black' }}
							>
								<p className="m-0 p-0">
									{' '}
									<i class="fa-solid fa-image"></i>
								</p>
							</button>
						) : (
							<button
								onClick={toggleImageLayer}
								className="leaflet-map-toggle-btns-off"
								style={{ backgroundColor: 'black' }}
							>
								<p className="m-0 p-0">
									{' '}
									<i class="fa-solid fa-image"></i>
								</p>
							</button>
						)}
						{showEditedLayer ? (
							<button
								onClick={toggleEditedLayer}
								className="leaflet-map-toggle-btns-on"
								style={{ backgroundColor: layerColours.edited.color }}
							>
								<p className="m-0 p-0">
									{' '}
									<i class="fa-solid fa-pen"></i>
								</p>
							</button>
						) : (
							<button
								onClick={toggleEditedLayer}
								className="leaflet-map-toggle-btns-off"
								style={{ backgroundColor: layerColours.edited.color }}
							>
								<p className="m-0 p-0">
									{' '}
									<i class="fa-solid fa-pen"></i>
								</p>
							</button>
						)}

						{showAOILayer ? (
							<button
								onClick={toggleAOI}
								className="leaflet-map-toggle-btns-on"
								style={{ backgroundColor: layerColours.created.color }}
							>
								<p className="m-0 p-0">
									{' '}
									<i class="fa-solid fa-draw-polygon"></i>
								</p>
							</button>
						) : (
							<button
								onClick={toggleAOI}
								className="leaflet-map-toggle-btns-off"
								style={{ backgroundColor: layerColours.created.color }}
							>
								<p className="m-0 p-0">
									{' '}
									<i class="fa-solid fa-draw-polygon"></i>
								</p>
							</button>
						)}
					</fieldset>
					<Tooltip id="QCImageMapToggleLayers" place="top" effect="solid">
						Toggle layers
					</Tooltip>
				</div>
			)}
		</div>
	);
};

export default QC_Image;
