import React from 'react';
import { Auth } from 'aws-amplify';

export default function Logout({props}) {

	
	
	const handleLogout = async () => {

		localStorage.removeItem('authenticatedUser')
		props.qcProps.setQcInProgress(false)
		props.qcProps.setCapturesInPipeline([])
		props.authProps.setIsAuthenticating(false)
		//props.authProps.setUserAttributes({})

		await Auth.signOut();
		props.authProps.userHasAuthenticated(false, []);
	};

	return (
		<button
			type="button"
			className="dropdown-item pointered bg-dark text-light"
			onClick={(e) =>handleLogout()}
		>
			<i className="fas fa-fw fa-sign-out-alt mr-1" /> Log out
		</button>
	);
}
