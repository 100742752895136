import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import earthiLogo from '../../images/earthi-rev.png';
import { Col } from 'reactstrap';
import { Tooltip } from 'react-tooltip';
import useGainsQCApi from '../../services/useGainsQCApi';
import CapturesTable from '../CapturesTable';

const Testing = (props) => {
	const {
		data: data1,
		loading: loading1,
		error: error1,
		makeRequest: makeRequest1
	} = useGainsQCApi();
	const {
		data: data2,
		loading: loading2,
		error: error2,
		makeRequest: makeRequest2
	} = useGainsQCApi();

	const handleButtonClick = () => {
		const url = 'https://jsonplaceholder.typicode.com/post';
		const method = 'POST';
		const body = {
			param1: 'value1',
			param2: 'value2',
			param3: 'value3'
		};

		makeRequest1(url, method, body, 'capture 3042', 2000);
	};

	const handleButtonClick2 = () => {
		const url = 'https://jsonplaceholder.typicode.com/posts';
		const method = 'GET';

		makeRequest2(url, method, null, 'sites', 3000);
	};

	let tableFields = [
		{
			header: 'ID',
			data: 'id',
			link: '/qc'
		},
		{
			header: 'Site',
			data: 'site_id'
		},
		{
			header: 'Site Name',
			data: 'name'
		},
		{
			header: 'Capture Date',
			data: 'captured'
		},
		{
			header: 'Capture Time',
			data: 'captured'
		}
	];

	let tableFields2=[
		{
			header: 'ID',
			data: 'id',
			link: '/qc'
		},
		{
			header: 'Site',
			data: 'site_id'
		},
		{
			header: 'Site Name',
			data: 'site_name'
		},
		{
			header: 'Capture Date',
			data: 'captured'
		},
		{
			header: 'Capture Time',
			data: 'captured'
		},
		{
			header: 'State',
			data: 'state'
		}
	];

	let captures = [
		{
			id: 503,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20220905_072155_ssc18_u0007',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 369197,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: '2024-07-09 11:29:32.173',
			captured: '2022-09-05 07:21:55.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 504,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20221119_104648_ssc12_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 406881,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2022-11-19 10:46:48.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 513,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231025_102547_ssc16_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 405277,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-10-25 10:25:47.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 501,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20220430_104836_ssc4_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 473551,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: '2024-07-08 12:17:46.793',
			captured: '2022-04-30 10:48:36.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 522,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20240126_144018_ssc9_u0002',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 401535,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2024-01-26 02:40:18.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 236,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'pleiades-neo-pms-fs-ort',
			stac_item_id: 'PNEO3_202306161134561_PMS-FS_ORT',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 344816,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-06-16 11:34:56.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 509,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231008_150849_ssc14_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 481231,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-10-08 03:08:49.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 519,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20240107_143122_ssc9_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 258126,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2024-01-07 02:31:22.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 508,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231007_153851_ssc15_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 617951,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-10-07 03:38:51.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 510,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231009_145233_ssc16_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 350428,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-10-09 02:52:33.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 505,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20230802_182830_ssc16_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 432428,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-08-02 06:28:30.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 506,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20230904_102627_ssc13_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 346917,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-09-04 10:26:27.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 518,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231210_135742_ssc16_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 243392,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-12-10 01:57:42.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 237,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'pleiades-neo-pms-fs-ort',
			stac_item_id: 'PNEO4_202401181137526_PMS-FS_ORT',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 586354,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2024-01-18 11:37:52.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 520,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20240109_144012_ssc10_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 115023,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2024-01-09 02:40:12.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 516,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231109_100848_ssc2_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 229795,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-11-09 10:08:48.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 507,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20230905_064516_ssc15_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 383025,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-09-05 06:45:16.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 511,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231014_153042_ssc15_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 266915,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-10-14 03:30:42.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 512,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231015_131047_ssc15_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 755438,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-10-15 01:10:47.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 514,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231027_110122_ssc15_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 450069,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-10-27 11:01:22.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 515,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231029_083653_ssc16_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 473074,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-10-29 08:36:53.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 517,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20231115_144510_ssc7_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 0,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2023-11-15 02:45:10.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		},
		{
			id: 521,
			site_id: 50003,
			name: 'Newport',
			stac_collection: 'skysat-analytic-dn-ortho',
			stac_item_id: '20240118_103610_ssc1_u0001',
			model_name: 'U-NET',
			model_version: 99109,
			origin: 'Model',
			total_pixels: 414181,
			qc_state: 3,
			qc_user: 'lucy.colley',
			state: null,
			qc_date: null,
			captured: '2024-01-18 10:36:10.000',
			table_name: 'ScrapSteelStockpilesCaptureProcessingData'
		}
	];
	let captures2 = [
		{
			id: 2253149,
			captured: '2016-01-06 03:58:28.000',
			site_id: 1515,
			sensor: 'landsat-8-l1tp',
			state: 1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0.5705238917674151,
			invalid_data_frac: 0.0,
			qc: 3,
			qc_user: 'lucy.colley',
			max_band_ratio: 1.926701545715332,
			use_smoke: 0,
			site_name: 'U.S. Steel Irvin Works',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_017032_20160106_20200907_02_T1-1515',
			initial_smoke: null,
			smoke: null,
			qc_date: null,
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2253150,
			captured: '2016-01-13 04:04:40.000',
			site_id: 1515,
			sensor: 'landsat-8-l1tp',
			state: -1,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1.0,
			invalid_data_frac: 1.0,
			qc: 3,
			qc_user: 'lucy.colley',
			max_band_ratio: -1.0,
			use_smoke: 0,
			site_name: 'U.S. Steel Irvin Works',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_018032_20160113_20200907_02_T1-1515',
			initial_smoke: null,
			smoke: null,
			qc_date: null,
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2253510,
			captured: '2016-01-15 04:16:47.000',
			site_id: 1515,
			sensor: 'sentinel-2-l1c',
			state: 0,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1.0,
			invalid_data_frac: 0.0,
			qc: 3,
			qc_user: 'lucy.colley',
			max_band_ratio: 1.0778388977050781,
			use_smoke: 0,
			site_name: 'U.S. Steel Irvin Works',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160115T213724_R097_V20160115T161647_20160115T161647-1515',
			initial_smoke: null,
			smoke: null,
			qc_date: null,
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2253151,
			captured: '2016-01-22 03:58:29.000',
			site_id: 1515,
			sensor: 'landsat-8-l1tp',
			state: null,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1.0,
			invalid_data_frac: 0.0,
			qc: 3,
			qc_user: 'lucy.colley',
			max_band_ratio: 1.1598280668258667,
			use_smoke: 0,
			site_name: 'U.S. Steel Irvin Works',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1GT_017032_20160122_20200907_02_T2-1515',
			initial_smoke: null,
			smoke: null,
			qc_date: null,
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2253501,
			captured: '2016-01-28 04:15:26.000',
			site_id: 1515,
			sensor: 'sentinel-2-l1c',
			state: null,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 1.0,
			invalid_data_frac: 0.0,
			qc: 3,
			qc_user: 'lucy.colley',
			max_band_ratio: 0.8179083466529846,
			use_smoke: 0,
			site_name: 'U.S. Steel Irvin Works',
			end_date: null,
			expected_state: null,
			stac_item_id:
				'S2A_OPER_PRD_MSIL1C_PDMC_20160129T004132_R140_V20160128T161526_20160128T161526-1515',
			initial_smoke: null,
			smoke: null,
			qc_date: null,
			table_name: 'SteelCaptureProcessingData'
		},
		{
			id: 2253152,
			captured: '2016-02-07 03:58:25.000',
			site_id: 1515,
			sensor: 'landsat-8-l1tp',
			state: null,
			initial_state: -1,
			hotspots: 0,
			cloud_frac: 0.8767990788716177,
			invalid_data_frac: 0.0,
			qc: 3,
			qc_user: 'lucy.colley',
			max_band_ratio: 1.233095645904541,
			use_smoke: 0,
			site_name: 'U.S. Steel Irvin Works',
			end_date: null,
			expected_state: null,
			stac_item_id: 'LC08_L1TP_017032_20160207_20200907_02_T1-1515',
			initial_smoke: null,
			smoke: null,
			qc_date: null,
			table_name: 'SteelCaptureProcessingData'
		}
	];

	return (
		<>
			<Col className="px-0">
				<div className="contentContainer" id="dashboard-container">
					<div style={{ marginTop: '68px' }}>
						<hr></hr>
						<h1>Testing</h1>

						<CapturesTable
							fields={tableFields2}
							captures={captures2}
							qcCat={'smelters'}
						/>

						{/*<button
							onClick={handleButtonClick}
							disabled={loading1}
							className="interactButton"
						>
							{loading1 ? 'Posting...' : 'Post Data'}
						</button>

						<button
							onClick={handleButtonClick2}
							disabled={loading2}
							className="interactButton"
						>
							{loading2 ? 'Fetching...' : 'Fetch Data'}
						</button>

						 {error1 && <div>Error: {error1}</div>}
						{data1 && (
							<div>
								<h1>Data</h1>
								<pre>Data posted</pre>
							</div>
						)}

						
						{error2 && <div>Error: {error2}</div>}
						{data2 && (
							<div>
								<h1>Data</h1>
								<pre>Data fetched</pre>
							</div>
						)} */}
					</div>
				</div>
			</Col>
		</>
	);
};

export default Testing;
