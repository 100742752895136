const stockpiles = {
	type: 'FeatureCollection',
	crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
	features: [
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.033163439806085, 43.343292264279917],
						[-3.033163437355362, 43.343287762006533],
						[-3.033175774941633, 43.343287758428566],
						[-3.033175772489999, 43.343283256155168],
						[-3.033188110075356, 43.343283252575866],
						[-3.033188107622811, 43.343278750302481],
						[-3.033194276415033, 43.343278748512333],
						[-3.033194273962032, 43.343274246238934],
						[-3.033218949129091, 43.343274239075022],
						[-3.033218951583915, 43.343278741348406],
						[-3.033225120376133, 43.343278739556602],
						[-3.033225110555017, 43.343260730462994],
						[-3.033231279345412, 43.343260728670842],
						[-3.033231276889679, 43.34325622639745],
						[-3.033243614469555, 43.343256222812158],
						[-3.03324361201291, 43.343251720538738],
						[-3.033255949591873, 43.343251716952125],
						[-3.033255944676762, 43.343242712405299],
						[-3.033262113465331, 43.343242710611484],
						[-3.03326210854931, 43.34323370606463],
						[-3.033268277336966, 43.343233704270496],
						[-3.033268274878501, 43.343229201997069],
						[-3.033280612452901, 43.343229198407791],
						[-3.033280619831034, 43.343242705228072],
						[-3.033299126196732, 43.34324269984166],
						[-3.033299128657478, 43.343247202115073],
						[-3.033305297446498, 43.343247200318942],
						[-3.033305294985296, 43.343242698045522],
						[-3.033311463773861, 43.343242696249064],
						[-3.033311449003924, 43.343215682608481],
						[-3.033255929931442, 43.343215698764702],
						[-3.033255927473891, 43.343211196491254],
						[-3.033144889336994, 43.343211228722836],
						[-3.03314489178634, 43.343215730996292],
						[-3.033163398143869, 43.34321572563185],
						[-3.033163400594583, 43.343220227905299],
						[-3.033157231808285, 43.34322022969377],
						[-3.033157236708803, 43.343229234240646],
						[-3.033151067921593, 43.34322923602879],
						[-3.033151072821201, 43.343238240575666],
						[-3.033144904033078, 43.343238242363483],
						[-3.033144908931777, 43.343247246910337],
						[-3.033138740142741, 43.343247248697821],
						[-3.033138745040531, 43.343256253244654],
						[-3.033132576250583, 43.343256255031811],
						[-3.033132588492788, 43.343278766398846],
						[-3.033138757285015, 43.343278764611689],
						[-3.033138759733914, 43.343283266885081],
						[-3.033144928526596, 43.343283265097597],
						[-3.033144930975951, 43.343287767370995],
						[-3.033157268562225, 43.343287763795018],
						[-3.033157271012493, 43.34329226606841],
						[-3.033163439806085, 43.343292264279917]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.033311436695659, 43.343193171241239],
						[-3.033311434234008, 43.343188668967784],
						[-3.033280590318533, 43.343188677946777],
						[-3.033280587859161, 43.343184175673315],
						[-3.033255912728594, 43.343184182850521],
						[-3.033255910271047, 43.343179680577052],
						[-3.033243572706672, 43.343179684163658],
						[-3.033243575163307, 43.343184186437142],
						[-3.033237406380663, 43.343184188229927],
						[-3.033237408836844, 43.343188690503403],
						[-3.033212733704437, 43.343188697671287],
						[-3.033212736158795, 43.343193199944757],
						[-3.033311436695659, 43.343193171241239]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.033570604990054, 43.343337168237717],
						[-3.033570595066795, 43.343319159144365],
						[-3.033576763863084, 43.343319157333582],
						[-3.033576761381815, 43.34331465506024],
						[-3.033601436565143, 43.343314647813791],
						[-3.033601434082051, 43.34331014554045],
						[-3.033607602877425, 43.343310143728004],
						[-3.033607600393878, 43.343305641454663],
						[-3.033613769188796, 43.343305639641876],
						[-3.033613766704793, 43.343301137368513],
						[-3.033626104293717, 43.343301133741981],
						[-3.033626101808802, 43.343296631468618],
						[-3.033638439396812, 43.343296627840743],
						[-3.033638436910987, 43.34329212556738],
						[-3.033650774498081, 43.343292121938177],
						[-3.033650772011345, 43.343287619664807],
						[-3.033656940804436, 43.343287617849711],
						[-3.033656935830054, 43.343278613302964],
						[-3.033663104622232, 43.343278611487534],
						[-3.03366309467165, 43.343260602394004],
						[-3.033656925881295, 43.343260604209441],
						[-3.033656923394107, 43.343256101936049],
						[-3.033650754604208, 43.343256103751145],
						[-3.033650752117476, 43.343251601477746],
						[-3.033644583328031, 43.343251603292508],
						[-3.033644580841756, 43.343247101019124],
						[-3.033632243263777, 43.343247104647659],
						[-3.033632238293052, 43.343238100100855],
						[-3.033626069504973, 43.343238101914615],
						[-3.033626064535162, 43.343229097367789],
						[-3.033613726960826, 43.343229100994336],
						[-3.033613724476833, 43.343224598720916],
						[-3.03360755569012, 43.343224600533688],
						[-3.033607548239512, 43.343211093713407],
						[-3.033589041883473, 43.343211099149727],
						[-3.033589039401306, 43.343206596876286],
						[-3.033576701831522, 43.343206600498831],
						[-3.033576699350267, 43.343202098225397],
						[-3.033508842721429, 43.343202118125596],
						[-3.033508840245189, 43.343197615852141],
						[-3.033477996325247, 43.343197624884375],
						[-3.033477991377327, 43.343188620337486],
						[-3.03345948502809, 43.343188625752823],
						[-3.033459482555498, 43.343184123479368],
						[-3.033496495251233, 43.343184112645687],
						[-3.033496492775907, 43.343179610372225],
						[-3.033471817647243, 43.343179617596007],
						[-3.033471815173741, 43.343175115322545],
						[-3.033410127356601, 43.343175133358713],
						[-3.033410129825544, 43.343179635632183],
						[-3.033385454696848, 43.343179642837335],
						[-3.033385457163969, 43.343184145110797],
						[-3.033366950816073, 43.343184150511171],
						[-3.033366953281827, 43.343188652784626],
						[-3.033360784498738, 43.34318865458409],
						[-3.033360786964036, 43.343193156857545],
						[-3.033366955747581, 43.343193155058081],
						[-3.033366960679091, 43.343202159604992],
						[-3.033360791894634, 43.343202161404449],
						[-3.033360796825235, 43.343211165951338],
						[-3.033348459254497, 43.343211169549264],
						[-3.033348461718886, 43.343215671822705],
						[-3.033329955361407, 43.343215677217088],
						[-3.03332995782443, 43.34322017949053],
						[-3.033342295396995, 43.343220175894601],
						[-3.033342300324864, 43.343229180441462],
						[-3.033348469112058, 43.343229178643],
						[-3.033348471576449, 43.34323368091642],
						[-3.033354640364098, 43.343233679117624],
						[-3.033354647758643, 43.343247185937877],
						[-3.033348478969627, 43.343247187736679],
						[-3.033348488827206, 43.343265196830295],
						[-3.033360826408884, 43.343265193232369],
						[-3.03336083380481, 43.343278700052551],
						[-3.033367002597016, 43.343278698253087],
						[-3.033367005062781, 43.343283200526479],
						[-3.033360836270119, 43.343283202325935],
						[-3.033360838735429, 43.343287704599327],
						[-3.033367007528547, 43.343287702799863],
						[-3.033367012460081, 43.34329670734661],
						[-3.03337318125411, 43.343296705546805],
						[-3.033373186186557, 43.343305710093546],
						[-3.033379354981498, 43.343305708293421],
						[-3.033379357448178, 43.343310210566784],
						[-3.033385526243573, 43.343310208766312],
						[-3.033385531177848, 43.343319213313038],
						[-3.033410206363071, 43.34331920610785],
						[-3.033410208832032, 43.343323708381199],
						[-3.033422546425552, 43.343323704776608],
						[-3.033422543955679, 43.343319202503253],
						[-3.033441050344586, 43.343319197093876],
						[-3.033441052815827, 43.343323699367232],
						[-3.033465728002852, 43.343323692150058],
						[-3.033465725529788, 43.343319189876702],
						[-3.033471894326087, 43.343319188071582],
						[-3.033471896799607, 43.343323690344938],
						[-3.033478065596362, 43.343323688539485],
						[-3.033478063122386, 43.343319186266136],
						[-3.033484231918683, 43.343319184460341],
						[-3.033484236867548, 43.343328189007039],
						[-3.033490405664757, 43.343328187200925],
						[-3.033490408139646, 43.343332689474252],
						[-3.03349657693731, 43.343332687667797],
						[-3.033496579412656, 43.343337189941131],
						[-3.033552098595712, 43.343337173668068],
						[-3.033552096116265, 43.343332671394734],
						[-3.033564433711581, 43.343332667774838],
						[-3.033564436191941, 43.343337170048173],
						[-3.033570604990054, 43.343337168237717]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.034138013322422, 43.343120891130432],
						[-3.034138008276968, 43.343111886583472],
						[-3.034144177052234, 43.343111884742093],
						[-3.034144174529052, 43.343107382468617],
						[-3.034156512078672, 43.343107378784865],
						[-3.034156509554579, 43.343102876511374],
						[-3.034162678328931, 43.343102874669007],
						[-3.034162673279835, 43.343093870122019],
						[-3.034150335732952, 43.343093873806438],
						[-3.034150338256588, 43.343098376079936],
						[-3.034131831934892, 43.343098381604051],
						[-3.034131834457161, 43.343102883877542],
						[-3.034125665682804, 43.34310288571826],
						[-3.034125668204618, 43.343107387991743],
						[-3.034119499429806, 43.34310738983212],
						[-3.034119501951165, 43.343111892105611],
						[-3.034125670726433, 43.34311189026522],
						[-3.034125675770063, 43.343120894812188],
						[-3.034138013322422, 43.343120891130432]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.036944787310676, 43.34308850302714],
						[-3.036944784580538, 43.34308400075377],
						[-3.036938615808281, 43.343084002746195],
						[-3.036938613078599, 43.343079500472832],
						[-3.036920106763195, 43.343079506448092],
						[-3.036920112219826, 43.343088510994825],
						[-3.036944787310676, 43.34308850302714]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.036864590541162, 43.34308402662932],
						[-3.03686458781695, 43.343079524355943],
						[-3.036858419045142, 43.343079526344049],
						[-3.036858416321386, 43.343075024070664],
						[-3.036852247550033, 43.343075026058429],
						[-3.036852244826734, 43.343070523785045],
						[-3.036846076055836, 43.343070525772475],
						[-3.03684606788731, 43.343057018952308],
						[-3.03685223665684, 43.343057016964877],
						[-3.036852225763658, 43.343039007871269],
						[-3.036839888228243, 43.343039011845789],
						[-3.036839882783479, 43.343030007298978],
						[-3.036833714016683, 43.343030009285741],
						[-3.036833708572833, 43.343021004738894],
						[-3.036821371041062, 43.343021008711418],
						[-3.03682136832005, 43.343016506437991],
						[-3.036815199554619, 43.343016508423759],
						[-3.036815202275176, 43.343021010697186],
						[-3.036809033509289, 43.343021012682613],
						[-3.03680903622939, 43.34302551495604],
						[-3.036802867463046, 43.34302551694114],
						[-3.036802872902339, 43.343034521487979],
						[-3.036796704135083, 43.343034523472745],
						[-3.036796706854274, 43.343039025746158],
						[-3.036802875621986, 43.343039023761385],
						[-3.036802883780932, 43.343052530581609],
						[-3.03680905255001, 43.343052528596502],
						[-3.036809055270116, 43.343057030869907],
						[-3.03681522403965, 43.343057028884466],
						[-3.036815237642467, 43.343079540251402],
						[-3.036846081501523, 43.343079530319237],
						[-3.036846084224368, 43.343084032592614],
						[-3.036864590541162, 43.34308402662932]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.037098968246827, 43.343025421281077],
						[-3.037098962763774, 43.343016416734265],
						[-3.037105131529175, 43.343016414733185],
						[-3.037105126045214, 43.343007410186353],
						[-3.037111294809703, 43.343007408184953],
						[-3.037111289324833, 43.342998403638106],
						[-3.03711745808841, 43.342998401636372],
						[-3.03711745260263, 43.342989397089518],
						[-3.037123621365294, 43.342989395087436],
						[-3.037123615878605, 43.342980390540575],
						[-3.037117447116852, 43.342980392542636],
						[-3.037117444373965, 43.342975890269194],
						[-3.037105106851369, 43.342975894272328],
						[-3.037105109593345, 43.342980396545762],
						[-3.03709894083159, 43.342980398546835],
						[-3.037098943573111, 43.342984900820269],
						[-3.0370927748109, 43.342984902821009],
						[-3.037092777551965, 43.342989405094436],
						[-3.037086608789298, 43.342989407094841],
						[-3.037086627973583, 43.343020923008801],
						[-3.037092796739442, 43.343020921008403],
						[-3.037092799480513, 43.343025423281809],
						[-3.037098968246827, 43.343025421281077]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.036111906919792, 43.342926687150019],
						[-3.036111904251216, 43.342922184876471],
						[-3.036124241763072, 43.342922180980487],
						[-3.036124239093585, 43.342917678706939],
						[-3.036130407849057, 43.342917676758454],
						[-3.036130399839232, 43.34290416993781],
						[-3.036124231085128, 43.342904171886303],
						[-3.03612422574616, 43.342895167339201],
						[-3.036111888239774, 43.342895171235185],
						[-3.036111885571203, 43.342890668961616],
						[-3.036093379312986, 43.342890674803108],
						[-3.03609338198019, 43.342895177076663],
						[-3.036087213226994, 43.342895179023159],
						[-3.036087215893743, 43.342899681296728],
						[-3.03608104714009, 43.342899683242884],
						[-3.036081057805268, 43.342917692337089],
						[-3.036087226560743, 43.342917690390919],
						[-3.036087229227495, 43.34292219266446],
						[-3.036105735495287, 43.342922186823969],
						[-3.036105738163406, 43.342926689097517],
						[-3.036111906919792, 43.342926687150019]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.032527804074103, 43.342824210291887],
						[-3.03252779926668, 43.342815205744309],
						[-3.032515461776022, 43.342815209252343],
						[-3.032515459373223, 43.342810706978554],
						[-3.032496953138598, 43.342810712238119],
						[-3.032496960342893, 43.342824219059501],
						[-3.032527804074103, 43.342824210291887]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.036389484821954, 43.342899585527107],
						[-3.036389482132869, 43.342895083253566],
						[-3.036407988392364, 43.342895077364176],
						[-3.036407985701911, 43.342890575090628],
						[-3.036420323207326, 43.342890571162712],
						[-3.036420320515963, 43.34288606888915],
						[-3.036432658020463, 43.342886064959906],
						[-3.036432655328189, 43.342881562686344],
						[-3.036444992831776, 43.342881558755757],
						[-3.03644499013859, 43.342877056482202],
						[-3.036457327641262, 43.342877052550286],
						[-3.036457324947166, 43.342872550276731],
						[-3.036463493698045, 43.342872548310268],
						[-3.036463488308942, 43.342863543763144],
						[-3.036469657058908, 43.342863541796348],
						[-3.036469654363902, 43.342859039522786],
						[-3.036475823113413, 43.342859037555655],
						[-3.036475820417951, 43.342854535282086],
						[-3.036481989167005, 43.342854533314636],
						[-3.036481986471088, 43.342850031041053],
						[-3.036488155219686, 43.342850029073276],
						[-3.036488152523313, 43.342845526799692],
						[-3.036494321271455, 43.342845524831567],
						[-3.036494318574627, 43.342841022557991],
						[-3.036500487322312, 43.342841020589546],
						[-3.036500484625029, 43.342836518315956],
						[-3.036506653372257, 43.342836516347184],
						[-3.036506650674519, 43.342832014073579],
						[-3.036512819421291, 43.342832012104466],
						[-3.036512816723098, 43.342827509830869],
						[-3.036518985469413, 43.342827507861429],
						[-3.036518982770765, 43.342823005587832],
						[-3.03653748900834, 43.342822999677495],
						[-3.036537491708356, 43.342827501951085],
						[-3.036543660454668, 43.342827499980295],
						[-3.036543665855615, 43.342836504527483],
						[-3.036549834602838, 43.342836502556366],
						[-3.036549840004698, 43.342845507103526],
						[-3.036556008752833, 43.342845505132075],
						[-3.03655601145422, 43.342850007405659],
						[-3.03656218020281, 43.342850005433881],
						[-3.036562182904654, 43.342854507707457],
						[-3.036568351653699, 43.342854505735346],
						[-3.036568354355999, 43.342859008008922],
						[-3.036574523105499, 43.342859006036463],
						[-3.036574525808255, 43.342863508310032],
						[-3.036593032058121, 43.342863502390692],
						[-3.036593034762246, 43.342868004664254],
						[-3.036605372263065, 43.342868000716365],
						[-3.036605374968102, 43.34287250298992],
						[-3.03661771246983, 43.342872499040702],
						[-3.036617715175779, 43.342877001314257],
						[-3.036630052678417, 43.34287699736371],
						[-3.036630055385278, 43.342881499637251],
						[-3.036648561640598, 43.342881493708923],
						[-3.036648564348827, 43.342885995982471],
						[-3.036660901853282, 43.342885992028599],
						[-3.036660904562424, 43.342890494302139],
						[-3.036685579573149, 43.342890486390388],
						[-3.036685582284115, 43.342894988663922],
						[-3.036728763556052, 43.342894974805546],
						[-3.036728760841895, 43.342890472532027],
						[-3.036753435852593, 43.342890464605631],
						[-3.036753433136613, 43.34288596233209],
						[-3.036765770641046, 43.342885958366907],
						[-3.036765767924156, 43.342881456093366],
						[-3.036778105427675, 43.342881452126861],
						[-3.036778102709873, 43.342876949853327],
						[-3.036784271461176, 43.342876947869556],
						[-3.036784268742919, 43.342872445596022],
						[-3.036790437493766, 43.342872443611931],
						[-3.036790434775053, 43.342867941338369],
						[-3.036796603525443, 43.342867939353958],
						[-3.036796587210448, 43.342840925712608],
						[-3.036802755958101, 43.342840923727856],
						[-3.03680274236001, 43.342818412359961],
						[-3.036796573614635, 43.34281841434472],
						[-3.036796568176314, 43.342809409797539],
						[-3.036784230687386, 43.342809413766048],
						[-3.036784227969139, 43.342804911492458],
						[-3.03677805922513, 43.342804913476208],
						[-3.036778056507338, 43.34280041120261],
						[-3.036771887763784, 43.342800413186026],
						[-3.03677188504645, 43.342795910912422],
						[-3.036765716303351, 43.342795912895511],
						[-3.036765713586473, 43.342791410621906],
						[-3.036759544843829, 43.342791412604655],
						[-3.036759542127407, 43.34278691033105],
						[-3.03674720464303, 43.342786914295566],
						[-3.036747201927521, 43.342782412021947],
						[-3.036741033185787, 43.3427824140037],
						[-3.036741030470734, 43.342777911730082],
						[-3.036734861729455, 43.342777913711501],
						[-3.036734859014859, 43.342773411437889],
						[-3.036728690274036, 43.342773413418975],
						[-3.036728687559896, 43.342768911145349],
						[-3.036722518819528, 43.342768913126108],
						[-3.036722516105844, 43.342764410852467],
						[-3.036704009886104, 43.34276441679274],
						[-3.036704007173789, 43.342759914519107],
						[-3.03669783843433, 43.342759916498522],
						[-3.036697835722471, 43.342755414224897],
						[-3.036685498244463, 43.342755418182747],
						[-3.036685495533517, 43.3427509159091],
						[-3.036673158056418, 43.34275091986563],
						[-3.036673155346384, 43.342746417591968],
						[-3.036654649132099, 43.342746423524268],
						[-3.036654646423433, 43.342741921250607],
						[-3.036629971472867, 43.342741929155657],
						[-3.036629968766025, 43.34273742688201],
						[-3.036605293817273, 43.342737434781739],
						[-3.036605291112255, 43.342732932508085],
						[-3.036592953638787, 43.342732936455938],
						[-3.036592950934681, 43.342728434182277],
						[-3.036586782198402, 43.342728436155703],
						[-3.036586779494753, 43.342723933882041],
						[-3.036574442023104, 43.342723937827905],
						[-3.036574439320368, 43.342719435554244],
						[-3.036568270584998, 43.342719437526675],
						[-3.036568267882718, 43.342714935252999],
						[-3.036543592943058, 43.342714943139413],
						[-3.036543595643515, 43.342719445413088],
						[-3.036531258172769, 43.342719449354298],
						[-3.036531260872315, 43.342723951627974],
						[-3.036512754664825, 43.342723957537295],
						[-3.036512757363004, 43.342728459810964],
						[-3.03646957620898, 43.342728473587748],
						[-3.036469578903968, 43.342732975861423],
						[-3.036444903956975, 43.342732983726542],
						[-3.036444906650141, 43.342737486000217],
						[-3.036426400438521, 43.342737491895562],
						[-3.03642640313032, 43.342741994169231],
						[-3.036414065654992, 43.342741998097807],
						[-3.03641406834588, 43.342746500371469],
						[-3.036407899607759, 43.342746502335245],
						[-3.036407902298192, 43.342751004608921],
						[-3.036395564821038, 43.342751008535501],
						[-3.036395567510559, 43.342755510809162],
						[-3.03638323003249, 43.342755514734399],
						[-3.0363832327211, 43.342760017008054],
						[-3.036377063981609, 43.342760018970175],
						[-3.036377066669764, 43.342764521243822],
						[-3.036364729189868, 43.342764525167077],
						[-3.036364731877112, 43.342769027440731],
						[-3.036358563136707, 43.342769029401865],
						[-3.036358565823496, 43.342773531675505],
						[-3.036352397082635, 43.342773533636297],
						[-3.036352399768969, 43.342778035909937],
						[-3.036346231027651, 43.342778037870403],
						[-3.036346239085288, 43.342791544691309],
						[-3.036340070342602, 43.342791546651434],
						[-3.03634008376973, 43.342814058019535],
						[-3.036346252514696, 43.342814056059403],
						[-3.03634627400178, 43.342850074248197],
						[-3.036340105253168, 43.342850076208329],
						[-3.036340110624034, 43.342859080755488],
						[-3.036333941874509, 43.342859082715293],
						[-3.036333944559487, 43.342863584988862],
						[-3.036327775809506, 43.342863586948347],
						[-3.036327789232126, 43.342886098316164],
						[-3.036333957984386, 43.342886096356686],
						[-3.036333960669368, 43.342890598630241],
						[-3.036340129422083, 43.342890596670443],
						[-3.036340132107521, 43.342895098943984],
						[-3.036352469613862, 43.342895095023373],
						[-3.036352472300212, 43.34289959729692],
						[-3.036389484821954, 43.342899585527107]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.035957698638704, 43.342944744831762],
						[-3.035957695981522, 43.342940242558235],
						[-3.035970033497057, 43.342940238678871],
						[-3.035970030838963, 43.342935736405337],
						[-3.035988537110893, 43.342935730583804],
						[-3.035988531791973, 43.342926726036737],
						[-3.035982363035576, 43.342926727977584],
						[-3.035982360376573, 43.342922225704037],
						[-3.035976191620631, 43.342922227644543],
						[-3.035976188962084, 43.342917725370995],
						[-3.035970020206598, 43.342917727311168],
						[-3.035970017548508, 43.342913225037627],
						[-3.03603170509879, 43.342913205620889],
						[-3.036031713086738, 43.342926712441518],
						[-3.036025544330345, 43.342926714384696],
						[-3.03602555231693, 43.342940221205289],
						[-3.036031721074691, 43.342940219262125],
						[-3.036031718412039, 43.342935716988585],
						[-3.03605022468395, 43.342935711157068],
						[-3.036050216691896, 43.34292220433646],
						[-3.03604404793596, 43.342922206280633],
						[-3.03604404260884, 43.342913201733545],
						[-3.036037873853815, 43.342913203677384],
						[-3.036037868527609, 43.342904199130281],
						[-3.036031699773495, 43.342904201073786],
						[-3.036031686460269, 43.342881689705955],
						[-3.036037855212102, 43.342881687762457],
						[-3.036037852549003, 43.342877185488881],
						[-3.036044021300381, 43.342877183545042],
						[-3.036044018636825, 43.342872681271473],
						[-3.036050187387747, 43.342872679327307],
						[-3.036050184723737, 43.34286817705371],
						[-3.036056353474201, 43.342868175109217],
						[-3.036056345480808, 43.34285466828846],
						[-3.036050176731711, 43.342854670232953],
						[-3.036050174067704, 43.342850167959348],
						[-3.036044005319062, 43.342850169903528],
						[-3.036043997328411, 43.342836663082707],
						[-3.036050166075685, 43.342836661138541],
						[-3.036050160747676, 43.342827656591318],
						[-3.036062498240399, 43.342827652701992],
						[-3.036062495575484, 43.342823150428373],
						[-3.036081001813196, 43.342823144591897],
						[-3.036080996480631, 43.342814140044645],
						[-3.036093333970614, 43.342814136152001],
						[-3.036093336637807, 43.342818638425619],
						[-3.036105674128698, 43.342818634531639],
						[-3.036105676796804, 43.342823136805258],
						[-3.036118014288604, 43.342823132909949],
						[-3.036118016957622, 43.342827635183568],
						[-3.036124185703977, 43.342827633235409],
						[-3.036124183034503, 43.342823130961797],
						[-3.036130351780402, 43.342823129013311],
						[-3.036130343770616, 43.342809622192433],
						[-3.036136512515146, 43.342809620243614],
						[-3.036136509844763, 43.342805117969988],
						[-3.036142678588837, 43.342805116020841],
						[-3.036142675917999, 43.342800613747208],
						[-3.036148844661616, 43.342800611797728],
						[-3.036148847332911, 43.342805114071354],
						[-3.036155016076983, 43.342805112121539],
						[-3.036155018748734, 43.342809614395172],
						[-3.036161187493262, 43.342809612445016],
						[-3.036161192837677, 43.342818616992261],
						[-3.036167361583116, 43.342818615041779],
						[-3.036167374946444, 43.342841126409816],
						[-3.036173543694161, 43.342841124459],
						[-3.036173546367285, 43.342845626732597],
						[-3.036167377619111, 43.342845628683413],
						[-3.036167385637117, 43.342859135504192],
						[-3.036161216887575, 43.342859137454688],
						[-3.036161219559789, 43.342863639728265],
						[-3.036155050809791, 43.34286364167842],
						[-3.036155056153309, 43.342872646225572],
						[-3.036148887402399, 43.342872648175401],
						[-3.036148900758926, 43.342895159543232],
						[-3.036155069512116, 43.342895157593397],
						[-3.036155072183878, 43.342899659866958],
						[-3.036161240937524, 43.342899657916803],
						[-3.036161243609743, 43.342904160190358],
						[-3.036173581117943, 43.342904156289038],
						[-3.036173583791075, 43.342908658562592],
						[-3.03617975254563, 43.342908656611435],
						[-3.036179749872042, 43.34290415433788],
						[-3.036204424888432, 43.342904146529932],
						[-3.036204422213021, 43.342899644256399],
						[-3.036222928473939, 43.342899638396936],
						[-3.036222925797162, 43.342895136123381],
						[-3.036241432056707, 43.342895130260942],
						[-3.036241429378563, 43.342890627987387],
						[-3.036259935636735, 43.342890622121942],
						[-3.036259932957224, 43.34288611984838],
						[-3.036272270461757, 43.342886115936423],
						[-3.036272267781334, 43.342881613662868],
						[-3.036278436533144, 43.342881611706389],
						[-3.036278423128762, 43.342859100338536],
						[-3.036241410631569, 43.342859112072396],
						[-3.03624140795343, 43.342854609798827],
						[-3.036235239204351, 43.342854611753303],
						[-3.036235233848986, 43.342845607206108],
						[-3.036229065100819, 43.342845609160257],
						[-3.036229062423593, 43.342841106886659],
						[-3.03622289367588, 43.342841108840474],
						[-3.036222888322342, 43.342832104293279],
						[-3.036216719575541, 43.34283210624676],
						[-3.036216706193986, 43.342809594878695],
						[-3.036210537449463, 43.342809596831842],
						[-3.036210532097757, 43.34280059228459],
						[-3.036204363354146, 43.342800594237396],
						[-3.036204355327959, 43.342787087416497],
						[-3.036198186585715, 43.342787089368969],
						[-3.036198183910776, 43.342782587095321],
						[-3.036192015168986, 43.342782589047474],
						[-3.036192009820023, 43.342773584500172],
						[-3.036185841079145, 43.34277358645199],
						[-3.036185835731095, 43.342764581904682],
						[-3.036179666991128, 43.342764583856152],
						[-3.036179661643993, 43.342755579308822],
						[-3.036173492904937, 43.342755581259972],
						[-3.036173482212498, 43.342737572165277],
						[-3.036167313475264, 43.342737574116086],
						[-3.03616730812996, 43.34272856956872],
						[-3.036161139393637, 43.342728571519189],
						[-3.036161136721442, 43.342724069245506],
						[-3.036154967985575, 43.342724071195647],
						[-3.036154965313836, 43.342719568921964],
						[-3.036148796578425, 43.342719570871779],
						[-3.036148793907142, 43.342715068598075],
						[-3.036117950232355, 43.342715078342138],
						[-3.036117947563352, 43.342710576068434],
						[-3.036111778828849, 43.342710578016245],
						[-3.036111776160302, 43.342706075742541],
						[-3.036105607426254, 43.342706077690025],
						[-3.036105610094344, 43.342710579963729],
						[-3.036093272625334, 43.342710583857695],
						[-3.036093275292513, 43.342715086131406],
						[-3.036087106557552, 43.342715088077888],
						[-3.036087109224276, 43.342719590351592],
						[-3.036074771753438, 43.342719594243562],
						[-3.036074777085065, 43.342728598790949],
						[-3.036062439612402, 43.342728602681596],
						[-3.036062442277304, 43.342733104955286],
						[-3.036050104803727, 43.342733108844612],
						[-3.036050107467718, 43.342737611118288],
						[-3.03603160125598, 43.342737616949755],
						[-3.036031683797625, 43.342877187432379],
						[-3.036019346294867, 43.342877191318387],
						[-3.036019343633136, 43.34287268904481],
						[-3.035994668629436, 43.342872696812819],
						[-3.035994665969528, 43.342868194539228],
						[-3.035988497219058, 43.342868196480403],
						[-3.03598848392181, 43.342845685112422],
						[-3.035982315173618, 43.342845687053263],
						[-3.035982317832611, 43.342850189326874],
						[-3.035976149083962, 43.342850191267367],
						[-3.035976151742499, 43.342854693540978],
						[-3.035969982993394, 43.342854695481144],
						[-3.035969985651477, 43.342859197754741],
						[-3.035963816901915, 43.34285919969458],
						[-3.035963819559542, 43.342863701968184],
						[-3.035957650809524, 43.342863703907675],
						[-3.035957656123867, 43.342872708454856],
						[-3.035951487372937, 43.34287271039404],
						[-3.035951497999808, 43.342890719488345],
						[-3.035945329247054, 43.342890721427189],
						[-3.035945334559581, 43.34289972597432],
						[-3.035939165805915, 43.34289972791283],
						[-3.035939173773343, 43.342913234733516],
						[-3.035933005018308, 43.342913236671698],
						[-3.035933007673663, 43.342917738945246],
						[-3.035939176429154, 43.34291773700707],
						[-3.035939189708216, 43.342940248374781],
						[-3.035951527223754, 43.342940244497413],
						[-3.03595152988048, 43.342944746770932],
						[-3.035957698638704, 43.342944744831762]
					],
					[
						[-3.03610563143909, 43.342746598153262],
						[-3.036080956486479, 43.34274660593988],
						[-3.036080951153936, 43.342737601392521],
						[-3.036087119891178, 43.342737599446366],
						[-3.036087117224452, 43.342733097172676],
						[-3.036124129645154, 43.342733085488767],
						[-3.036124132314615, 43.342737587762457],
						[-3.036117963577377, 43.342737589710595],
						[-3.036117966246384, 43.342742091984285],
						[-3.036105628770994, 43.342742095879579],
						[-3.03610563143909, 43.342746598153262]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.036315365852281, 43.342742029478472],
						[-3.03631535243432, 43.34271951811008],
						[-3.036321521169715, 43.342719516151284],
						[-3.036321513117577, 43.342706009330193],
						[-3.036327681851604, 43.342706007371078],
						[-3.036327676482604, 43.342697002823662],
						[-3.036321507749489, 43.342697004782785],
						[-3.036321505065446, 43.342692502509081],
						[-3.036315336332786, 43.342692504467884],
						[-3.036315333649199, 43.342688002194173],
						[-3.036309164916994, 43.342688004152635],
						[-3.036309159550735, 43.342678999605191],
						[-3.036302990819442, 43.342679001563319],
						[-3.036302988136768, 43.3426744992896],
						[-3.036278313213412, 43.342674507118772],
						[-3.036278310532563, 43.342670004845061],
						[-3.036265973071792, 43.342670008757651],
						[-3.036265975751729, 43.342674511031376],
						[-3.036272144482571, 43.342674509075231],
						[-3.036272152523754, 43.342688015896407],
						[-3.036278321255963, 43.342688013939949],
						[-3.036278326617667, 43.342697018487357],
						[-3.036284495350786, 43.342697016530565],
						[-3.036284503394714, 43.342710523351663],
						[-3.036278334660227, 43.342710525308462],
						[-3.036278337341082, 43.342715027582173],
						[-3.036284506076025, 43.342715025625374],
						[-3.036284519482587, 43.342737536993795],
						[-3.036290688219809, 43.342737535036662],
						[-3.036290690901579, 43.342742037310337],
						[-3.036315365852281, 43.342742029478472]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.034915111406731, 43.342828008715898],
						[-3.034915108826604, 43.342823506442222],
						[-3.03493978381067, 43.342823498907116],
						[-3.03493978122872, 43.34281899663344],
						[-3.034958287465396, 43.342818990978614],
						[-3.03495828488208, 43.342814488704931],
						[-3.034964453627181, 43.342814486819336],
						[-3.03496445104341, 43.342809984545653],
						[-3.034976788532699, 43.342809980773453],
						[-3.034976785948016, 43.342805478499763],
						[-3.034982954692204, 43.342805476613158],
						[-3.034982952107066, 43.342800974339468],
						[-3.034995289594529, 43.342800970565257],
						[-3.03499528700848, 43.342796468291574],
						[-3.035001455751754, 43.342796466403975],
						[-3.035001453165249, 43.342791964130278],
						[-3.035007621908067, 43.342791962242352],
						[-3.035007619321108, 43.342787459968648],
						[-3.035013788063469, 43.342787458080387],
						[-3.035013785476054, 43.342782955806683],
						[-3.035019954217959, 43.342782953918082],
						[-3.035019951630089, 43.342778451644385],
						[-3.035032289112986, 43.342778447866181],
						[-3.035032286524205, 43.342773945592484],
						[-3.035038455265196, 43.342773943702888],
						[-3.035038450086723, 43.342764939155458],
						[-3.035044618826803, 43.342764937265535],
						[-3.035044613647421, 43.342755932718106],
						[-3.035050782386588, 43.342755930827835],
						[-3.035050774616151, 43.342742424006659],
						[-3.03505694335395, 43.342742422116068],
						[-3.035056922629166, 43.342706403926094],
						[-3.035050753895014, 43.342706405816692],
						[-3.03505074353446, 43.34268839672162],
						[-3.035044574802131, 43.342688398611877],
						[-3.035044569622769, 43.342679394064312],
						[-3.03503840089135, 43.342679395954242],
						[-3.035038395712903, 43.342670391406664],
						[-3.035032226982396, 43.34267039329626],
						[-3.035032224393629, 43.34266589102247],
						[-3.035026055663576, 43.342665892911718],
						[-3.035026053075266, 43.342661390637915],
						[-3.035013715616071, 43.342661394415437],
						[-3.035013713028673, 43.34265689214164],
						[-3.035001375570389, 43.342656895917813],
						[-3.035001372983903, 43.342652393644016],
						[-3.034964360611769, 43.342652404964575],
						[-3.034964358028019, 43.342647902690778],
						[-3.034915008202121, 43.342647917766222],
						[-3.034915010782225, 43.342652420040025],
						[-3.03488416713874, 43.342652429451363],
						[-3.034884169716565, 43.342656931725173],
						[-3.034871832258256, 43.342656935487391],
						[-3.034871834835169, 43.342661437761201],
						[-3.034865666105558, 43.342661439641795],
						[-3.034865668682016, 43.342665941915598],
						[-3.034859499951948, 43.342665943795879],
						[-3.034859502527952, 43.342670446069675],
						[-3.034853333797427, 43.342670447949622],
						[-3.034853338948524, 43.342679452497208],
						[-3.034847170217087, 43.342679454376814],
						[-3.034847172792181, 43.342683956650603],
						[-3.034841004060287, 43.342683958529875],
						[-3.034841011784204, 43.342697465351229],
						[-3.034834843050942, 43.34269746723016],
						[-3.034834848199311, 43.342706471777717],
						[-3.034822510730963, 43.342706475534619],
						[-3.034822515877511, 43.342715480082155],
						[-3.034816347142424, 43.342715481960091],
						[-3.034816352288063, 43.34272448650762],
						[-3.034810183552064, 43.34272448838523],
						[-3.034810193841525, 43.342742497480238],
						[-3.034816362579347, 43.342742495602614],
						[-3.034816367724993, 43.342751500150101],
						[-3.034810198986258, 43.342751502027724],
						[-3.034810209275734, 43.342769511122633],
						[-3.034816378016291, 43.342769509245009],
						[-3.034816385734771, 43.342783016066157],
						[-3.034822554476695, 43.342783014188214],
						[-3.034822559623263, 43.342792018735622],
						[-3.034828728366099, 43.342792016857338],
						[-3.03482873093984, 43.342796519131042],
						[-3.03483489968313, 43.342796517252424],
						[-3.034834902257328, 43.342801019526121],
						[-3.034841071001074, 43.342801017647169],
						[-3.034841073575727, 43.342805519920866],
						[-3.034847242319929, 43.34280551804158],
						[-3.034847244895039, 43.342810020315262],
						[-3.034859582384351, 43.342810016555703],
						[-3.034859584960373, 43.342814518829385],
						[-3.034871922450595, 43.34281451506849],
						[-3.03487192502753, 43.342819017342173],
						[-3.034878093773095, 43.342819015461224],
						[-3.034878096350487, 43.342823517734914],
						[-3.034896602588548, 43.342823512090064],
						[-3.034896605167307, 43.342828014363732],
						[-3.034915111406731, 43.342828008715898]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.038949405268393, 43.342723153718538],
						[-3.038949402390154, 43.342718651444983],
						[-3.038961739860395, 43.342718647242918],
						[-3.038961736981244, 43.342714144969378],
						[-3.038967905715908, 43.342714142867855],
						[-3.038967902836303, 43.342709640594293],
						[-3.038974071570509, 43.34270963849243],
						[-3.0389740514101, 43.342678122577439],
						[-3.038967882679084, 43.342678124679288],
						[-3.038967879799484, 43.342673622405705],
						[-3.038961711068924, 43.342673624507228],
						[-3.038961705310637, 43.342664619960054],
						[-3.038955536580988, 43.342664622061228],
						[-3.038955533702302, 43.342660119787638],
						[-3.038949364973107, 43.342660121888485],
						[-3.038949362094877, 43.342655619614888],
						[-3.038943193366138, 43.342655621715416],
						[-3.038943190488365, 43.342651119441811],
						[-3.038930853031796, 43.342651123641851],
						[-3.038930861662383, 43.342664630462664],
						[-3.038887680554799, 43.342664645152354],
						[-3.038887683428471, 43.342669147425944],
						[-3.038875345968243, 43.342669151620001],
						[-3.038875348841005, 43.342673653893591],
						[-3.038856842649291, 43.342673660182193],
						[-3.038856839777897, 43.342669157908595],
						[-3.038850671047781, 43.342669160004128],
						[-3.038850665305906, 43.342660155456926],
						[-3.038856834035111, 43.342660153361386],
						[-3.038856822549547, 43.342642144266947],
						[-3.038844485094783, 43.342642148457671],
						[-3.038844487965262, 43.342646650731297],
						[-3.038838319237422, 43.342646652826161],
						[-3.038838322107446, 43.342651155099766],
						[-3.03883215337915, 43.342651157194311],
						[-3.038832156248719, 43.342655659467916],
						[-3.038825987519966, 43.342655661562127],
						[-3.038825990389079, 43.342660163835717],
						[-3.038819821659871, 43.342660165929594],
						[-3.038819824528529, 43.342664668203199],
						[-3.038813655798863, 43.342664670296735],
						[-3.038813658667066, 43.342669172570332],
						[-3.038807489936945, 43.342669174663541],
						[-3.038807501407938, 43.34268718375791],
						[-3.038813670139882, 43.342687181664708],
						[-3.038813673008088, 43.342691683938291],
						[-3.038826010472887, 43.342691679750878],
						[-3.038826013342006, 43.342696182024454],
						[-3.038881531937672, 43.342696163164646],
						[-3.038881534810893, 43.342700665438215],
						[-3.038893872277502, 43.342700661243484],
						[-3.038893875151635, 43.34270516351706],
						[-3.038900043885394, 43.34270516141919],
						[-3.038900046759984, 43.342709663692759],
						[-3.038906215494198, 43.342709661594554],
						[-3.038906218369245, 43.342714163868123],
						[-3.038912387103914, 43.342714161769592],
						[-3.038912389979417, 43.342718664043147],
						[-3.038918558714542, 43.342718661944289],
						[-3.038918561590502, 43.34272316421783],
						[-3.038949405268393, 43.342723153718538]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.03376757982377, 43.342567221337518],
						[-3.033767577328465, 43.342562719063586],
						[-3.033773746048153, 43.34256271724221],
						[-3.033773743552393, 43.342558214968264],
						[-3.033779912271623, 43.342558213146546],
						[-3.033779899790552, 43.342535701776825],
						[-3.033786068507503, 43.342535699954773],
						[-3.033786056024167, 43.342513188584959],
						[-3.033792224738838, 43.342513186762595],
						[-3.033792212253238, 43.342490675392682],
						[-3.033779874828453, 43.342490679037091],
						[-3.033779872332246, 43.342486176763096],
						[-3.033767534908371, 43.342486180406169],
						[-3.033767532413077, 43.342481678132181],
						[-3.033761363701594, 43.342481679953224],
						[-3.033761361206756, 43.342477177679221],
						[-3.033755192495728, 43.34247717949993],
						[-3.033755185012585, 43.342463672677901],
						[-3.033749016302925, 43.342463674498276],
						[-3.033749013809001, 43.34245917222426],
						[-3.033724338972177, 43.342459179502427],
						[-3.033724341464277, 43.342463681776444],
						[-3.033718172754614, 43.342463683595156],
						[-3.03371817524626, 43.342468185869166],
						[-3.03371200653614, 43.342468187687544],
						[-3.03371200902733, 43.342472689961554],
						[-3.033718177737906, 43.342472688143175],
						[-3.033718180229552, 43.342477190417192],
						[-3.033724348940583, 43.34247718859848],
						[-3.033724351432686, 43.342481690872468],
						[-3.033736688855657, 43.342481687234049],
						[-3.033736691348673, 43.342486189508058],
						[-3.033742860060614, 43.342486187688337],
						[-3.033742862554085, 43.342490689962339],
						[-3.033749031266481, 43.342490688142298],
						[-3.033749038748267, 43.342504194964256],
						[-3.03375520746203, 43.342504193143874],
						[-3.033755219933965, 43.342526704513745],
						[-3.033761388650006, 43.342526702693036],
						[-3.033761401124234, 43.342549214062807],
						[-3.033755232405913, 43.342549215883516],
						[-3.033755239889089, 43.342562722705345],
						[-3.033761408608778, 43.342562720884629],
						[-3.033761411103626, 43.342567223158568],
						[-3.03376757982377, 43.342567221337518]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.034094637886565, 43.342774228942382],
						[-3.034094635367073, 43.342769726668621],
						[-3.034113141588948, 43.342769721150539],
						[-3.03411313906809, 43.342765218876785],
						[-3.034125476548425, 43.342765215196415],
						[-3.034125474026656, 43.342760712922647],
						[-3.034156317725205, 43.342760703715882],
						[-3.034156315201157, 43.342756201442121],
						[-3.034174821418912, 43.34275619591407],
						[-3.034174818893496, 43.342751693640309],
						[-3.034187156371085, 43.342751689953282],
						[-3.034187153844758, 43.342747187679514],
						[-3.034199491321433, 43.34274718399115],
						[-3.034199488794196, 43.342742681717382],
						[-3.034205657532076, 43.342742679872707],
						[-3.034205655004384, 43.342738177598925],
						[-3.034211823741808, 43.342738175753915],
						[-3.03421182121366, 43.342733673480154],
						[-3.034217989950628, 43.342733671634797],
						[-3.034217987422024, 43.342729169361014],
						[-3.034224156158535, 43.342729167515337],
						[-3.034224151100419, 43.342720162967773],
						[-3.034230319836018, 43.342720161121768],
						[-3.034230309717969, 43.34270215202659],
						[-3.034236478451743, 43.342702150180237],
						[-3.034236473391811, 43.34269314563263],
						[-3.034230304658948, 43.342693147478968],
						[-3.03423029707042, 43.342679640657522],
						[-3.034224128338924, 43.342679642503526],
						[-3.034224125809872, 43.342675140229701],
						[-3.034217957078831, 43.342675142075386],
						[-3.03421795202164, 43.342666137527729],
						[-3.03421178329151, 43.342666139373058],
						[-3.034211775707096, 43.342652632551541],
						[-3.034205606978333, 43.342652634396543],
						[-3.034205599395292, 43.342639127575005],
						[-3.034199430667896, 43.34263912941968],
						[-3.034199412977344, 43.342607613502608],
						[-3.034205581701549, 43.34260761165794],
						[-3.034205574118525, 43.342594104836287],
						[-3.034211742841362, 43.342594102991292],
						[-3.034211727672595, 43.342567089347888],
						[-3.034205558952493, 43.342567091192898],
						[-3.034205553897154, 43.342558086645056],
						[-3.034199385177963, 43.342558088489717],
						[-3.03419938265075, 43.342553586215793],
						[-3.034193213932014, 43.342553588060134],
						[-3.034193211405258, 43.34254908578621],
						[-3.034187042686977, 43.342549087630211],
						[-3.034187040160677, 43.342544585356293],
						[-3.034174702725027, 43.3425445890433],
						[-3.034174700199639, 43.342540086769354],
						[-3.034156194047526, 43.342540092297362],
						[-3.034156191523506, 43.342535590023417],
						[-3.034137685372756, 43.342535595548448],
						[-3.034137682850104, 43.342531093274502],
						[-3.034131514133642, 43.342531095115504],
						[-3.034131511611447, 43.342526592841566],
						[-3.034119174179433, 43.342526596522582],
						[-3.03411917165815, 43.342522094248622],
						[-3.034100665511491, 43.342522099767642],
						[-3.034100662991577, 43.342517597493696],
						[-3.034094494276479, 43.342517599332702],
						[-3.03409449175702, 43.342513097058756],
						[-3.034088323042377, 43.342513098897413],
						[-3.034088320523376, 43.342508596623468],
						[-3.034082151809188, 43.342508598461812],
						[-3.034082149290643, 43.342504096187838],
						[-3.034069811863177, 43.342504099863525],
						[-3.034069809345544, 43.342499597589558],
						[-3.034063640632267, 43.342499599426901],
						[-3.03406363811509, 43.342495097152934],
						[-3.034057469402268, 43.342495098989943],
						[-3.034057466885547, 43.342490596715962],
						[-3.03405129817318, 43.342490598552644],
						[-3.034051295656916, 43.342486096278655],
						[-3.034045126945004, 43.342486098115003],
						[-3.03404512191339, 43.342477093567041],
						[-3.034038953202389, 43.342477095403041],
						[-3.034038948171689, 43.34246809085505],
						[-3.034032779461599, 43.342468092690737],
						[-3.034032776946705, 43.342463590416735],
						[-3.034026608237071, 43.342463592252081],
						[-3.034026605722634, 43.342459089978085],
						[-3.034020437013455, 43.342459091813105],
						[-3.034020429471515, 43.342445584991061],
						[-3.034014260763703, 43.342445586825747],
						[-3.03401425825018, 43.342441084551723],
						[-3.034008089542823, 43.342441086386074],
						[-3.034008087029756, 43.342436584112043],
						[-3.033995749615952, 43.342436587779737],
						[-3.033995752128107, 43.342441090053761],
						[-3.03398341471339, 43.342441093720133],
						[-3.033983417224634, 43.342445595994143],
						[-3.033977248516817, 43.342445597826838],
						[-3.033977251027606, 43.342450100100841],
						[-3.033971082319334, 43.342450101933196],
						[-3.033971084829667, 43.342454604207212],
						[-3.033964916120939, 43.342454606039219],
						[-3.033964918630817, 43.342459108313236],
						[-3.033952581212447, 43.342459111976282],
						[-3.033952583721413, 43.342463614250271],
						[-3.033946415011771, 43.342463616081297],
						[-3.033946417520283, 43.342468118355292],
						[-3.033940248810185, 43.342468120185984],
						[-3.033940251318241, 43.342472622459979],
						[-3.033927913897131, 43.342472626120376],
						[-3.033927916404277, 43.342477128394364],
						[-3.033915578982252, 43.342477132053411],
						[-3.033915581488486, 43.342481634327406],
						[-3.033909412777018, 43.342481636156428],
						[-3.033909415282797, 43.342486138430417],
						[-3.033903246570871, 43.342486140259112],
						[-3.033903249076195, 43.3424906425331],
						[-3.033897080363813, 43.342490644361469],
						[-3.033897082868682, 43.34249514663545],
						[-3.033884745443005, 43.342495150291157],
						[-3.033884747946963, 43.342499652565145],
						[-3.033878579233667, 43.342499654392505],
						[-3.03387858173717, 43.342504156666479],
						[-3.033872413023418, 43.342504158493519],
						[-3.033872418029512, 43.34251316304146],
						[-3.033866249314848, 43.342513164868166],
						[-3.03386625181744, 43.342517667142133],
						[-3.03386008310232, 43.342517668968497],
						[-3.033860088106594, 43.342526673516417],
						[-3.033853919390562, 43.342526675342448],
						[-3.033853921892244, 43.342531177616415],
						[-3.033847753175755, 43.342531179442105],
						[-3.033847758178209, 43.34254018399001],
						[-3.033841589460808, 43.34254018581538],
						[-3.033841596963126, 43.34255369263721],
						[-3.033835428244357, 43.342553694462246],
						[-3.033835438245633, 43.342571703557986],
						[-3.03382926952504, 43.342571705382689],
						[-3.03382929202384, 43.342612225847873],
						[-3.033823123299144, 43.342612227672248],
						[-3.033823148293308, 43.342657250410987],
						[-3.033829317022563, 43.342657248586619],
						[-3.033829322022314, 43.342666253134325],
						[-3.033835490752481, 43.342666251309616],
						[-3.033835500753814, 43.342684260404987],
						[-3.033841669485803, 43.342684258579929],
						[-3.033841676988176, 43.342697765401432],
						[-3.033847845721532, 43.342697763576048],
						[-3.033847853225278, 43.342711270397501],
						[-3.033854021960001, 43.34271126857179],
						[-3.033854026963413, 43.342720273119411],
						[-3.033860195699047, 43.342720271293359],
						[-3.03386019820121, 43.342724773567163],
						[-3.033866366937299, 43.342724771740791],
						[-3.033866369439918, 43.342729274014594],
						[-3.033872538176463, 43.342729272187881],
						[-3.033872540679538, 43.342733774461678],
						[-3.033878709416538, 43.342733772634631],
						[-3.03387871192007, 43.342738274908427],
						[-3.033884880657525, 43.342738273081054],
						[-3.033884883161514, 43.342742775354829],
						[-3.033897220637333, 43.342742771699079],
						[-3.033897223142234, 43.342747273972854],
						[-3.033915729357327, 43.34274726848674],
						[-3.033915731863596, 43.342751770760515],
						[-3.033934238080052, 43.342751765271402],
						[-3.033934240587689, 43.34275626754517],
						[-3.033952746805507, 43.342756262053058],
						[-3.033952749314512, 43.342760764326826],
						[-3.033971255533692, 43.342760758831709],
						[-3.033971258044065, 43.34276526110547],
						[-3.033995933004787, 43.342765253773997],
						[-3.033995935516984, 43.342769756047765],
						[-3.034045285442047, 43.342769741368841],
						[-3.034045287957892, 43.342774243642587],
						[-3.034094637886565, 43.342774228942382]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.033564091427408, 43.342711354020622],
						[-3.033564088947128, 43.342706851746804],
						[-3.033582595150007, 43.342706846314577],
						[-3.033582592668359, 43.342702344040759],
						[-3.033601098869865, 43.342702338605534],
						[-3.033601096386851, 43.342697836331695],
						[-3.033607265120229, 43.342697834519292],
						[-3.033607262636759, 43.34269333224546],
						[-3.033619600102603, 43.342693328619653],
						[-3.033619597618222, 43.342688826345814],
						[-3.033625766350687, 43.342688824532416],
						[-3.033625763865851, 43.342684322258577],
						[-3.033638101329867, 43.342684318630774],
						[-3.03363809884412, 43.34267981635692],
						[-3.033644267575672, 43.342679814542521],
						[-3.033644262603267, 43.342670809994821],
						[-3.033650431333907, 43.342670808180088],
						[-3.033650426360593, 43.342661803632375],
						[-3.03365659509032, 43.342661801817307],
						[-3.033656580167659, 43.342634788174088],
						[-3.03366274889465, 43.342634786358687],
						[-3.033662733969274, 43.342607772715333],
						[-3.03366890269353, 43.342607770899598],
						[-3.033668897717497, 43.342598766351792],
						[-3.033675066440841, 43.342598764535722],
						[-3.033675061463899, 43.342589759987902],
						[-3.03368123018633, 43.342589758171513],
						[-3.033681225208478, 43.342580753623679],
						[-3.033687393929997, 43.342580751806942],
						[-3.033687391440616, 43.342576249533025],
						[-3.033693560161679, 43.342576247715968],
						[-3.033693555182007, 43.342567243168105],
						[-3.033699723902158, 43.342567241350729],
						[-3.033699711450711, 43.342544729981],
						[-3.03369354273284, 43.342544731798384],
						[-3.033693537753177, 43.342535727250471],
						[-3.033687369036217, 43.342535729067521],
						[-3.033687366546842, 43.342531226793568],
						[-3.033681197830338, 43.342531228610284],
						[-3.033681192852502, 43.34252222406235],
						[-3.033675024136909, 43.342522225878724],
						[-3.033675021648447, 43.342517723604757],
						[-3.033668852933309, 43.342517725420812],
						[-3.033668850445304, 43.342513223146831],
						[-3.033662681730621, 43.342513224962552],
						[-3.033662679243073, 43.342508722688578],
						[-3.033656510528845, 43.342508724503965],
						[-3.033656505554662, 43.342499719955988],
						[-3.033650336841345, 43.342499721771041],
						[-3.03365033435471, 43.342495219497053],
						[-3.033644165641848, 43.342495221311772],
						[-3.03364416315567, 43.342490719037777],
						[-3.033637994443264, 43.342490720852169],
						[-3.033637991957542, 43.342486218578159],
						[-3.033613317109736, 43.342486225832374],
						[-3.033613314625838, 43.342481723558372],
						[-3.033607145914341, 43.342481725371094],
						[-3.033607143430899, 43.342477223097092],
						[-3.033594806008815, 43.342477226721549],
						[-3.033594798561227, 43.342463719899492],
						[-3.033600967270902, 43.34246371808743],
						[-3.033600964787919, 43.34245921581342],
						[-3.033607133497137, 43.342459214001018],
						[-3.033607118596511, 43.342432200356818],
						[-3.033600949890028, 43.342432202169199],
						[-3.033600944924069, 43.342423197621109],
						[-3.033594776218497, 43.342423199433163],
						[-3.033594773735973, 43.342418697159097],
						[-3.033582436325739, 43.342418700782211],
						[-3.033582433844129, 43.342414198508152],
						[-3.033570096434804, 43.342414202129945],
						[-3.033570093954105, 43.342409699855864],
						[-3.03355775654569, 43.342409703476321],
						[-3.033557754065904, 43.342405201202247],
						[-3.033545416658398, 43.342405204821361],
						[-3.033545414179524, 43.342400702547287],
						[-3.033526908069628, 43.342400707973461],
						[-3.033526905592122, 43.342396205699387],
						[-3.033514568186433, 43.342396209315169],
						[-3.03351456570984, 43.342391707041095],
						[-3.033502228305061, 43.342391710655555],
						[-3.03350222582938, 43.342387208381467],
						[-3.03348988842551, 43.342387211994591],
						[-3.033489885950741, 43.342382709720496],
						[-3.033465211144818, 43.342382716942751],
						[-3.033465213617764, 43.342387219216846],
						[-3.033452876213887, 43.342387222825977],
						[-3.033452878685921, 43.342391725100072],
						[-3.033446709983526, 43.342391726904133],
						[-3.033446714926685, 43.342400731452294],
						[-3.033440546223378, 43.342400733256028],
						[-3.033440563521261, 43.342432249174486],
						[-3.033446732227759, 43.342432247370745],
						[-3.033446744585686, 43.342454758740956],
						[-3.033440575876909, 43.342454760544705],
						[-3.033440580819172, 43.342463765092774],
						[-3.033434412109482, 43.342463766896174],
						[-3.033434417050836, 43.342472771444221],
						[-3.033428248340234, 43.342472773247302],
						[-3.033428250810456, 43.342477275521318],
						[-3.033422082099398, 43.342477277324058],
						[-3.033422087038931, 43.342486281872091],
						[-3.033415918326961, 43.342486283674496],
						[-3.033415925734898, 43.342499790496511],
						[-3.033409757021559, 43.342499792298582],
						[-3.033409759490417, 43.342504294572585],
						[-3.033403590776622, 43.342504296374329],
						[-3.033403595713428, 43.342513300922327],
						[-3.03339742699872, 43.342513302723731],
						[-3.033397431934616, 43.342522307271715],
						[-3.033391263218997, 43.342522309072791],
						[-3.033391292828952, 43.342576336360338],
						[-3.033397461550041, 43.342576334559261],
						[-3.033397466485953, 43.342585339107139],
						[-3.033403635207954, 43.342585337305721],
						[-3.033403637676367, 43.342589839579645],
						[-3.033409806398824, 43.342589837777908],
						[-3.033409811336562, 43.342598842325756],
						[-3.03341598005993, 43.342598840523671],
						[-3.03341598746791, 43.342612347345423],
						[-3.033422156192644, 43.34261234554301],
						[-3.033422185830086, 43.34266637282969],
						[-3.03342835456029, 43.342666371026944],
						[-3.033428359500784, 43.342675375574657],
						[-3.033434528231899, 43.342675373771577],
						[-3.033434530702602, 43.34267987604543],
						[-3.033440699434173, 43.342679874242023],
						[-3.033440701905333, 43.342684376515876],
						[-3.033453039369383, 43.342684372908046],
						[-3.033453041841455, 43.342688875181885],
						[-3.033459210573935, 43.342688873377462],
						[-3.033459213046464, 43.342693375651322],
						[-3.033465381779399, 43.342693373846572],
						[-3.033465384252385, 43.342697876120411],
						[-3.033483890452556, 43.34269787070415],
						[-3.033483892926908, 43.342702372978003],
						[-3.033502399128441, 43.342702367558751],
						[-3.033502401604163, 43.34270686983259],
						[-3.033539414009949, 43.34270685898511],
						[-3.033539416488406, 43.342711361258935],
						[-3.033564091427408, 43.342711354020622]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.039461188699204, 43.342380805417072],
						[-3.03946118578318, 43.342376303143276],
						[-3.039455017082759, 43.342376305271401],
						[-3.039455011251626, 43.342367300723801],
						[-3.039461179951135, 43.34236729859569],
						[-3.039461165371035, 43.342344787226622],
						[-3.039454996673804, 43.342344789354733],
						[-3.03945498792712, 43.342331282533252],
						[-3.039405638360193, 43.342331299546174],
						[-3.039405641272107, 43.342335801820006],
						[-3.039399472575782, 43.342335803945126],
						[-3.039399481310161, 43.342349310766608],
						[-3.039393312612468, 43.342349312891393],
						[-3.039393318434479, 43.342358317439022],
						[-3.039387149735874, 43.342358319563488],
						[-3.03938716137808, 43.342376328658709],
						[-3.039393330078509, 43.34237632653425],
						[-3.039393332989518, 43.342380828808054],
						[-3.039405670391285, 43.342380824558134],
						[-3.039405667479364, 43.342376322284352],
						[-3.039411836179791, 43.342376320158884],
						[-3.039411833267415, 43.342371817885095],
						[-3.039436508067291, 43.342371809379955],
						[-3.039436510981491, 43.342376311653751],
						[-3.039448848382337, 43.342376307399185],
						[-3.039448851297448, 43.342380809672967],
						[-3.039461188699204, 43.342380805417072]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.032829939207407, 43.342576498866315],
						[-3.03282993678139, 43.342571996592341],
						[-3.032848442943444, 43.342571991278923],
						[-3.03284844051606, 43.342567489004942],
						[-3.032866946676742, 43.342567483688541],
						[-3.032866944247991, 43.342562981414567],
						[-3.032879281687531, 43.342562977868624],
						[-3.032879279257868, 43.342558475594636],
						[-3.032897785415807, 43.342558470273239],
						[-3.032897782984777, 43.342553967999251],
						[-3.032910120422489, 43.342553964449984],
						[-3.032910117990548, 43.342549462175995],
						[-3.032928624145743, 43.342549456849603],
						[-3.032928621712435, 43.342544954575622],
						[-3.032934790430377, 43.342544952799507],
						[-3.032934787996614, 43.342540450525505],
						[-3.032947125431583, 43.342540446972251],
						[-3.03294712299691, 43.342535944698263],
						[-3.032965629147992, 43.342535939365881],
						[-3.032965626711951, 43.342531437091893],
						[-3.03298413286166, 43.342531431756512],
						[-3.032984130424253, 43.342526929482531],
						[-3.032996467856478, 43.342526925923934],
						[-3.032996465418159, 43.342522423649932],
						[-3.03300880284947, 43.34252242009002],
						[-3.03300880041024, 43.342517917816025],
						[-3.033014969125439, 43.342517916035575],
						[-3.033014966685753, 43.342513413761573],
						[-3.033021135400496, 43.342513411980782],
						[-3.033021130520215, 43.342504407432756],
						[-3.033027299234046, 43.342504405651638],
						[-3.03302729679345, 43.342499903377629],
						[-3.033033465506824, 43.342499901596177],
						[-3.033033460624723, 43.342490897048144],
						[-3.033039629337185, 43.342490895266359],
						[-3.033039624454175, 43.342481890718297],
						[-3.033033455742625, 43.342481892500075],
						[-3.033033450860528, 43.342472887952013],
						[-3.03302728214989, 43.342472889733457],
						[-3.033027274828117, 43.342459382911322],
						[-3.033033443537388, 43.342459381129871],
						[-3.033033424009039, 43.34242336293736],
						[-3.033027255303415, 43.342423364718798],
						[-3.033027250422244, 43.342414360170636],
						[-3.033021081717532, 43.342414361951754],
						[-3.033021076837275, 43.342405357403571],
						[-3.033014908133473, 43.342405359184347],
						[-3.033014905693802, 43.342400856910253],
						[-3.033008736990455, 43.342400858690702],
						[-3.03300873455124, 43.342396356416607],
						[-3.033002565848348, 43.342396358196709],
						[-3.03300256340959, 43.342391855922607],
						[-3.032996394707153, 43.342391857702395],
						[-3.032996392268851, 43.342387355428286],
						[-3.03299022356687, 43.342387357207727],
						[-3.032990221129024, 43.342382854933618],
						[-3.032984052427499, 43.342382856712732],
						[-3.032984049990109, 43.342378354438615],
						[-3.032977881289038, 43.342378356217395],
						[-3.032977878852106, 43.342373853943279],
						[-3.03297171015149, 43.342373855721725],
						[-3.032971707715014, 43.342369353447616],
						[-3.032959370314693, 43.342369357003506],
						[-3.032959367879129, 43.342364854729375],
						[-3.032940861780011, 43.342364860060727],
						[-3.032940859345815, 43.342360357786596],
						[-3.032916184548808, 43.342360364890411],
						[-3.032916182116435, 43.342355862616266],
						[-3.032897676020041, 43.342355867940633],
						[-3.032897673589037, 43.342351365666488],
						[-3.032879167494004, 43.342351370987856],
						[-3.032879165064368, 43.342346868713712],
						[-3.032860658970699, 43.342346874032074],
						[-3.032860656542431, 43.342342371757923],
						[-3.032835981752687, 43.342342378844435],
						[-3.032835979326243, 43.342337876570284],
						[-3.032798967144346, 43.342337887190055],
						[-3.032798964720637, 43.342333384915896],
						[-3.032768121237986, 43.342333393756554],
						[-3.032768118816558, 43.342328891482396],
						[-3.03274344403225, 43.342328898548921],
						[-3.032743441612646, 43.342324396274755],
						[-3.0327311042214, 43.342324399806024],
						[-3.032731101802708, 43.342319897531858],
						[-3.032706427022034, 43.342319904590418],
						[-3.032706429438903, 43.342324406864584],
						[-3.032700260743277, 43.342324408628393],
						[-3.032700263159692, 43.342328910902559],
						[-3.032687925767526, 43.342328914429174],
						[-3.032687928183029, 43.34233341670334],
						[-3.03268175948649, 43.342333418466147],
						[-3.032681764316585, 43.342342423014479],
						[-3.032675595619134, 43.342342424776945],
						[-3.03267560044832, 43.342351429325255],
						[-3.032669431749957, 43.342351431087401],
						[-3.032669438992372, 43.342364937909835],
						[-3.03266327029264, 43.342364939671654],
						[-3.032663275120008, 43.342373944219936],
						[-3.032657106419365, 43.342373945981414],
						[-3.032657108832594, 43.342378448255538],
						[-3.032650940131493, 43.342378450016682],
						[-3.032650944957042, 43.342387454564943],
						[-3.032644776255029, 43.34238745632576],
						[-3.032644785904309, 43.342405465422225],
						[-3.032650954608145, 43.342405463661422],
						[-3.032650959433699, 43.342414468209626],
						[-3.032657128138446, 43.342414466448467],
						[-3.03265713537815, 43.342427973270759],
						[-3.032663304084265, 43.342427971509281],
						[-3.032663313739034, 43.342445980605625],
						[-3.032669482446971, 43.342445978843806],
						[-3.032669504174338, 43.342486499310354],
						[-3.032675672886378, 43.342486497548201],
						[-3.03267568013021, 43.342500004370315],
						[-3.032681848843616, 43.342500002607835],
						[-3.032681853673753, 43.342509007155897],
						[-3.03268802238807, 43.342509005393076],
						[-3.032688029634647, 43.342522512215133],
						[-3.032694198350331, 43.342522510451978],
						[-3.032694200766314, 43.342527012726002],
						[-3.032700369482453, 43.342527010962513],
						[-3.032700371898891, 43.342531513236523],
						[-3.032706540615486, 43.3425315114727],
						[-3.032706543032381, 43.34253601374671],
						[-3.032712711749431, 43.34253601198256],
						[-3.032712714166783, 43.342540514256555],
						[-3.032718882884288, 43.342540512492072],
						[-3.032718885302096, 43.342545014766074],
						[-3.032731222738017, 43.342545011236105],
						[-3.032731225156737, 43.3425495135101],
						[-3.032737393875152, 43.342549511744615],
						[-3.032737396294328, 43.342554014018603],
						[-3.032749733732069, 43.342554010486637],
						[-3.032749736152158, 43.342558512760625],
						[-3.032755904871483, 43.342558510994138],
						[-3.032755907292028, 43.342563013268141],
						[-3.032768244731589, 43.342563009734164],
						[-3.032768247153047, 43.342567512008145],
						[-3.032780584593517, 43.342567508472861],
						[-3.032780587015886, 43.342572010746821],
						[-3.032792924457266, 43.342572007210208],
						[-3.032792926880548, 43.342576509484168],
						[-3.032829939207407, 43.342576498866315]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.039855817678773, 43.342124038914932],
						[-3.039855811788457, 43.342115034366977],
						[-3.039849643114518, 43.342115036516361],
						[-3.039849631335716, 43.342097027420415],
						[-3.0398434626636, 43.342097029569459],
						[-3.039843456775115, 43.342088025021461],
						[-3.03983728810391, 43.342088027170185],
						[-3.039837282216339, 43.342079022622173],
						[-3.039831113546045, 43.342079024770563],
						[-3.039831110602716, 43.342074522496553],
						[-3.039818773263037, 43.342074526792331],
						[-3.039818776205454, 43.34207902906634],
						[-3.039812607535157, 43.342079031213729],
						[-3.039812604593196, 43.342074528939733],
						[-3.039806435923354, 43.34207453108678],
						[-3.03980643298185, 43.342070028812756],
						[-3.039794095643076, 43.342070033105877],
						[-3.039794092702484, 43.34206553083186],
						[-3.039787924033553, 43.342065532977912],
						[-3.039787918153282, 43.342056528429858],
						[-3.039781749485261, 43.342056530575583],
						[-3.039781746545583, 43.342052028301545],
						[-3.039769409210451, 43.342052032592001],
						[-3.039769412149218, 43.342056534866046],
						[-3.039757074813171, 43.342056539155152],
						[-3.039757077751027, 43.34206104142919],
						[-3.039750909082546, 43.342061043573246],
						[-3.039750912019947, 43.342065545847277],
						[-3.03974474335101, 43.342065547991005],
						[-3.039744746287956, 43.342070050265029],
						[-3.039763252296131, 43.342070043832841],
						[-3.039763255234444, 43.342074546106858],
						[-3.039769423904291, 43.342074543962134],
						[-3.039769426843061, 43.342079046236151],
						[-3.039775595513362, 43.342079044091093],
						[-3.039775604331044, 43.342092550913115],
						[-3.039781773002712, 43.342092548767717],
						[-3.039781775942397, 43.342097051041712],
						[-3.03978794461452, 43.342097048895994],
						[-3.039787950494802, 43.34210605344397],
						[-3.039794119167837, 43.342106051297911],
						[-3.039794122108435, 43.342110553571906],
						[-3.039800290781924, 43.342110551425513],
						[-3.039800293722979, 43.342115053699501],
						[-3.039806462396924, 43.342115051552767],
						[-3.039806465338435, 43.342119553826748],
						[-3.039812634012835, 43.342119551679694],
						[-3.039812636954803, 43.342124053953675],
						[-3.039855817678773, 43.342124038914932]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.039960602469978, 43.341997938651907],
						[-3.039960599517089, 43.341993436377841],
						[-3.039966768178709, 43.341993434222474],
						[-3.039966765225365, 43.3419889319484],
						[-3.039972933886528, 43.341988929792691],
						[-3.039972930932729, 43.341984427518611],
						[-3.039979099593435, 43.341984425362583],
						[-3.039979096639181, 43.341979923088509],
						[-3.039985265299431, 43.341979920932133],
						[-3.039985262344722, 43.341975418658052],
						[-3.039991431004515, 43.341975416501363],
						[-3.039991428049351, 43.341970914227282],
						[-3.039997596708688, 43.341970912070245],
						[-3.03999759079745, 43.341961907522062],
						[-3.039960578846888, 43.341961920459255],
						[-3.039960575894005, 43.34195741818516],
						[-3.039954407236031, 43.341957420340187],
						[-3.039954404283605, 43.341952918066092],
						[-3.039935898311048, 43.341952924529188],
						[-3.039935901262107, 43.34195742680329],
						[-3.03992973260413, 43.341957428956995],
						[-3.039929735554734, 43.34196193123109],
						[-3.039923566896301, 43.341961933384461],
						[-3.039923584597205, 43.341988947028987],
						[-3.039929753258372, 43.341988944875617],
						[-3.039929756208982, 43.341993447149683],
						[-3.039935924870604, 43.341993444995992],
						[-3.03993592782167, 43.341997947270059],
						[-3.039960602469978, 43.341997938651907]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.040515710200277, 43.341889688758364],
						[-3.040515704212484, 43.341880684210111],
						[-3.040521872862646, 43.341880682024815],
						[-3.040521860885244, 43.341862672928265],
						[-3.040472511698516, 43.341862690401356],
						[-3.040472526652036, 43.341885201772044],
						[-3.040491032603903, 43.341885195222126],
						[-3.040491035595976, 43.341889697496242],
						[-3.040515710200277, 43.341889688758364]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.040016076073836, 43.34193038513024],
						[-3.040016073116855, 43.341925882856124],
						[-3.040028410426407, 43.34192587853908],
						[-3.040028389721178, 43.341894362620138],
						[-3.040034558372762, 43.341894360461126],
						[-3.040034555414419, 43.341889858186981],
						[-3.040028386763291, 43.341889860345994],
						[-3.04002837493175, 43.341871851249373],
						[-3.040022206282444, 43.341871853408065],
						[-3.04002220036759, 43.341862848859726],
						[-3.040016031719195, 43.341862851018078],
						[-3.040016028762225, 43.341858348743912],
						[-3.040009860114286, 43.341858350901923],
						[-3.0400098630708, 43.341862853176096],
						[-3.039997525774008, 43.341862857491137],
						[-3.039997528729612, 43.341867359765317],
						[-3.039985191431906, 43.341867364079029],
						[-3.039985194386599, 43.341871866353195],
						[-3.039972857087978, 43.341871870665578],
						[-3.03997286004176, 43.341876372939737],
						[-3.039966691391993, 43.341876375095431],
						[-3.03996669434532, 43.341880877369597],
						[-3.039960525695096, 43.34188087952495],
						[-3.03996053160084, 43.341889884073268],
						[-3.039954362949703, 43.341889886228287],
						[-3.039954374759376, 43.341907895324866],
						[-3.039948206106416, 43.341907897479572],
						[-3.039948212010344, 43.34191690202784],
						[-3.039979055279696, 43.341916891251032],
						[-3.039979052325453, 43.341912388976901],
						[-3.039997558285688, 43.341912382506813],
						[-3.039997561241299, 43.341916884780957],
						[-3.040003729895166, 43.341916882623593],
						[-3.040003735807302, 43.341925887171833],
						[-3.040009904462079, 43.341925885014156],
						[-3.040009907418604, 43.341930387288258],
						[-3.040016076073836, 43.34193038513024]
					]
				]
			}
		},
		{
			type: 'Feature',
			properties: { stockpiles: 1.0 },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[-3.040527996590771, 43.341813145726853],
						[-3.040527993595974, 43.341808643452659],
						[-3.040546499524574, 43.341808636893788],
						[-3.04054649652841, 43.341804134619593],
						[-3.040552665170819, 43.341804132432642],
						[-3.0405526621742, 43.341799630158441],
						[-3.040564999458105, 43.341799625783523],
						[-3.040564996460576, 43.341795123509335],
						[-3.040571165102071, 43.341795121321383],
						[-3.040571162104087, 43.341790619047181],
						[-3.040577330745125, 43.341790616858887],
						[-3.040577327746685, 43.341786114584686],
						[-3.040583496387268, 43.341786112396072],
						[-3.040583493388373, 43.34178161012187],
						[-3.040589662028498, 43.341781607932916],
						[-3.040589659029148, 43.341777105658707],
						[-3.040595827668818, 43.341777103469418],
						[-3.040595824669012, 43.341772601195217],
						[-3.040601993308225, 43.341772599005594],
						[-3.040601990307965, 43.341768096731379],
						[-3.040608158946721, 43.341768094541429],
						[-3.040608155946006, 43.34176359226722],
						[-3.040614324584306, 43.341763590076937],
						[-3.040614321583134, 43.341759087802707],
						[-3.040620490220978, 43.341759085612097],
						[-3.040620487219352, 43.341754583337874],
						[-3.040626655856739, 43.34175458114693],
						[-3.040626652854658, 43.341750078872707],
						[-3.040632821491588, 43.341750076681421],
						[-3.040632815486517, 43.341741072132955],
						[-3.040638984122535, 43.341741069941349],
						[-3.040638981119544, 43.34173656766712],
						[-3.040645149755105, 43.341736565475166],
						[-3.040645143748214, 43.341727560926699],
						[-3.040651312382863, 43.341727558734426],
						[-3.040651309378963, 43.341723056460168],
						[-3.040657478013156, 43.341723054267554],
						[-3.040657475008801, 43.34171855199331],
						[-3.040663643642537, 43.341718549800362],
						[-3.040663640637726, 43.341714047526118],
						[-3.040669809271006, 43.341714045332843],
						[-3.04066980626574, 43.341709543058592],
						[-3.040675974898563, 43.34170954086499],
						[-3.040675971892842, 43.341705038590717],
						[-3.040682140525209, 43.341705036396782],
						[-3.040682137519033, 43.341700534122516],
						[-3.040688306150943, 43.341700531928247],
						[-3.040688300137682, 43.341691527379723],
						[-3.040694468768679, 43.341691525185126],
						[-3.040694465761594, 43.341687022910847],
						[-3.040700634392135, 43.341687020715902],
						[-3.040700631384594, 43.341682518441637],
						[-3.040712968644763, 43.341682514050767],
						[-3.040712965636311, 43.341678011776487],
						[-3.040706797006683, 43.341678013972086],
						[-3.040706793998688, 43.341673511697813],
						[-3.040694456740342, 43.341673516088015],
						[-3.040694453733259, 43.341669013813728],
						[-3.04068828510454, 43.341669016008325],
						[-3.040688282097914, 43.341664513734038],
						[-3.04068211346965, 43.341664515928315],
						[-3.040682110463481, 43.341660013654021],
						[-3.040675941835673, 43.34166001584795],
						[-3.04067593882996, 43.341655513573656],
						[-3.040663601575253, 43.341655517960518],
						[-3.040663598570452, 43.341651015686224],
						[-3.040657429943553, 43.341651017879165],
						[-3.040657426939209, 43.341646515604864],
						[-3.040651258312765, 43.341646517797457],
						[-3.040651255308878, 43.341642015523163],
						[-3.0406389180569, 43.341642019907368],
						[-3.040638915053924, 43.341637517633053],
						[-3.04063274642839, 43.341637519824658],
						[-3.040632743425872, 43.341633017550336],
						[-3.040626574800793, 43.341633019741607],
						[-3.040626571798731, 43.341628517467292],
						[-3.040620403174107, 43.341628519658236],
						[-3.040620400172501, 43.3416240173839],
						[-3.040608062924163, 43.34162402176478],
						[-3.040608059923469, 43.341619519490457],
						[-3.040601891299755, 43.3416195216804],
						[-3.040601888299518, 43.341615019406063],
						[-3.040595719676259, 43.341615021595672],
						[-3.040595716676478, 43.341610519321335],
						[-3.040589548053675, 43.341610521510617],
						[-3.040589545054351, 43.341606019236274],
						[-3.040577207809652, 43.341606023613814],
						[-3.040577204811241, 43.341601521339463],
						[-3.040571036189347, 43.34160152352775],
						[-3.040571033191391, 43.341597021253399],
						[-3.040564864569952, 43.341597023441338],
						[-3.040564861572453, 43.341592521166994],
						[-3.040558692951469, 43.341592523354599],
						[-3.040558689954427, 43.341588021080241],
						[-3.040546352713368, 43.34158802545447],
						[-3.040546349717239, 43.341583523180105],
						[-3.040540181097164, 43.341583525366715],
						[-3.040540178101491, 43.341579023092343],
						[-3.040534009481872, 43.341579025278627],
						[-3.040534006486655, 43.341574523004255],
						[-3.04052783786749, 43.341574525190211],
						[-3.04052783487273, 43.341570022915839],
						[-3.040515497635311, 43.341570027286721],
						[-3.040515494641463, 43.341565525012335],
						[-3.040509326023209, 43.341565527197289],
						[-3.040509323029818, 43.341561024922903],
						[-3.040503154412018, 43.341561027107524],
						[-3.040503151419083, 43.341556524833145],
						[-3.040496982801739, 43.341556527017417],
						[-3.04049697980926, 43.341552024743045],
						[-3.040484642575481, 43.341552029110602],
						[-3.040484639583915, 43.341547526836194],
						[-3.04047847096748, 43.341547529019479],
						[-3.040478467976371, 43.341543026745086],
						[-3.040472299360391, 43.341543028928037],
						[-3.040472296369738, 43.341538526653636],
						[-3.040459959138688, 43.341538531018536],
						[-3.040459956148948, 43.341534028744121],
						[-3.040453787533878, 43.341534030926063],
						[-3.040453784544593, 43.341529528651655],
						[-3.040441447315362, 43.341529533014565],
						[-3.040441444326991, 43.341525030740158],
						[-3.04043527571283, 43.341525032921105],
						[-3.040435278700746, 43.341529535195505],
						[-3.040416772856893, 43.341529541736371],
						[-3.040416775843442, 43.341534044010785],
						[-3.040410607228367, 43.341534046190404],
						[-3.040410610214461, 43.341538548464804],
						[-3.04040444159893, 43.341538550644103],
						[-3.040404444584568, 43.341543052918503],
						[-3.040398275968581, 43.341543055097453],
						[-3.040398281938948, 43.341552059646254],
						[-3.040392113322048, 43.341552061824878],
						[-3.040392116306778, 43.341556564099278],
						[-3.040385947689421, 43.341556566277575],
						[-3.04038595365797, 43.34156557082634],
						[-3.040379785039701, 43.341565573004296],
						[-3.04037978802352, 43.341570075278689],
						[-3.040373619404794, 43.341570077456311],
						[-3.040373622388159, 43.341574579730697],
						[-3.040367453768977, 43.341574581907992],
						[-3.040367456751887, 43.341579084182364],
						[-3.040361288132248, 43.341579086359332],
						[-3.040361291114702, 43.341583588633711],
						[-3.040355122494607, 43.34158359081033],
						[-3.040355125476606, 43.341588093084717],
						[-3.040348956856054, 43.341588095261002],
						[-3.040348959837599, 43.341592597535374],
						[-3.04034279121659, 43.34159259971134],
						[-3.040342797178769, 43.341601604260049],
						[-3.040336628556848, 43.341601606435688],
						[-3.040336634518118, 43.341610610984411],
						[-3.040330465895285, 43.341610613159702],
						[-3.040330468875465, 43.341615115434053],
						[-3.040324300252175, 43.341615117609024],
						[-3.0403243032319, 43.341619619883375],
						[-3.040318134608154, 43.341619622058012],
						[-3.040318140566695, 43.341628626606699],
						[-3.040311971942036, 43.341628628781002],
						[-3.040311974920852, 43.341633131055332],
						[-3.040305806295737, 43.341633133229308],
						[-3.040305809274097, 43.341637635503645],
						[-3.040299640648525, 43.34163763767728],
						[-3.040299643626431, 43.341642139951617],
						[-3.040293475000403, 43.341642142124932],
						[-3.040293495842567, 43.341673658045146],
						[-3.040299664471785, 43.341673655871844],
						[-3.040299673405519, 43.341687162694726],
						[-3.040305842036104, 43.34168716052109],
						[-3.040305850971212, 43.341700667343957],
						[-3.040312019603164, 43.341700665169981],
						[-3.040312028539645, 43.341714171992813],
						[-3.040318197172964, 43.341714169818509],
						[-3.040318200152249, 43.341718672092775],
						[-3.040324368786023, 43.341718669918137],
						[-3.040324374745505, 43.341727674466668],
						[-3.04033054338019, 43.341727672291697],
						[-3.040330546360388, 43.341732174565948],
						[-3.040336714995528, 43.341732172390643],
						[-3.040336720956838, 43.341741176939131],
						[-3.040342889592889, 43.341741174763499],
						[-3.040342895555113, 43.341750179311987],
						[-3.040349064192075, 43.341750177136014],
						[-3.040349076118355, 43.341768186232954],
						[-3.04035524475714, 43.34176818405664],
						[-3.040355250721195, 43.341777188605086],
						[-3.040361419360891, 43.341777186428445],
						[-3.040361422343376, 43.341781688702653],
						[-3.040367590983527, 43.341781686525678],
						[-3.04036759694941, 43.34179069107411],
						[-3.040373765590471, 43.341790688896793],
						[-3.04037376857387, 43.341795191170995],
						[-3.040379937215387, 43.341795188993359],
						[-3.040379940199242, 43.34179969126756],
						[-3.040392277483185, 43.341799686911266],
						[-3.040392280467953, 43.341804189185474],
						[-3.04041078639523, 43.341804182648538],
						[-3.040410789381366, 43.341808684922739],
						[-3.040490981738756, 43.341808656561454],
						[-3.040490984730818, 43.341813158835649],
						[-3.040527996590771, 43.341813145726853]
					]
				]
			}
		}
	]
};

export default stockpiles;
